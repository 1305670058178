import React from 'react'
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

export const ScientificProgrammeCommittee = () => {

  return (
    <div className="home-root">
      <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
        <Header />
        <div className='w-full relativeh-screen mt-20 pb-20'>
          <main>
            <div className="w-full sm:w-4/6 m-auto px-5 pt-20">
              <div>
                <div className='shadow-md p-10'>
                  <div className="text-center w-full flex justify-start">
                    <div className="">
                      <h1
                        className="text-black text-3xl border-b-4 border-b-[#e78c31] pb-3 leading-[60px]"
                        style={{ fontSize: 50 }}
                      >
                        Scientific Programme Committee
                      </h1>
                    </div>
                  </div>
                  <ul>
                    <li>Srdjan Djurovic, Norway</li>
                    <li>Tanja Antunovic, Montenegro</li>
                    <li>Najdana Gligorovic Barhanovic, Montenegro</li>
                    <li>Danica Popovic, Montenegro</li>
                    <li>Dobrin Svinarov, Bulgaria</li>
                    <li>Snezana Jovicic, Serbia</li>
                    <li>Katerina Tosheska-Trajkovska, North Macedonia</li>
                    <li>Jozo Coric, Bosnia and Herzegovina</li>
                    <li>Dogan Yucel, Turkiye</li>
                    <li>Alexander Haliassos, Greece</li>
                    {/* <li>Anyla Bulo, Albania</li> */}
                    <li>Anyla Bulo Kasneci, Albania</li>
                    <li>Marina Minic-Novcic, UK</li>
                    <li>Marina Jaksic, Montenegro</li>
                    <li>Jelena Boljevic, Montenegro</li>
                    <li>Vuko Nikolic, Montenegro</li>
                    <li>Ivana Milasevic, Montenegro</li>
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>

      </div>
      <Footer />
    </div>

  )
}