import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import katic from "../assets/panorama.jpg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DropDownList from "../components/DropDownList";
import { Link, useNavigate } from "react-router-dom";

export const LocalCorner = () => {
	const navigate = useNavigate();

	const onSubmitWorkClick = () => {
		navigate("/SendAbstract");
	};

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header />
				<div className="w-full relativeh-screen mt-28 pb-20">
					<main>
						<div className="w-full sm:w-5/6 m-auto px-5 pt-20">
							<div className="shadow-md p-10">
								<div className="w-fit pb-2">
									<h1 className="text-2xl sm:text-4xl border-b-4 border-b-[#e78c31] pb-1">Local Corner</h1>
								</div>
								<h4 className="mb-2 font-bold"> Donation to the Children's Home "Mladost" in Bijela on behalf of Petra Uljarevic</h4>
								
								<p  className="pt-3">It is a great honour for us to have gathered so many great lecturers at BCLF 2023, who will selflessly
									share their invaluable knowledge and experience with the congress participants. Among them, master
									Petra Uljarevic from Maribor in Slovenia stands out, because she will take a step further in her
									selflessness. Namely, she decided to independently bear all the costs of participating in the congress,
									which the organiser was obliged to provide, and to donate those funds, in her name, to the Children&#39;s
									Home &quot;Mladost&quot; in Bijela (more information available on link https://dombijela.me/). This is a truly
									altruistic act because &quot;Mladost&quot; is the only institution in the territory of Montenegro that deals with the
									care of children deprived of parental care and children whose development is hindered by family
									circumstances. In this way, reputable Petra, whose native home is Herceg Novi, the host city of this year’s
									Congress, will incorporate a small part of herself and the BCLF 2023 story into the noble activities of an
									institution that provides long-term and continuous care and support in the growing up of children who
									represent a particularly sensitive population group.
								</p>
								
								<h4 className="mb-2">Donacija Dječjem domu „Mladost“ u Bijeloj u ime Petre Uljarević</h4>
								<p className="pt-3">
								Velika nam je čast što smo okupili toliko sjajnih predavača na BCLF 2023, koji će svoje neprocjenjivo
								znanje i iskustvo nesebično dijeliti sa učesnicima kongresa. Među njima se posebno izdvaja magistra
								Petra Uljarević iz Maribora u Sloveniji, koja će napraviti korak više u svojoj nesebičnosti. Naime, ona je
								odlučila da samostalno snosi sve troškove učešća na kongresu, koje je organizator bio dužan da
								obezbijedi, a da se ta sredstva, u njeno ime, doniraju Dječjem domu „Mladost” u Bijeloj (više informacija
								dostupno na linku https://dombijela.me/). Ovo je istinski altruističan čin, jer je riječ o jedinoj ustanovi na
								teritoriji Crne Gore koja se bavi zbrinjavanjem djece lišene roditeljskog staranja i djece čiji je razvoj
								ometen porodičnim prilikama. Na ovaj način će uvažena Petra, koja je porijeklom Novljanka, ugraditi
								jedan mali dio sebe i BCLF 2023 priče, u plemenite aktivnosti ustanove koja pruža dugoročnu i
								kontinuiranu njegu i podršku u odrastanju djece koja čine posebno osjetljivu populacionu grupu.
								</p>
								<br></br>
								<br></br>
								<p className="font-bold">
									Za delegate iz Crne Gore omogućena je dnevna registracija.
								</p>
								<h4 className="mb-2"> Kotizacija za dnevnu registraciju iznosi:</h4>
								<ul>
									<li>27.09.2023. 40€</li>
									<li>28. i 29.09.2023. 70€</li>
									<li>30.09.2023.  40€</li>

								</ul>
								<h4 className="mb-2"> Dnevna registracija uključuje:</h4>
								<ul>
									<li>Mogućnost prisustva predavanjima, radionicama, komercijalnim prezentacijama, poster sesiji</li>
									<li>Kongresni materijal</li>
									<li>Sertifikat o učešću (za određeni dan)</li>
									<li>Ručak (za registraciju 28. i 29.09.2023.)</li>
									<li>Kafe pauze (ako su u programu)</li>
									<li>Ceremonija otvaranja (za registraciju 27.09.2023.)</li>
								</ul>
								{/* <p className="pt-5">
									Za dnevnu registraciju neophodno je popuniti formular na stranici
									{" "}<Link to="/Registration" className="font-bold underline text-blue-700" >Registration</Link>.
								</p> */}
								
							</div>

						</div>
					</main>
				</div>
			</div>
			<Footer />
		</div>
	);
};
