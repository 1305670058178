import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import katic from "../assets/panorama.jpg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DropDownList from "../components/DropDownList";
import { Link, useNavigate } from "react-router-dom";

export const GeneralInfo = () => {
	const navigate = useNavigate();

	const onSubmitWorkClick = () => {
		navigate("/SendAbstract");
	};

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header />
				<div className="w-full relativeh-screen mt-28 pb-20 pt-12 sm:pt-20">
					<main>
						<div className="w-full sm:w-4/6 m-auto px-5 sm:pl-10 pb-10 ] shadow-md">
							<div className="text-center w-full flex justify-start">
								<div className="">
									<h1
										className="text-black text-3xl border-b-4 border-b-[#e78c31] pb-3 leading-[60px]"
										style={{ fontSize: 50 }}
									>

										General Information
									</h1>
								</div>
							</div>
							<div className="w-fit mt-5">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Climate
								</h2>
							</div>
							<p>
								Herceg Novi has a pleasant Mediterranean climate with dry and warm summers and mild winters.
							</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Major Telephone Codes and Numbers
								</h2>
							</div>
							<ul>
								<li>International prefix: + 382</li>
								<li>Information: 1181</li>
								<li>Exact time: 125</li>
								<li>Fire department: 123</li>
								<li>Police and Emergency: 122 or 112</li>
								<li>Emergency: 124</li>
							</ul>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Currency
								</h2>
							</div>
							<p>The official currency of Montenegro is the Euro.</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Banks
								</h2>
							</div>
							<p>
								Banks are open Monday through Friday, usually
								opening at 08:00 or 08:30 and closing around
								16:00. On Saturdays, they close at 12:00. You
								can only change currencies at banks. ATMs work
								around the clock.
								<br></br>
								<p>
									Payment in shops, hotels and restaurants can
									be made by credit or debit card.
								</p>
							</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Supermarkets and Shops
								</h2>
							</div>
							<p>
								Supermarkets tend to open at 07:00 and close at 22:00, except on Sundays, when grocery stores and clothes boutiques do not open at all.
							</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Health care
								</h2>
							</div>
							<p>
								Pharmacies are open usually from 08:00 and they
								are open until 20:00 or 21:00.
							</p>
							<br></br>

							<p>
								Emergency works around the clock. All cities
								have Emergency health systems.
							</p>
							<br></br>

							<p>
								Hospitals and Health Centers works 7.00 to
								20.00. Health care for foreigners is covered by
								the international laws. Before traveling to
								Montenegro please check your Health insurance
								coverage.
							</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Visa
								</h2>
							</div>
							<p>
								Residents of most European countries do not need
								a visa. For more details on a specific country,
								see the{" "}
								<a
									href="https://www.gov.me/en/diplomatic-missions/embassies-and-consulates-of-montenegro"
									target="_blank"
									className="text-blue-800 underline"
								>
									link
								</a>.
							</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									COVID-19
								</h2>
							</div>
							<p>
								There are currently no COVID-19 travel
								restrictions in place for travel into
								Montenegro, regardless of citizenship.
							</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									How to get to Herceg Novi from nearby airports:
								</h2>
							</div>
							<ul>
								<li>
									<a
										className="text-blue-800 underline"
										href="https://www.rome2rio.com/map/Dubrovnik-Airport-%C4%8Cilipi-Croatia/Herceg-Novi"
										target="_blank"
									>
										Dubrovnik
									</a>
								</li>
								<li>
									<a
										className="text-blue-800 underline"
										href="https://www.rome2rio.com/map/Podgorica/Herceg-Novi"
										target="_blank"
									>
										Podgorica
									</a>
								</li>
								<li>
									<a
										className="text-blue-800 underline"
										href="https://www.rome2rio.com/map/Tivat/Herceg-Novi"
										target="_blank"
									>
										Tivat
									</a>
								</li>
								<li>
									<a
										className="text-blue-800 underline"
										href="https://www.rome2rio.com/map/Tirana/Herceg-Novi"
										target="_blank"
									>
										Tirana
									</a>
								</li>
							</ul>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Transportation in Herceg Novi
								</h2>
							</div>
							<p className="underline font-bold">
								Local city busses
							</p>
							<div className="p-5">
								<p>
									For transport trough Herceg Novi you can use
									local city busses (Blue Line).
								</p>
								<br></br>
								<p>
									For the Congress delegates most suitable
									lines are: Line 1 (Igalo – Kamenari via Old
									Town, return Kamenari – Igalo via main city
									road) and Line 2 (Igalo – Meljine via Old
									Town, return Meljine – Igalo via main city
									road).
								</p>
								<br></br>

								<p>Ticket price on every line is €1.</p>
								<p>
									Timetable and map of all local lines are
									avaible on {" "}
									<a
										href="http://blueline-mne.com/index.php/gradski-prevoz/gradski-prevoz-herceg-novi"
										target="_blank"
										className="text-blue-600"
									>
										link
									</a>.
								</p>
								<br></br>
							</div>

							<p className="underline font-bold">Taxi</p>
							<div className="p-5">
								<p>
									There are 2 taxi services operating in
									Herceg Novi:
								</p>
								<br></br>

								<p>Taxi More</p>
								<br></br>

								<p className="pl-5">
									Phone / Viber - <a href="tel:+38269019730" className="text-blue-600">+38269019730</a>
									<br></br>
									Application for phone –{" "}
									<a
										href="https://play.google.com/store/apps/details?id=com.autocab.taxibooker.taximore19730.hercegnovi1"
										target="_blank"
										className="text-blue-600"
									>
										Taxi More on Google Play
									</a>.
								</p>
								<br></br>

								<p>Taxi Maestro</p>
								<br></br>

								<p className="pl-5">
									Phone / Viber - <a href="tel:+38269019530" className="text-blue-600">+38269019530</a>
									<br></br>
									Application for phone –{" "}
									<a
										href="https://play.google.com/store/apps/details?id=com.navigator.maestromne"
										target="_blank"
										className="text-blue-600"
									>
										Taxi Maestro on Google Play
									</a>.
								</p>
							</div>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Herceg Novi
								</h2>
							</div>
							<p>
								More information about host town of BCLF2023 
								<a
									href="https://www.hercegnovi.travel/en/"
									target="_blank"
									className="text-blue-600 pl-2 pr-2"
								>
									here
								</a>
								and
								<a
									href="https://www.hercegnovi.city/"
									target="_blank"
									className="text-blue-600 pl-2"
								>
									here
								</a>.
							</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Montenegro
								</h2>
							</div>
							<p>
								More information about country

								<a
									href="https://www.montenegro.travel/en"
									target="_blank"
									className="text-blue-600 pl-2"
								>
									here
								</a>.
							</p>
						</div>
					</main>
				</div>
			</div>
			<Footer />
		</div>
	);
};
