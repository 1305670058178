import React from 'react'
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

export const CongressOrganizingCommittee = () => {

  return (
    <div className="home-root">
      <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
        <Header />
        <div className='w-full relativeh-screen mt-20 pb-20'>
          <main>
            <div className="w-full sm:w-4/6 m-auto px-5 pt-20">
              <div>
                <div className='shadow-md p-10'>
                  <div className="text-center w-full flex justify-start">
                    <div className="">
                      <h1
                        className="text-black text-3xl border-b-4 border-b-[#e78c31] pb-3 leading-[60px]"
                        style={{ fontSize: 50 }}
                      >
                        Organizing Committee
                      </h1>
                    </div>
                  </div>
                  <p>
                    Montenegrin Association of Clinical Chemistry and Laboratory Medicine – MACC (with great help from
                    MACC dear friend Velimir Lazarevic) is the organizer of this Meeting.
                  </p>

                  <h4>
                    Organizing Committee members are:
                  </h4>
                  <ul>
                    <li>Tanja Antunovic</li>
                    <li>Najdana Gligorovic Barhanovic</li>
                    <li>Velimir Lazarevic</li>
                    <li>Marina Jaksic</li>
                    <li>Aleksandra Stefanovic Tomic</li>
                    <li>Marija Djurovic</li>
                    <li>Jelena Boljevic</li>
                    <li>Jana Djapic Savelic</li>
                    <li>Mirjana Pejovic</li>
                    <li>Bojanka Vujicic</li>
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>

      </div>
      <Footer />
    </div>

  )
}