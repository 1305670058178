import React, { useState } from "react";
import { Link } from "react-router-dom";
import mne from '../../assets/mne-zastava.png'
import eng from '../../assets/eng-zastava.png'
import useLanguage from "../../redux/hooks/useLanguage";
import SubMenu from "../SubMenu";
import logo from '../../assets/logo-white.svg'
import langService from "../../_helpers/lang-service";

export default function Header({ isMain }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [menu, showMenu] = useState("");
  const [lang,setLang] = useLanguage();

  const isEng = lang === "ENG";

  const handleLanguageMne = () => {
    localStorage.setItem('BCFL_LANG', JSON.stringify("MNE"));
    setLang("MNE");
    window.location.reload();
  }


  const handleLanguageEng = () => {
    localStorage.setItem('BCFL_LANG', JSON.stringify("ENG"));
    setLang("ENG");
    window.location.reload();
  }

  

  return (
    <header>
      
      <nav className={" flex flex-wrap items-center justify-between px-2 py-3 bg-transparent mb-3 absolute top-0 left-0 z-50 w-full "} style={{zIndex: 999, backgroundColor: (isMain ? "transparent" : "#001746")}}>
        <div className=" container px-4 mx-auto py-8 sm:py-4 flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start ">
            <div className=" absolute -top-24 lg:-top-14 -left-36  lg:-left-20 z-50">
                <Link to='/' 
                className="text-xl font-bold leading-relaxed inline-block mr-4 py-3 whitespace-nowrap uppercase text-white relative"
                >
                    {true ? <img src={logo} className="w-96 h-52" /> : ""}
                </Link>
            </div>
            

            <Link to='/' 
              className=" text-xl font-bold leading-relaxed inline-block  whitespace-nowrap uppercase text-white relative"
            >
                
            </Link>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="material-icons">menu</i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center transition-all rounded-lg z-50 " +
              (navbarOpen ? "mt-10 flex bg-blue-500 lg:bg-transparent" : " hidden bg-transparent")
            }
            id="example-navbar-danger"
          >
            <ul className={"flex flex-col lg:flex-row list-none lg:ml-auto py-2 pb-5"}>
              <li className="nav-item relative" onClick={() => showMenu((prev) => prev === "information" ? "" : "information")} >
                <div
                  className="cursor-pointer px-1 py-3 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                >

                  <span className="ml-1 text-white" style={{ fontSize: 12}}>Information</span>
                  <i className="material-icons  likes-icon" style={{fontSize : '0.93rem',  paddingRight: 4,paddingLeft: 2, cursor: 'pointer', color: '#fff'}}>expand_more</i> 
               
                </div>
                <SubMenu show={false} onClose={()=>showMenu("")} />
                {menu === "information" && <div class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="py-1" role="none">
                    <Link to='/CongressVenue' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      Congress Venue
                    </Link>
                    <Link to='/CongressLanguage' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      Congress Language
                    </Link>
                    
                    <Link to='/GeneralInfo' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      General Info
                    </Link>
                    <Link to='/CongressOrganizingCommittee' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                        Congress Organising Committee
                    </Link>
                    <Link to='/OrganizerAgency' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      Event and Conferences Organizer Agency
                    </Link>
                    <Link to='/Contacts' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                        Contacts
                    </Link>
                    <Link to='/MedTechEurope' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      MedTech Europe
                    </Link>
                    <Link to='/LocalCorner' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      Local Corner
                    </Link>
                  </div>
                </div>}
              </li>
              <li className="nav-item relative" onClick={() => showMenu((prev) => prev === "programme" ? "" : "programme")} >
                <div
                  className="cursor-pointer px-1 py-3 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                >
                  <span className="ml-1 text-white" style={{ fontSize: 12}}>Programme</span>
                  <i className="material-icons  likes-icon" style={{fontSize : '0.93rem',  paddingRight: 4,paddingLeft: 2, cursor: 'pointer', color: '#fff'}}>expand_more</i> 
                </div>
                <SubMenu show={false} onClose={()=>showMenu("")} />
                {menu === "programme" && <div class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="py-1" role="none">
                    <Link to='/Invitations' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      Invitations
                    </Link>
                    <Link to='/Lecturers' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      Lecturers
                    </Link>
                    
                    <Link to='/ScientificProgrammeCommittee' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      Scientific Programme Committee
                    </Link>
                    <Link to='/ScientificProgram' href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                      Scientific Programme
                    </Link>
                    
                  </div>
                </div>}
              </li>
              <li className="nav-item">
                <Link to='/Sponsors'
                  className="px-1 py-3 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                >
                  <span className="ml-1 text-white" style={{ fontSize: 12}}>Sponsors</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to='/Accommodation'
                  className="px-1 py-3 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href=""
                >
                  <span className="ml-1 text-white" style={{ fontSize: 12}}>Accommodation</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to='/AbstractSubmission'
                  className="px-1 py-3 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href=""
                >
                  <span className="ml-1 text-white" style={{ fontSize: 12}}>Poster presentations</span>
                </Link>
              </li>
               <li className="nav-item mt-2 " style={{ marginTop: 5}}>
                <Link to='/Registration' className="p-2 border border-white rounded-md text-white  text-sm cursor-pointer hover:opacity-75" >Info for participants</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}