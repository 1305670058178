import React from 'react'
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

export const MainTopics = () => {

  return (
    <div className="home-root">
      <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
        <Header />
        <div className='w-full relativeh-screen mt-28 pb-20'>
          <main>
            <div className="w-full sm:w-4/6 m-auto px-5 pt-20">
              <div>
                <div className='shadow-md p-10'>
                  <div className="text-center w-full flex justify-start">
                    <div className="">
                      <h1
                        className="text-black text-3xl border-b-4 border-b-[#e78c31] pb-3 leading-[60px]"
                        style={{ fontSize: 50 }}
                      >

                        Main Topics
                      </h1>
                    </div>
                  </div>
                  <ul className=' '>
                    <li>Dyslipidemia, Diabetes, Adiposity</li>
                    <li>Cardiovascular Diseases and Cardiovascular Diseases Risk Prediction</li>
                    <li>Biomarkers in Neurobehavioral and Psychiatric Disorders</li>
                    <li>Bone and Mineral Metabolism</li>
                    <li>Laboratory Hematology</li>
                    <li>Thrombosis and Haemostasis</li>
                    <li>Therapy Drug Monitoring / Medical Toxicology</li>
                    <li>Emerging Technologies in Laboratory Medicine</li>
                    <li>Precision Medicine Technologies and Molecular Diagnostics</li>
                    <li>Allergology / Immunology</li>
                    <li>Nutrition and Nutrigenomics</li>
                    <li>Total Testing Process in Clinical Laboratory</li>
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>

      </div>
      <Footer />
    </div>

  )
}