import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import topics from "../_constants/topics";
import logo from "../assets/logo3.svg";
import abstracts_data from "../data/abstracts";
export const AbstractSubmission = () => {
	const navigate = useNavigate();

	const onSubmitWorkClick = () => {
		alert("Abstract submission is over.");

		return;
		navigate("/SendAbstract");
	};

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header />
				<div className="absolute top-5 left-10 z-50">
					<img src={logo} className="w-96 h-96" />
				</div>
				<div className="w-full relative  mt-28 pb-20">
					<main>
						<div className="pt-32">
							<div className="w-full m-auto px-5 pt-10  flex flex-row flex-wrap">
								<div className="w-full l-3 md:pl-28 pr-10">
									<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit">Poster presentations</h1>
									<div className="pt-5 flex flex-wrap -ml-2">
										{abstracts_data.filter(({ topic }) => topic.length === 0).map(({ abstract_title }) => (
											<div className="p-2 w-1/2">
												<Link to={"/AbstractTopic/" + abstract_title} className="bg-blue-100 cursor-pointer hover:bg-blue-100 w-full h-10 flex justify-center items-center border p-7 rounded-lg shadow-sm border-gray-300">
													<span className="text-black font-semibold">
														{abstract_title}
													</span>
												</Link>
											</div>

										))}
									</div>

								</div>
							</div>
							<div className="w-full m-auto px-5 pt-10  flex flex-row flex-wrap">
								<div className="w-full l-3 md:pl-28 pr-10">
									<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit">
										Book of abstracts (BJCL, vol. XXX)
									</h1>
									<div className="pt-5">
										<div className="">
											<a href={'https://gtpgakufraytdhxtvqri.supabase.co/storage/v1/object/public/bclf/Book%20of%20Abstracts.pdf'} target="_blank" className="p-5   text-black font-bold w-fit text-sm bg-blue-700 text-white transition-all  rounded-lg">
												Download Book of abstracts
											</a>
										</div>

									</div>

								</div>
							</div>
							<div className="w-full m-auto px-5 pt-10  flex flex-row flex-wrap">
								<div className="w-full lg:w-2/3 pl-3 md:pl-28 pr-10">
									<div>
										<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit">Abstract submission</h1>
									</div>
									Congress participants are invited to submit
									abstracts for presentation as a free
									communication at BCLF2023. An unlimited
									number of abstracts may be submitted.
									Presenting author have to register for
									attending the Congress. Abstracts are
									welcome in all fields of laboratory
									medicine, in the broader sense. Free
									communications are primarily presented via
									poster. A limited number of abstracts will
									be selected for oral presentation during
									congress symposia. Authors wishing to orally
									present their work should mark the
									appropriate field on the on-line abstract
									submission form.
									<h2>General requirements</h2>
									The text must be written in proper English.
									Abstracts must contain results. Statements
									such as “results will be discussed” are not
									acceptable and will lead to rejection.
									<h2>General information</h2>
									<div className="pl-5">
										<h3>Deadline for receipt</h3>
										<p className="">
											Abstracts should be submitted by
											21st June 2023
										</p>
										<h3>Confirmation of receipt</h3>

										<p className="">
											Receipt of the abstract will be
											acknowledged by e-mail immediately
											after submission.
										</p>

										<h3>
											Notification of acceptance or
											rejection
										</h3>
										<p className="">
											Authors will be notified of
											acceptance or rejection before 15th
											July 2023.
										</p>

										<h3>Registration</h3>
										<p className="">
											The presenting author of each
											abstract must register for the
											congress.
										</p>
									</div>
									<div>
										<h2>
											How to prepare and submit an
											abstract
										</h2>
										<ul className=" ">
											<li>
												Abstracts should be prepared
												off-line in advance.
											</li>
											<li>
												The body of the abstract should
												be divided into the following
												paragraphs: Background, Methods,
												Results, Conclusions.
											</li>
											<li>
												Do not include title, authors’
												names and institutions in the
												abstract file.
											</li>
											<li>
												Tables and figures are not
												allowed.
											</li>
											<li>References are not allowed.</li>
											<li style={{ lineHeight: "25px" }}>
												When abbreviations are used,
												spell out the full word at first
												mention in the text followed by
												the abbreviation in parentheses.
												Thereafter, use the abbreviation
												throughout.
											</li>
										</ul>
										<p className="pt-10">
											When the on-line system requires the
											abstract to be entered, copy and
											paste your text. Abstracts are
											limited to 3000 characters, spaces
											included (title, authors’ names and
											institutions excluded). {/*The system will automatically notify you if the
											abstract exceeds this number of
											characters. */}Title, author(s) and
											institution(s) must be added
											separately at the appropriate step.
										</p>
									</div>

								</div>
								<div className="w-full lg:w-1/3">
									<div className="pl-2">
										<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit">Abstract topics</h1>
									</div>
									<div className=" w-full border-[#d5d4d4]">
										{topics.map((topic) => (
											<div className="p-4 border-b pl-9 relative">
												<div className="absolute left-3 top-6 bg-[#e78c31] w-[8px] h-[8px]">

												</div>
												<span className="text-black text-sm">
													{topic.label}
												</span>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
			<Footer />
		</div>
	);
};

const ErrorMessage = ({ name }) => (
	<Field name={name}>
		{({ field, form: { touched, errors } }) =>
			touched[name] && errors[name] ? (
				<div className="text-red-500 text-xs">{errors[name]}</div>
			) : null
		}
	</Field>
);
