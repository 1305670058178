import React from "react";
import COUNTRIES from "../../../_constants/countries";
import DropDownList from "../../DropDownList";

const countriesArray = COUNTRIES.map((c) => ({
	label: c.name,
	value: c.name,
}));

const PersonalInfo = ({
	name,
	surname,
	email,
	phone,
	country,
	city,
	postalCode,
	//organisation,
	adress,
	dietary,
	//invitationLetter,
	setName,
	setSurname,
	setEmail,
	setPhone,
	onCountryChange,
	setCity,
	setPostalCode,
	//setOrganisation,
	setAdress,
	setDietary,
	//setInvitationLetter,
    title = "",
    setTitle,
	errors
}) => {

	return (
		<div className="flex flex-wrap">
			<div className="w-full md:w-1/2 lg:w-1/3 pt-2">
				<div class="mb-6 flex gap-3">
					<div className="w-2/5">
						<span
							htmlhtmlFor="surname"
							class="block mb-2 text-sm font-medium text-gray-400 pl-1"
						>
							Title *
						</span>
						<DropDownList
							placeholder="Choose"
							options={[{ label: "Mr.", value: "Mr." }, { label: "Ms.", value: "Ms."  }]}
							selectedValue={title}
							onValueChange={setTitle}
						/>
						{errors?.title && <span className="error">{errors?.title}</span>}
					</div>
					<div className="w-3/5">
						<label
							htmlhtmlFor="name"
							class="block mb-2 text-sm font-medium text-gray-400"
						>
							Name *
						</label>

						<input
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder=""
							type="text"
							id="name"
							style={{ backgroundColor: "#2e80f10d" }}
							class="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							required
						/>
						{errors?.name && <span className="error">{errors?.name}</span>}
					</div>
				</div>
				<div class="mb-6">
					<label
						htmlFor="phoneNumber"
						class="block mb-2 text-sm font-medium text-gray-400 "
					>
						Phone Number *
					</label>
					<input
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
						style={{ backgroundColor: "#2e80f10d" }}
						placeholder=""
						id="phoneNumber"
						type="text"
						class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
						required
					/>
					{errors?.phone && <span className="error">{errors?.phone}</span>}
				</div>
				<div class="mb-6">
					<label
						htmlFor="postalCode"
						class="block mb-2 text-sm font-medium text-gray-400 "
					>
						Postal code *
					</label>
					<input
						value={postalCode}
						onChange={(e) => setPostalCode(e.target.value)}
						style={{ backgroundColor: "#2e80f10d" }}
						placeholder=""
						id="postalCode"
						type="text"
						class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-400 dark:focus:border-blue-400"
						required
					/>
					{errors?.postalCode && <span className="error">{errors?.postalCode}</span>}
				</div>
			</div>
			<div className="w-full md:w-1/2 lg:w-1/3 p-4 pt-2">
				<div class="mb-6">
					<label
						htmlFor="surname"
						class="block mb-2 text-sm font-medium text-gray-400 "
					>
						Surname *
					</label>
					<input
						value={surname}
						onChange={(e) => setSurname(e.target.value)}
						style={{ backgroundColor: "#2e80f10d" }}
						placeholder=""
						type="text"
						id="surname"
						class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-400 dark:focus:border-blue-400"
						required
					/>
					{errors?.surname && <span className="error">{errors?.surname}</span>}

				</div>
				<div class="mb-6">
					<span
						htmlFor="country"
						class="block mb-2 text-sm font-medium text-gray-400 "
					>
						Country *
					</span>
					<DropDownList
						placeholder="Select your country"
						options={countriesArray}
						selectedValue={country}
						onValueChange={onCountryChange}
					/>
					{errors?.country && <span className="error">{errors?.country}</span>}

				</div>
				<div class="mb-6">
					<label
						htmlFor="dietary"
						class="block mb-2 text-sm font-medium text-gray-400 "
					>
						Fax
					</label>
					<input
						value={dietary}
						onChange={(e) => setDietary(e.target.value)}
						style={{ backgroundColor: "#2e80f10d" }}
						placeholder=""
						id="dietary"
						type="text"
						class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-400 dark:focus:border-blue-400"
						required
					/>
				</div>
				{/* <div class="mb-6">
                    <label htmlFor="organisation" class="block mb-2 text-sm font-medium text-gray-400 ">
                    {langService.getLabel("RegistrationPageLabels","UNIVERSITY",lang)}
                    </label>
                    <input 
                        value={organisation} 
                        onChange={(e) => setOrganisation(e.target.value)} 
                        style={{ backgroundColor: "#2e80f10d"}} 
                        placeholder="" 
                        type="text" 
                        id="organisation" 
                        class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-400 dark:focus:border-blue-400" 
                        required
                    />
                </div> */}
				{/* <div class="mb-6 flex flex-row-reverse gap-4 pt-10 pl-1">
                    <label htmlFor="organisation" class="block mb-2 text-sm font-medium text-gray-400 ">
                        Invitation letter needed for Visa issue to enter Montenegro
                    </label>
                    <div className=''>
                        <Switch onChange={() => setInvitationLetter(!invitationLetter)} checked={invitationLetter} />
                    </div>
                </div> */}
			</div>
			<div className="w-full md:w-1/2 lg:w-1/3 p-4 pt-2 relative">
				<div class="mb-6">
					<label
						htmlFor="email"
						class="block mb-2 text-sm font-medium text-gray-400 "
					>
						Email *
					</label>
					<input
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						style={{ backgroundColor: "#2e80f10d" }}
						placeholder="example@gmail.com"
						type="text"
						id="email"
						class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-400 dark:focus:border-blue-400"
						required
					/>
					{errors?.email && <span className="error">{errors?.email}</span>}

				</div>
				<div class="mb-6">
					<label
						htmlFor="surname"
						class="block mb-2 text-sm font-medium text-gray-400 "
					>
						City *
					</label>
					<input
						value={city}
						onChange={(e) => setCity(e.target.value)}
						style={{ backgroundColor: "#2e80f10d" }}
						placeholder=""
						type="text"
						id="city"
						class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-400 dark:focus:border-blue-400"
						required
					/>
					{errors?.city && <span className="error">{errors?.city}</span>}

				</div>
				<div class="mb-6">
					<label
						htmlFor="adress"
						class="block mb-2 text-sm font-medium text-gray-400 focus:text-gray-300"
					>
						Address *
					</label>
					<input
						value={adress}
						onChange={(e) => setAdress(e.target.value)}
						style={{ backgroundColor: "#2e80f10d" }}
						placeholder=""
						type="text"
						id="adress"
						class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-400 dark:focus:border-blue-400"
						required
					/>
					{errors?.adress && <span className="error">{errors?.adress}</span>}

				</div>
			</div>
		</div>
	);
};

export default PersonalInfo;
