import React from "react";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import abstracts_data from "../data/abstracts";
import logo from "../assets/logo3.svg";

export const Posters = () => {
    const { id } = useParams();

    const abcSpeakers = useMemo(() => {
        const arr = abstracts_data.filter(({ topic }) => topic === id).reduce((acc, abstract) => {
            const index = acc.findIndex((a) => a.abc === abstract.topic);
            if (index >= 0) {
                acc[index].speakers.push(abstract);
                return acc;
            }

            return [
                ...acc,
                {
                    abc: abstract.topic,
                    speakers: [
                        abstract
                    ]
                }
            ]


        }, []);

        return arr;
    }, [id]);

    return (
        <div className="home-root">
            <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
                <Header />
                <div className="absolute top-5 left-10 z-50">
                    <img src={logo} className="w-96 h-96" />
                </div>
                <div className="w-full relative  mt-28 pb-20">
                    <main>
                        <div className="pt-32">
                            <div className="w-full m-auto px-5 pt-10  flex flex-row flex-wrap">
                                <div className="w-full pl-3 md:pl-28 pr-10">
                                    <h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit">Poster presentations</h1>
                                </div>
                            </div>
                            <div class="flex flex-col mt-5 md:mx-28 shadow-xl m-5 border ">
                                {abcSpeakers.map((item) => (
                                    <>
                                        <div class="text-xl font-bold p-3 bg-blue-100 rounded-sm">
                                            {item.abc}
                                        </div>
                                        <ul class="list-none bg-white">
                                            {item.speakers.map(({ Name, abstract_title, Country, file_url }) => (
                                                <li key={Name} class="p-2 ml-0 pl-5 border-b bg-white border-gray-300 last-of-type:border-b-0 ">
                                                    <div>
                                                        <div className="font-semibold text-base">
                                                            {abstract_title}
                                                        </div>
                                                        <div className="pt-2 text-gray-600 text-base">
                                                            {Name + ', ' + Country}
                                                        </div>
                                                        <div className="pt-4 pb-2">
                                                            {file_url ? <a href={file_url} target="_blank" className="p-2 border w-fit text-sm hover:bg-blue-100  border-gray-500 rounded-lg">
                                                                Download
                                                            </a> : 
                                                            <span className="p-2 cursor-not-allowed border w-fit text-sm hover:bg-blue-100  border-gray-500 rounded-lg">
                                                                Download
                                                            </span>
                                                        }
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}

                                        </ul>
                                    </>
                                ))}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <Footer />
        </div>
    );

    return (
        <div className="home-root">
            <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
                <Header />
                <div className="hidden sm:block absolute top-10 left-10 z-50">
                    <img src={logo} className="w-96 h-96" />
                </div>
                <main>
                    <div className="mt-48 px-5 m-auto w-full sm:w-3/4 pb-20">
                        <div className="text-center w-full flex justify-center">
                            <div className="">
                                <h1
                                    className="text-black text-3xl border-b-4 border-b-[#e78c31] pb-3"
                                    style={{ fontSize: 50 }}
                                >

                                    Poster presentations

                                </h1>
                            </div>
                        </div>
                        <div class="flex flex-col mt-12">
                            {abcSpeakers.map((item) => (
                                <>
                                    <div class="text-xl font-bold mb-4 p-3 bg-blue-100 rounded-sm">
                                        {item.abc}
                                    </div>
                                    <ul class="list-none pb-1">

                                        {item.speakers.map(({ Name, abstract_title, Country, date, time, imgUrl, text1, text2, text3 }) => (
                                            <li class="p-2 ml-2 border-b border-gray-300 last-of-type:border-b-0 ">
                                                <div>
                                                    <div className="font-semibold">
                                                        {Name + ', ' + Country}

                                                    </div>
                                                    <div className="pt-2 text-gray-600">
                                                        {abstract_title}
                                                    </div>
                                                    <div className="py-2">
                                                        <a href="https://bclf.s3.eu-central-1.amazonaws.com/Granting_EFLM_Auspices_04_23_Herceg-Novi.pdf" target="_blank" className="p-2 border w-fit text-sm hover:bg-blue-100  border-gray-500 rounded-lg">
                                                            Download
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}

                                    </ul>
                                </>
                            ))}
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
