import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import katic from "../assets/panorama.jpg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DropDownList from "../components/DropDownList";
import { Link, useNavigate } from "react-router-dom";

export const Invitations = () => {
	const navigate = useNavigate();

	const onSubmitWorkClick = () => {
		navigate("/SendAbstract");
	};

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header />
				<div className="w-full relativeh-screen mt-28 pb-20">
					<main>
						<div
							className="w-full sm:w-5/6 m-auto px-5 pt-10"
							style={{ maxWidth: 1600 }}
						>
							<div className="mt-10">
								<div class="bg-gray-50 shadow-md p-6 rounded-lg">
									<div className="flex justify-center items-center">
										<h2 class="text-xl font-bold text-center border-b-2 border-b-[#e78c31] pb-2">
											IFCC Welcome Letter
										</h2>
									</div>
									{/* <p class="text-lg mt-4">
										Dear Colleagues, and Friends,
									</p> */}
									<p class="text-lg mt-2">
										On behalf of the IFCC organization, I am delighted to present this welcoming message to all
										attendees of the 2023 Balkan Clinical Laboratory Federation (BCLF), being held in the beautiful
										country of Montenegro. IFCC is pleased to support this year&#39;s annual conference, enabling
										scientific exchange and close interactions among pathologists, laboratory scientists, and
										diagnostic industry colleagues. IFCC and BCLF recently signed a formal memorandum of
										understanding (MOU) to begin to collaborate on a wide range of areas including core training
										programme support for early career and senior medical technologists and laboratory scientists,
										mentoring of senior laboratory medicine specialists on laboratory accreditation, support for
										mutually agreed specific projects, and several other areas.
									</p>
									<p class="text-lg mt-2">
										This is a fast-evolving time for the field of clinical chemistry and laboratory medicine, as we face
										many scientific and technological advancements that allow for a much greater role for our
										professionals as partners at the centre of healthcare. This timely congress is an excellent
										opportunity to gather in a regional and international forum to discuss these advancements as
										well as meet colleagues from across the region and many other countries around the globe. I
										have previously attended BCLF congresses on two other occasions and without a doubt, the
										BCLF congress has proven to be an important forum in Europe bringing together scientists,
										laboratory specialists, clinicians, and industry colleagues in the field of clinical chemistry and
										laboratory medicine from across the Balkan region as well as many neighboring countries.
										During the congress, attendees will get to experience the excellent scientific program, which
										features innovative and diverse educational opportunities including lectures, symposia, recent
										advancements in clinical practice and science, poster presentations, and much more. The
										conference aims to connect the latest technological breakthroughs in diagnostic laboratory
										technology with the best minds in laboratory medicine to help attendees learn and implement
										the latest and greatest in clinical laboratory science, technology, and medicine.
									</p>
									<p class="text-lg mt-2">
										I am confident that each of you will thoroughly relish the remarkable scientific and social
										programs thoughtfully curated by the BCLF congress organizing and scientific committees. I wish
										you all a productive and enjoyable conference and may your stay in Montenegro be delightful
										and enjoyable. Montenegro is renowned for its breath-taking mountains, glacial lakes, the
										elegant beach resorts of the Budva Riviera, and the UNESCO World Heritage listed Bay of Kotor.
										It stands as one of the most exquisite countries globally, offering a wealth of natural, historical,
										and cultural treasures for exploration and appreciation.
									</p>
									<p class="text-lg mt-2 font-bold">
										Professor Khosrow Adeli PhD, FCACB, DABCC, FAACC
									</p>
									<p class="text-lg mt-2 font-bold">
										IFCC President<br></br>
									</p>
								</div>
								<div class="bg-gray-50 shadow-md p-6 rounded-lg mt-10">
									<div className="flex justify-center items-center">
										<h2 class="text-xl font-bold text-center border-b-2 border-b-[#e78c31] pb-2">
											EFLM Welcome Letter
										</h2>
									</div>
									<p class="text-lg mt-4">
										Dear Colleagues, and Friends,
									</p>
									<p class="text-lg mt-2">
										On behalf of the European Federation of Clinical Chemistry and Laboratory Medicine
										(EFLM), it gives me an immense pleasure and a great honor to invite you to attend
										the XXX th Congress of the Balkan Clinical Laboratory Federation (BCLF) and II nd
										Montenegrin Conference of Clinical Chemistry and Laboratory Medicine, which will
										be held from September 27 th to September 30 th in Herceg Novi, Boka bay,
										Montenegro.
									</p>
									<p class="text-lg mt-2">
										The Scientific Program Committee will put together a fantastic multidisciplinary
										program covering a broad range of important themes such as basic concepts,
										advanced diagnostics and techniques used in laboratory medicine. Distinguished
										international speakers and key opinion leaders will deliver presentations in health
										care, on recent diagnostic technologies, scientific advances, and challenges.
									</p>
									<p class="text-lg mt-2">
										It will be a great pleasure for me to be among the Balkan friends and colleagues at
										the BCLF Congress in Montenegro for the excellent scientific program, networking
										and to attend the BCLF Executive Board meeting as the faithful BCLF member more
										than 20 years. I look forward to meeting you at the next BCLF Congress, enjoying
										inspirational scientific program, exhibition, networking, and social activities. I am
										confident that you will find your time rewarding and worthwhile.
									</p>
									<p class="text-lg mt-2 font-bold">
										Professor Tomris Ozben
									</p>
									<p class="text-lg mt-2 font-bold">
										BCLF Past-President<br></br>
										EFLM, President<br></br>
										IFCC, President-Elect<br></br>
									</p>
								</div>

								{/* BCLF */}
								<div class="bg-gray-50 shadow-md p-6 rounded-lg mt-10">
									<div className="flex justify-center items-center">
										<h2 class="text-xl font-bold text-center border-b-2 border-b-[#e78c31] pb-2">
											BCLF Welcome Letter
										</h2>
									</div>
									<p class="text-lg mt-4">
										Dear Colleagues, Dear friends,
									</p>
									<p class="text-lg mt-2">
										On behalf of the Balkan Clinical
										Laboratory Federation (BCLF) it is a
										great pleasure to invite you to take
										part in the XXX BCLF Meeting which will
										be held on September 27-30, 2023 in
										Herceg Novi, Montenegro.
									</p>
									<p class="text-lg mt-2">
										The Meeting will feature plenary
										lectures, symposia, short presentations,
										posters, industrial workshops, and a
										commercial exhibit, plus a social
										program to promote active discussion and
										networking among delegates. It will be a
										synergy between science and professional
										achievements, and a combination of
										keynote talks from leading international
										and regional scientists. Let's combine
										our efforts to strengthen our
										collaboration and friendship for the
										goodwill of our patients and for the
										future of our profession. The Meeting
										place provides excellent conditions for
										congress activities, and the attractions
										of the ancient and modern Herceg Novi
										offer a unique experience for delegates.
										These are exciting times in the world of
										laboratory medicine, and I am sure that
										the XXX BCLF Meeting will be a rewarding
										and unforgettable event for all
										participants.
									</p>
									<p class="text-lg mt-2">
										I look forward to meeting you all in
										beautiful Montenegro.
									</p>
									<p class="text-lg mt-2 font-bold">
										Dobrin Svinarov
									</p>
									<p class="text-lg mt-2 font-bold">
										BCLF President
									</p>
								</div>
								<div class="bg-gray-50 shadow-md p-6 rounded-lg mt-10">
									<div className="flex justify-center items-center">
										<h2 class="text-xl font-bold text-center border-b-2 border-b-[#e78c31] pb-2">
											MACC Welcome Letter
										</h2>
									</div>
									<p class="text-lg mt-4">
										Dear colleagues, associates and friends,
									</p>
									<p class="text-lg mt-2">
										On behalf of the Montenegrin Association
										of Clinical Chemistry and Laboratory
										Medicine (MACC), I’m delighted to invite
										you to the XXX Meeting of the Balkan
										Clinical Laboratory Federation (BCLF)
										and II Montenegrin Conference of
										Clinical Chemistry and Laboratory
										Medicine, which will be held from
										September 27th to September 30th in
										Herceg Novi, Boka bay, Montenegro.
									</p>
									<p class="text-lg mt-2">
										This Meeting will gather laboratory
										professionals as well as established
										scientists from Balkan region, Europe
										and the world. It is our hope that
										lectures, knowledge transfer and
										collaboration, will provide exquisite
										professional and scientific background
										for improvement of patients management
										and empowerment of laboratory medicine
										in Montenegro and the whole region.
									</p>
									<p class="text-lg mt-2">
										As we are all aware of, the previous
										years have been very difficult for
										everyone, especially the healthcare
										professionals. Montenegro and the Bay of
										Kotor – as someone said “Amphitheater
										made of stone and sea”, will offer you
										the beautiful, calm and peaceful
										environment for strengthening
										professional bonds and making new
										friendships.
									</p>
									<p class="text-lg mt-2">
										I look forward to meeting you during
										this joint event.
									</p>
									<p class="text-lg mt-2 font-bold">
										Tanja Antunović
									</p>
									<p class="text-lg mt-2 font-bold">
										MACC President
									</p>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
			<Footer />
		</div>
	);
};
