import { createSlice } from '@reduxjs/toolkit'

const item = localStorage.getItem('BCFL_LANG');

const initialState = {
  language:  (item && JSON.parse(item)) || 'ENG'
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload
    }
  }
})

export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer