import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

export const MedtechEurope = () => {

  return (
    <div className="home-root">
      <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
        <Header />
        <div className="w-full relativeh-screen mt-28 pb-20">
          <main>
            <div className="w-full sm:w-5/6 m-auto px-5 pt-20">
              <div className="shadow-md p-10">
                <div className="w-fit">
                  <h1 className="text-2xl sm:text-4xl border-b-4 border-b-[#e78c31] pb-1">
                    MedTech Europe
                  </h1>
                  </div>
                  <p>
                    Ethical MedTech is a platform, supported by MedTech Europe, dedicated to ethics
                    and compliance projects in the healthcare sector.
                  </p>

                  <p className="mt-2">
                    BCLF 2023 has been submitted to the Ethical MedTech Conference Vetting
                    System and is COMPLIANT with the MedTech Europe Code of Ethical Business
                    Practice.
                  </p>
                  <p className="mt-2">
                    To view the status of BCLF 2023 on the Ethical MedTech platform, please
                    click <a href="https://www.ethicalmedtech.eu/medtech-apps/cvs/view-event/EMT33375" target="_blank" className="underline text-blue-600">
                                    here
                                    </a>.
                  </p>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
};
