import React from 'react';

const date = new Date();

const Countdown = ({ countdownData, name }) => {
    if (!countdownData.isItBday) {
        return (
            <div className='px-10'>
                <h1 className='heading'>
                    Registration will be open till 3rd September 2023!

                    {/* Countdown to <span className='highlight'>{name}'s</span> Birthday */}
                </h1>
                <div className='countdown-wrapper'>
                    <div className='countdown-box bg-[#001746]'>
                        {countdownData.days}
                        <span className='legend'>Days</span>
                    </div>
                    <div className='countdown-box'>
                        {countdownData.hours}
                        <span className='legend'>Hours</span>
                    </div>
                    <div className='countdown-box'>
                        {countdownData.minutes}
                        <span className='legend'>Minutes</span>
                    </div>
                    <div className='countdown-box'>
                        {countdownData.seconds}
                        <span className='legend'>Seconds</span>
                    </div>
                </div>
            </div>
        );
    }
};

export default Countdown;