
const talks2 = [
    {
        session: "BCLF board meeting",
        chairs: [],
        events: [
            {
                time: "10:00-11:00",
                title: "BCLF board meeting",
                speaker: "",
                isRed: true
            }
        ]
    },
    {
        "session": "Lab-clinical case reports: From diagnostics to diagnosis",
        "chairs": ["Ivana Milasevic, Montenegro"],
        "affs": ["Center for Clinical-Laboratory Diagnostic, Clinical Center of Montenegro, Podgorica, Montenegro"],
        "events": [
            {
                "time": "7:30-8:15",
                "title": "Blood morphology. A microscope: between reality and mind's eye",
                "speaker": "Petra Uljarevic, Slovenia",
                "aff": "University Medical Center, Maribor, Slovenia",

            },
            {
                "time": "8:15-9:00",
                "title": "Antiphospholipid syndrome – diagnostic and therapeutic challenges",
                "speaker": "Aleksandra Antovic, Sweden",
                "aff": "Department of Clinical Sciences, Danderyd Hospital, Karolinska Institutet, Stockholm, Sweden"
            }
        ]
    },
    {
        "session": "Coffee break",
        "chairs": [],
        "events": [
            {
                "time": "9:00-9:15",
                "title": "Coffee break",
                "speaker": ""
            }
        ]
    },
    {
        "session": "Session 3: Laboratory haematology",
        "chairs": ["Sverre Sandberg, Norway", "Snezana Jovicic, Serbia"],
        "affs": [
            `Haraldsplass Deaconess Hospital, Bergen, Norway
            Norwegian Porphyria Centre and Department of Medical Biochemistry and Pharmacology, Haukeland University Hospital, Bergen, Norway
            Department of Global Health and Primary Care, Faculty of Medicine and Dentistry, University of Bergen, Bergen, Norway`,
            "Department of Medical Biochemistry, Faculty of Pharmacy, University of Belgrade, Serbia"
        ],
        "events": [
            {
                "time": "9:15-9:45",
                "title": "An overview on how to diagnose and monitor the different porphyrias",
                "speaker": "Sverre Sandberg, Norway",
                "aff": `Haraldsplass Deaconess Hospital, Bergen, Norway
                Norwegian Porphyria Centre and Department of Medical Biochemistry and Pharmacology, Haukeland University Hospital, Bergen, Norway
                Department of Global Health and Primary Care, Faculty of Medicine and Dentistry, University of Bergen, Bergen, Norway`,
                

            },
            {
                "time": "9:45-10:15",
                "title": "What makes you stronger can kill you: Use of poly-basic peptides as new drug candidates against Plasmodium falciparum",
                "speaker": "Slavica Pavlovic Djuranovic, USA",
                "aff": "Department of Cell Biology and Physiology, Washington University School of Medicine, St. Louis, USA",
            },
            {
                "time": "10:15-10:30",
                "title": "Iron deficiency in nonanaemic COPD patients - Could low haemoglobin density and microcytic anaemia factor be useful?",
                "speaker": "Marko Trtica, Serbia",
                "aff": "Faculty of Pharmacy, Department of Medical Biochemistry, University of Belgrade, Serbia",
            },
            {
                "time": "10:30-10:45",
                "title": "The importance of determining hepcidin-25 for treating anemia in patients with end-stage renal disease",
                "speaker": "Miljan Savkovic, Serbia",
                "aff": "Center of Medical Biochemistry, Clinical Center of Serbia, Belgrade, Serbia",
            }
        ]
    },
    {
        "session": "Plenary lecture",
        "chairs": [],
        "events": [
            {
                "time": "10:45-11:30",
                "title": "Thrombophilia screening - not so straightforward",
                "speaker": "Gary W. Moore, UK",
                "aff": "Haemostasis Unit, Department of Haematology, Cambridge University Hospitals NHS Foundation Trust, Cambridge, United Kingdom",
            }
        ]
    },
    {
        "session": "Session 4: Thrombosis and haemostasis",
        "chairs": ["Jovan Antovic, Sweden", "Jozo Coric, Bosnia and Herzegovina"],
        "affs": [
            "Department of Coagulation Research, Institute for Molecular Medicine and Surgery, Karolinska Institutet, Stockholm, Sweden",
            "Department of Biochemistry, Clinical Center University of Sarajevo, Bosnia and Herzegovina"
        ],
        "events": [
            {
                "time": "11:30-12:00",
                "title": "Laboratory diagnosis of thrombotic microangiopathies: Measurement of ADAMTS-13",
                "speaker": "Jovan Antovic, Sweden",
                "aff": "Department of Coagulation Research, Institute for Molecular Medicine and Surgery, Karolinska Institutet, Stockholm, Sweden",
            },
            {
                "time": "12:00-12:30",
                "title": "One disease, numerous challenges: How to successfully diagnose von Willebrand disease in the laboratory?",
                "speaker": "Ivana Lapic, Croatia",
                "aff": "Department of Laboratory Diagnostics, University Hospital Center Zagreb, Croatia",
            },
            {
                "time": "12:30-13:00",
                "title": "Mainstream in haemostasis in Montenegro, can we keep up?",
                "speaker": "Nikola Bakic, Montenegro",
                "aff": "Hematology Department, Internal Medicine Clinic, Clinical Center of Montenegro, Podgorica, Montenegro",
            },
            {
                "time": "13:00-13:15",
                "title": "Optimal utilization of thrombophilia testing in asymptomatic individuals",
                "speaker": "Milena Velizarova, Bulgaria",
                "aff": "Department of Medical Genetics, Medical University, Sofia, Bulgaria",
            }
        ]
    },
    {
        session: "Commercial workshop 2",
        chairs: [],
        events: [
            {
                time: "13:15-13:45",
                title: "Commercial workshop 2",
                speaker: ""
            }
        ]
    },
    
    {
        session: "Lunch",
        chairs: [],
        events: [
            {
                time: "13:45-15:00",
                title: "Lunch",
                speaker: ""
            }
        ]
    },
    // {
    //     session: "Poster presentations",
    //     chairs: [],
    //     events: [
    //         {
    //             time: "14:15-15:00",
    //             title: "Poster presentations",
    //             speaker: ""
    //         }
    //     ]
    // },
    {
        session: "Session 5: Cardiovascular diseases and cardiovascular disease risk prediction",
        chairs: ["Alexander Haliassos, Greece", "Tomris Ozben, Turkiye"],
        affs: ["International federation of clinical chemistry", "Department of Biochemistry, Faculty of Medicine, Akdeniz University, Antalya, Turkiye"],
        events: [
            {
                time: "15:00-15:30",
                title: "Clinical implications of high-sensitivity cardiac troponin assays in acute coronary syndrome",
                speaker: "Aneta Boskovic, Montenegro",
                aff: "Department of Internal medicine, Faculty of Medicine, University of Montenegro, Podgorica, Montenegro",
            },
            // {
            //     time: "15:30-16:00",
            //     title: "Lecture from Balkan region",
            //     speaker: "",
            //     isRed: true
            // },
            {
                time: "15:30-15:45",
                title:
                    "Clinical and laboratory applications of kynurenine and its pathway products",
                speaker: "Murat Cihan, Turkiye",
                aff: "Ordu University Hospital, Ordu, Turkiye",
            },
            // {
            //     time: "15:30-16:00",
            //     title: "Biomarkers for cardiovascular disease risk in patients with obstructive sleep apnea syndrome",
            //     speaker: "Tomris Ozben, Turkiye"
            // },
            {
                time: "15:45-16:15",
                title: "Early vascular ageing, arterial stiffness and target organ damages in treated hypertensive patients",
                speaker: "Vladimir Prelevic, Montenegro",
                aff: "Clinic for Nephrology, Clinical Center of Montenegro, Podgorica, Montenegro",
            },
            {
                time: "16:15-16:45",
                title: "Protein Bound Uremic Toxins (PBUTs); Potential impact of implementing test panels for PBUTs on patient outcomes",
                speaker: "Ebru Sezer, Turkiye",
                aff: "Department of Medical Biochemistry, Faculty of Medicine, Ege University, Bornova, Izmir, Turkiye",
            },
            {
                time: "16:45-17:00",
                title: "Cathepsin D in cardiovascular diseases and its relationship with cardiovascular mortality",
                speaker: "Muhammed Fevzi Kilinckaya, Turkiye",
                aff: "Public Health Laboratory, Mardin, Turkiye",
            }
        ]
    },
    {
        session: "Commercial workshop 3",
        chairs: [],
        events: [
            {
                time: "17:00-17:30",
                title: "Commercial workshop 3",
                speaker: ""
            }
        ]
    },
    {
        session: "Coffee break",
        chairs: [],
        events: [
            {
                time: "17:30-17:45",
                title: "Coffee break",
                speaker: ""
            }
        ]
    },
    {
        session: "Session 6: Immunology / Allergology",
        chairs: ["Svetlana Ignjatovic, Serbia", "Neda Milinkovic, Serbia"],
        affs: [
            "Department of Medical Biochemistry, Faculty of Pharmacy, University of Belgrade, Serbia",
            "Department of Medical Biochemistry, Faculty of Pharmacy, University of Belgrade, Serbia"
        ],

        events: [
            {
                time: "17:45-18:15",
                title: "Exploring the latest innovations in laboratory diagnostics for drug-induced anaphylaxis: towards personalized approaches and biomarker development",
                speaker: "Urska Bidovec Stojkovic, Slovenia",
                aff: "University Clinic of Respiratory and Allergic Diseases, Golnik, Slovenia",

            },
            {
                time: "18:15-18:45",
                title: "Thyrotropin receptor antibodies: clinical relevance and methodological aspects",
                speaker: "Marija Saric Matutinovic, Serbia",
                aff: "Faculty of Pharmacy, University of Belgrade, Belgrade, Serbia",
            },
            {
                time: "18:45-19:00",
                title: "Analytical and clinical characteristics of serological immunoassays of anti-SARS-CoV-2 antibodies",
                speaker: "Neda Milinkovic, Serbia",
                aff: "Department of Medical Biochemistry, Faculty of Pharmacy, University of Belgrade, Serbia"
            },
            {
                time: "19:00-19:15",
                title: "The bright side of COVID-19 pandemic for patients with allergic conditions",
                speaker: "Verica Jakjimoska, North Macedonia",
                aff: "City General Hospital “8th September”, Skopje, North Macedonia",
            }
        ]
    },
    // {
    //     session: "Commercial workshop 4",
    //     chairs: [],
    //     events: [
    //         {
    //             time: "19:30-20:00",
    //             title: "Commercial workshop 4",
    //             speaker: ""
    //         }
    //     ]
    // },
    
]

const talks1 = [
    {
        session: "Session 1: Bone and mineral metabolism",
        chairs: ["Etienne Cavalier, Belgium", "Aylin Sepici Dincel, Turkiye"],
        affs: [
            "Department of Clinical Chemistry, University of Liege, Belgium",
            "Department of Medical Biochemistry, Faculty of Medicine, University of Gazi, Ankara, Turkiye."
        ],
        events: [
            {
                time: "14:00-14:30",
                title: "Hyperparathyroidism - diagnosis with many horizons",
                speaker: "Emir Muzurovic, Montenegro",
                aff: "Department of Internal Medicine, Endocrinology Section, Clinical Centre of Montenegro, Podgorica, Montenegro",
            },
            {
                time: "14:30-15:00",
                title: "Parathyroid hormone: from the first radio-immunoassay to LC-MS/MS",
                speaker: "Etienne Cavalier, Belgium",
                aff: "Department of Clinical Chemistry, University of Liege, Belgium",
            },
            {
                time: "15:00-15:30",
                title: "Sclerostin and Dickkopf-1 as a new marker of bone desease",
                speaker: "Aylin Sepici Dincel, Turkiye",
                aff: "Department of Medical Biochemistry, Faculty of Medicine, University of Gazi, Ankara, Turkiye.",
            },
            {
                time: "15:30-16:00",
                title: "Bone metabolism markers in physically active postmenopausal women",
                speaker: "Nela Raseta Simovic, Bosnia and Herzegovina",
                aff: "Center for Biomedical Research, Department of Pathophysiology, Faculty of Medicine, University of Banja Luka, Bosnia and Herzegovina",
            }
        ]
    },
    {
        session: "Session 2: Total testing process in clinical laboratory",
        chairs: ["Khosrow Adeli, Canada", "Mario Plebani, Italy"],
        affs: [
            "Molecular Medicine, Research Institute, The Hospital for Sick Children, Toronto, Canada Department of Physiology, University of Toronto, Canada",
            "Department of Laboratory Medicine, University Hospital of Padova, Italy Center of Biomedical Research, Castelfranco Veneto, Italy"
        ],


        events: [
            {
                time: "16:00-16:30",
                title: "Standardization and harmonization in laboratory medicine: data comparability is needed",
                speaker: "Mario Plebani, Italy",
                aff: "Department of Laboratory Medicine, University Hospital of Padova, Italy Center of Biomedical Research, Castelfranco Veneto, Italy",
            },
            {
                time: "16:30-17:00",
                title: "Closing the gaps in adults and paediatric reference intervals: global initiatives",
                speaker: "Khosrow Adeli, Canada",
                aff: "Molecular Medicine, Research Institute, The Hospital for Sick Children, Toronto, Canada Department of Physiology, University of Toronto, Canada",

            },
            {
                time: "17:00-17:30",
                title: `How to use biological variation data to  
                1) set performance specifications  
                2) monitor patients  
                3) generate personal reference`,
                speaker: "Sverre Sandberg, Norway",
                aff: `Haraldsplass Deaconess Hospital, Bergen, Norway
                Norwegian Porphyria Centre and Department of Medical Biochemistry and Pharmacology, Haukeland University Hospital, Bergen, Norway
                Department of Global Health and Primary Care, Faculty of Medicine and Dentistry, University of Bergen, Bergen, Norway`,
            }
        ]
    },
    {
        session: "Commercial workshop 1",
        chairs: [],
        events: [
            {
                time: "17:30-18:00",
                title: "Commercial workshop 1",
                speaker: ""
            }
        ]
    },
    {
        session: "Opening ceremony",
        chairs: [],
        events: [
            {
                time: "18:30-19:00",
                title: "Welcome addresses",
                speaker: ""
            },
            {
                time: "19:00-19:45",
                title: "Opening lecture: Implementation of sustainable practices in medical laboratories. Switching the Clinical Laboratories to Green Labs.",
                speaker: "Tomris Ozben, Turkiye.",
                aff: "Department of Biochemistry, Faculty of Medicine, Akdeniz University, Antalya, Turkiye",
            },
            {
                time: "19:45-21:00",
                title: "Welcome cocktail",
                speaker: ""
            }
        ]
    }
]

const talks4 = [
    {
        session: "Lab-clinical case reports: From diagnostics to diagnosis",
        chairs: ["Tanja Antunovic, Montenegro"],
        affs: [
            "Centre for Clinical Laboratory Diagnostic, Clinical Centre of Montenegro, Podgorica, Montenegro"
        ],
        events: [
            {
                time: "7:30-8:15",
                title: "Lipoprotein (a)",
                speaker: "Adie Viljoen, UK",
                aff: "East and North Hertfordshire, NHS Trust, Stevenage, United Kingdom"
            },
            {
                time: "8:15-9:00",
                title: "Curious Cases of Dyslipidaemia – A Series of Case Reports",
                speaker: "Marina Minic-Novcic, UK",
                aff: "Addenbrooke’s Hospital, Cambridge University Hospitals, NHS Trust, Cambridge, United Kingdom",
            }
        ]
    },
    {
        session: "Coffee break",
        chairs: [],
        events: [
            {
                time: "9:00-9:15",
                title: "Coffee break",
                speaker: ""
            }
        ]
    },
    {
        session: "Session 7: Biomarkers in neurobehavioral and psychiatric disorders",
        chairs: ["Srdjan Djurovic, Norway", "Danica Popovic, Montenegro"],
        affs: [
            "Department of Medical Genetics, Oslo University Hospital, Oslo, Norway",
            "Polyclinic Standard, Podgorica, Montenegro"
        ],
        events: [
            {
                time: "9:15-9:45",
                title:
                    "Multimodal prediction of age of onset of Alzheimer´s disease with potential for treatment stratification",
                speaker: "Ole A. Andreassen, Norway",
                aff: "Division of Mental Health and Addiction, Oslo University Hospital, Oslo, Norway",
            },
            {
                time: "9:45-10:15",
                title:
                    "Using antisense oligoes for treatment of diseases with genetical haploinsufficiency",
                speaker: "Sergej Djuranovic, USA",
                aff: "Washington University School of Medicine, St. Louis, USA",
            },
            {
                time: "10:15-10:45",
                title:
                    "Modeling psychiatric disorders with patient-derived brain organoid cultures",
                speaker: "Srdjan Djurovic, Norway",
                aff: "Department of Medical Genetics, Oslo University Hospital, Oslo, Norway",
            },
            {
                time: "10:45-11:00",
                title:
                    "The influence of COMT gene polymorphism  on the severity of presentation of negative symptomatology in schizophrenia spectrum",
                speaker: "Milena Petrovic, Montenegro",
                aff: "Psychiatric Clinic, Clinical Centre of Montenegro, Podgorica, Montenegro",
            },
            
        ]
    },
    {
        session: "Plenary lecture",
        chairs: [],
        events: [
            {
                time: "11:00-11:45",
                title: "Big data and aging",
                speaker: "Maurizio Ferrari, Italy",
                aff: `University Vita-Salute San Raffaele, Milan, Italy
                Synlab, Italy`,
            }
        ]
    },
    {
        session: "Session 8: Precision medicine technologies and molecular diagnostics",
        chairs: ["Maurizio Ferrari, Italy", "Dobrin Svinarov, Bulgaria"],
        affs: [
            `University Vita-Salute San Raffaele, Milan, Italy
                Synlab, Italy`,
            "Alexandrovska University Hospital, Faculty of Medicine, Medical University, Sofia, Bulgaria"
        ],
        events: [
            {
                time: "11:45-12:15",
                title: "Pharmacogenetics: prevention is key!",
                speaker: "Ron van Schaik, Netherlands",
                aff: "Erasmus MC University Medical Center Rotterdam, Netherlands",
            },
            {
                time: "12:15-12:45",
                title: "RNA editing as a diagnostic test to diagnosis bipolar disorders",
                speaker: "Maurizio Ferrari, Italy",
                aff: `University Vita-Salute San Raffaele, Milan, Italy
                Synlab, Italy`,
            },
            {
                time: "12:45-13:15",
                title:
                    "Clinical mass spectrometry becomes a major tool for precision medicine",
                speaker: "Dobrin Svinarov, Bulgaria",
                aff: "Alexandrovska University Hospital, Faculty of Medicine, Medical University, Sofia, Bulgaria"
            }
        ]
    },
    {
        session: "Commercial workshop 4",
        chairs: [],
        events: [
            {
                time: "13:15-13:45",
                title: "Commercial workshop 4",
                speaker: ""
            }
        ]
    },
    {
        session: "Lunch",
        chairs: [],
        events: [
            {
                time: "13:45-15:00",
                title: "Lunch",
                speaker: ""
            }
        ]
    },
    // {
    //     session: "Poster presentations",
    //     chairs: [],
    //     events: [
    //         {
    //             time: "14:15-15:00",
    //             title: "Poster presentations",
    //             speaker: ""
    //         }
    //     ]
    // },
    {
        session: "Session 9: Advancing healthcare for transgender patients",
        chairs: [
            "Marta Bizic, Serbia",
            "Najdana Gligorovic Barhanovic, Montenegro",
        ],
        affs: [
            "University Children’s Hospital, Faculty of Medicine, University of Belgrade, Serbia",
            "Polyclinic Mojlab, Podgorica, Montenegro",
        ],
        events: [
            {
                time: "15:00-15:30",
                title: "Genital surgery and bioethical aspects in transgender persons",
                speaker: "Marta Bizic, Serbia",
                aff: "University Children’s Hospital, Faculty of Medicine, University of Belgrade, Serbia",
            },
            {
                time: "15:30-16:00",
                title: "Hormone treatment in transgender persons, and interpreting laboratory results",
                speaker: "Jeroen Vervalcke, Belgium",
                aff: "Department of Endocrinology, Ghent University, Ghent, Belgium",
            },
            {
                time: "16:00-16:30",
                title: "Psychiatric aspects in transgender population",
                speaker: "Dusica Markovic Zigic",
                aff: "Psychiatry Clinic, Clinical Hospital Center “Dr Dragisa Misovic”, Belgrade, Serbia",
            }
        ],
    },
    {
        session: "Commercial workshop 5",
        chairs: [],
        events: [
            {
                time: "16:30-17:00",
                title: "Commercial workshop 5",
                speaker: ""
            }
        ]
    },
    {
        session: "Coffee break",
        chairs: [],
        events: [
            {
                time: "17:00-17:15",
                title: "Coffee break",
                speaker: ""
            }
        ]
    },
    {
        session: "Session 10: Dyslipidemia, diabetes, adiposity",
        chairs: ["Nada Majkic-Singh, Serbia", "Marina Minic-Novcic, UK"],
        affs: [
            "Department of Medical Biochemistry, Faculty of Pharmacy, University of Belgrade, Serbia",
            "Addenbrooke’s Hospital, Cambridge University Hospitals, NHS Trust, Cambridge, United Kingdom"
        ],
        events: [
            {
                time: "17:15-17:45",
                title: "Neuroendocrine Regulation of Food Intake and Metabolism via Gut Hormones and Enteric Neurons: Implications in Obesity & Type 2 Diabetes",
                speaker: "Khosrow Adeli, Canada",
                aff: "Molecular Medicine, Research Institute, The Hospital for Sick Children, Toronto, Canada Department of Physiology, University of Toronto, Canada",

            },
            {
                time: "17:45-18:15",
                title: "Diabetes and lipids",
                speaker: "Adie Viljoen, UK",
                aff: "East and North Hertfordshire, NHS Trust, Stevenage, United Kingdom",

            },
            {
                time: "18:15-18:45",
                title: "Familial hypercholesterolaemia, diagnosis and management",
                speaker: "Marina Minic-Novcic, UK",
                aff: "Addenbrooke’s Hospital, Cambridge University Hospitals, NHS Trust, Cambridge, United Kingdom",

            },
            {
                time: "18:45-19:00",
                title:"Association between vitamin D and hypertension in preobese and obese children in Montenegro",
                speaker: "Marina Jaksic, Montenegro",
                aff: "Institute for Childhood Diseases; Clinical Centre of Montenegro, Podgorica, Montenegro",

            },
            {
                time: "19:00-19:15",
                title:
                    "Dyslipidemia and relevant disorders to female reproductive health",
                speaker: "Aleksandra Atanasova-Boshku, North Macedonia",
                aff: "University Clinic for Gynecology and Obstetrics, Skopje, North Macedonia"
            },
        ],
    }

]

const talks5 = [
    {
        session: "Session 11: Masterclass from Balkan region I",
        chairs: ["Anyla Bulo Kasneci, Albania", "Katerina Tosheska-Trajkovska, North Macedonia"],
        affs: [
            "Mother Theresa University Hospital Center, University of Medicine, Tirana, Albania",
            "Department of Medical and Experimental Biochemistry, Faculty of Medicine, University St. Cyril and Methodius, Skopje, North Macedonia",
        ],
        events: [
            { 
                aff: "Department of Medical and Experimental Biochemistry, Faculty of Medicine, University St. Cyril and Methodius, Skopje, North Macedonia", 
                time: "8:00-8:30", 
                title: "The science of women leadership", 
                speaker: "Katerina Tosheska-Trajkovska, North Macedonia" 
            },
            // {
            //     time: "8:30-9:00", 
            //     title: "Medical waste management at the biochemistry laboratory",
            //     speaker: "Irini D. Leimoni, Greece",
            //     aff: "Affidea, Athens, Greece",
            // },
            // {
            //     time: "8:30-9:00", 
            //     title: "Medical waste management at the biochemistry laboratory",
            //     speaker: "Christos Tsatsanis",
            //     aff: "Affidea, Athens, Greece",
            // },
            {
                time: "8:30-9:00", 
                title: "Sepsis biomarkers: from bench to bedside",
                speaker: "Christos Tsatsanis, Greece",
                aff: "Medical School, University of Crete, Greece"
            },
            {
               time: "9:00-9:30", 
               title: "Medical waste management at the biochemistry laboratory",
               speaker: "Irini D. Leimoni, Greece",
               aff: "Affidea, Athens, Greece",
           },
            { 
                aff: "University of Medicine, Tirana, Albania", 
                time: "9:30-9:45", 
                title: "Lipid profile in the context of cardiovascular risk: a new approach of ASoLaM in the role of laboratory specialist in patient counseling", 
                speaker: "Ledina Mino, Albania" 
            },
            { 
                isRed: true, 
                time: "9:45-10:00", 
                subTitle: "Selected oral presentation from Balkan region", 
                title: "Immunomodulatory Effect of Nanomembrane-Based Low-Volume Plasma Exchange on Intestinal Permeability in Metabolic Syndrome",
                speaker: "Vjeroslava Slavic, Montenegro",
                aff: `Institute for Physical medicine, Rehabilitation and Rheumatology "Dr Simo Milosevic", Igalo, Montenegro`
            },
            { 
                isRed: true, 
                time: "10:00-10:15", 
                subTitle: "Selected oral presentation from Balkan region", 
                title: "Early prediction of bloodstream infection with complete blood count parameters: An ex-vivo human whole blood model", 
                speaker: "Ozlem Unay Demirel, Turkiye",
                aff: "Bahcesehir University School of Medicine, Istanbul, Turkiye"
            },
        ]
    },
    {
        session: "Plenary lecture",
        chairs: [],
        events: [
            {
                time: "10:15-11:00",
                title: "Mass spectrometry-based omics",
                speaker: "Julieta Hristova, Bulgaria",
                aff: "Alexandrovska University Hospital, Faculty of Medicine, Medical University, Sofia, Bulgaria",
            }
        ]
    },
    // {
    //     session: "Commercial workshop 6",
    //     chairs: [],
    //     events: [
    //         { time: "10:30-11:00", title: "Commercial workshop 6", speaker: "" },
    //     ]
    // },
    {
        session: "Coffee break",
        chairs: [],
        events: [
            { time: "11:00-11:15", title: "Coffee break", speaker: "" },
        ]
    },
    {
        session: "Session 12: Masterclass from Balkan region II",
        chairs: ["Radivoj Jadric, Bosnia and Herzegovina", "Adina Hutanu, Romania"],
        affs: [
            "Department of Medical Biochemistry, Faculty of Medicine, University of Sarajevo, Bosnia and Herzegovina", 
            `Department of Laboratory Medicine “George Emil” Palade University of Medicine, Pharmacy, Science and
            Technology, Targu Mures, Romania`
        ],
        events: [
            { 
                aff: "Department of Medical Biochemistry, Faculty of Medicine, University of Sarajevo, Bosnia and Herzegovina", 
                time: "11:15-11:45", 
                title: "Old biomarkers and their new use", 
                speaker: "Radivoj Jadric, Bosnia and Herzegovina" 
            },
            // {
            //     time: "11:45-12:15", title: "Sepsis biomarkers: from bench to bedside",
            //     speaker: "Christos Tsatsanis, Greece",
            //     aff: "Medical School, University of Crete, Greece"
            // },
            { 
                aff: "Central Laboratory, Clinical Emergency Hospital, Timisoara, Romania", 
                time: "11:45-12:15", 
                title: "Various types of urinary casts and their diagnostic significance - manual microscopic vs automatic method (IRICELL) - an integrated view", 
                speaker: "Sorin Giju, Romania" 
            },
            {
                time: "12:15-12:45",
                title: "Endocan as a novel marker of cardiometabolic disorders",
                speaker: "Aleksandra Klisic, Montenegro",
                aff: "Center for Laboratory Diagnostics, Primary Health Care Center, Podgorica, Montenegro"
            },
            {
                time: "12:45-13:00",
                title: "Chemical composition, antioxidant and antibacterial activity of essential oil of Satureja montana L.",
                speaker: "Kenan Preljevic, Montenegro",
                aff: "Faculty of Natural Sciences and Mathematics, University of Montenegro, Podgorica, Montenegro"

            }
        ]
    },

    // {
    //     session: "Commercial workshop 7",
    //     chairs: [],
    //     events: [
    //         {
    //             time: "13:30-14:00", title: "Commercial workshop 7", speaker: "",
    //             aff: "",
    //         },
    //     ]
    // },

    {
        session: "Closing remarks",
        chairs: [],
        events: [
            { time: "13:00-13:15", title: "Closing remarks", speaker: "" }
        ]
    }
];


const timeline = [talks1, talks2, talks4, talks5];

export default timeline;