import React, { useEffect, useRef, useState } from 'react'

const platforms = ['Web','Desktop','Mobile','Ios','Android'];
const components = ['Button','Input','Modal','Calendar','Header', 'Sidebar', 'Date Picker', 'Pagination'];


const SubMenu = ({ show,  onClose }) => {
    const windowRef = useRef();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(525);
    const [show2, setShow2] = useState(false);
    const [slected, setSlected] = useState([]);

    useEffect(() => {
        if(show){
            setHeight(250)
            setWidth(200)
            setTimeout(() => {
                setShow2(true);
            }, 100);
            
            if(windowRef?.current){
                windowRef.current.focus();
            }
        }else {

        }
    }, [show])
    
    const handleCloseWindow = () => {
        setTimeout(() => {
            onClose()
            setHeight(0);
            setWidth(0);
            setShow2(false);

        }, 100);
    }

    return (
        <div ref={windowRef} className={' transition absolute top-14 left-0  bg-white z-50 rounded-xl cursor-default ' + (!show ? '' : 'border')} style={{ width: width, height, overflow: "hidden", transition: "0.3s"}} tabIndex={1} onBlur={handleCloseWindow}>
            <div className='w-full  relative '>
            <div class="w-full relative origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                    Invitations
                </a>
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">
                    Lecturers
                </a>
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">
                    Scientific Programme Committee
                </a>
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">
                    Scientific Programme
                </a>
                
                <form method="POST" action="#" role="none">
                    <button type="submit" class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
                </form>
                </div>
            </div>
            </div>
        </div> 
    )

    return (
        <div ref={windowRef} className={' absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ' + (!show ? '' : 'border')} style={{ overflow: "hidden", transition: "0.3s"}} tabIndex={1} onBlur={handleCloseWindow}>
            <div class="w-full relative origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">
                    Invitations
                </a>
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">
                    Lecturers
                </a>
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">
                    Scientific Programme Committee
                </a>
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">
                    Scientific Programme
                </a>
                
                <form method="POST" action="#" role="none">
                    <button type="submit" class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
                </form>
                </div>
            </div>
        </div> 
    )
}

export default SubMenu;
