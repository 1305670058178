
//this component consists of several subcomponents
import React, { Fragment } from 'react';
import {BrowserRouter as Router, Routes , Route} from 'react-router-dom';
import { Provider} from 'react-redux';
import store from './redux/store';
import {About} from './pages/About';
import {Main} from './pages/Main';
import {AboutBCLF} from './pages/AboutBCLF';
import {AboutMACC} from './pages/AboutMACC';
import {AbstractSubmission} from './pages/AbstractSubmission.js';
import {BCLFWelcomeLetter} from './pages/BCLFWelcomeLetter.js';
import {Committees} from './pages/Committees.js';
import {ContactUs} from './pages/ContactUs.js';
import {Eposters} from './pages/Eposters.js';
import {IndustrySponsorSymposia} from './pages/IndustrySponsorSymposia.js';
import {InvitedSpeakers} from './pages/InvitedSpeakers.js';
import {MainTopics} from './pages/MainTopics.js';
import {MedtechEurope} from './pages/MedtechEurope.js';
import {MACCWelcomeLetter} from './pages/MACCWelcomeLetter.js';
import {OralPresentationInstructions} from './pages/OralPresentationInstructions.js';
import {ScientificAgenda} from './pages/ScientificAgenda.js';
import {SponsorsSP} from './pages/SponsorsSP.js';
import {VisaSupport} from './pages/VisaSupport.js';
import { FirstAnnouncment } from './pages/FirstAnnouncment.js';
import './styles/index.css'
import { Speakers } from './pages/Speakers';
import {Speaker} from './pages/Speaker';
import ScrollToTop from './_helpers/ScrollToTop';
import { Invitations } from './pages/Invtiations';
import { Information } from './pages/Information';
import { GeneralInfo } from './pages/GeneralInfo';
import { OrganizerAgency } from './pages/OrganizerAgency';
import { CongressVenue } from './pages/CongressVenue';
import { Contacts } from './pages/Contacts';
import { CongressLanguage } from './pages/CongressLanguage';
import { Accommodation } from './pages/Accommodation1';
import { MedTech } from './pages/MedTech';
import { LocalCorner } from './pages/LocalCorner';
import { AccomodationTransferForm } from './pages/AccomodationTransferForm';
import { CongressOrganizingCommittee } from './pages/CongressOrganizingCommittee';
import { ScientificProgrammeCommittee } from './pages/ScientificProgrammeCommittee';
import SponsorsTest from './pages/SponsorsTest';
import Timeline from './pages/Timeline';
import { Posters } from './pages/Posters';
import { Registration } from './pages/Registration';

function App() {
	
	return (
		<Provider store={store}>
			<Router>
			<ScrollToTop />

				<Fragment>
					<div className="scc-container">
						<Routes>
							<Route path="/" exact element={<Main />}/>
							<Route path="/About"  element={<About />}/>
							<Route path="/Registration"  element={<Registration />}/>
							{/* <Route path="/TestRegistration"  element={<RegistrationNew />}/> */}
							<Route path="/AboutBCLF" element={<AboutBCLF />}/>
							<Route path="/AboutMACC" element={<AboutMACC />}/>
							<Route path="/AbstractSubmission" element={<AbstractSubmission />}/>
							<Route path="/BCLFWelcomeLetter" element={<BCLFWelcomeLetter />}/>
							<Route path="/Committees" element={<Committees />}/>
							<Route path="/ContactUs" element={<ContactUs />}/>
							<Route path="/Eposters" element={<Eposters />}/>
							<Route path="/IndustrySponsorSymposia" element={<IndustrySponsorSymposia />}/>
							<Route path="/InvitedSpeakers" element={<InvitedSpeakers />}/>
							<Route path="/MainTopics" element={<MainTopics />}/>
							<Route path="/MedtechEurope" element={<MedtechEurope />}/>
							<Route path="/MACCWelcomeLetter" element={<MACCWelcomeLetter />}/>
							<Route path="/OralPresentationInstructions" element={<OralPresentationInstructions />}/>
							<Route path="/ScientificAgenda" element={<ScientificAgenda />}/>
							<Route path="/Sponsors" element={<SponsorsTest />}/>
							<Route path="/Timeline" element={<Timeline />}/>
							<Route path="/SponsorsSP" element={<SponsorsSP />}/>
							<Route path="/VisaSupport" element={<VisaSupport />}/>
							<Route path="/FirstAnnouncment" element={<FirstAnnouncment />}/>
							<Route path="/Lecturers" element={<Speakers />}/>
							<Route path="/Lecturer" element={<Speaker />}/>
							<Route path="/Invitations" element={<Invitations />}/>
							<Route path="/Information" element={<Information />}/>
							<Route path="/GeneralInfo" element={<GeneralInfo />}/>
							<Route path="/OrganizerAgency" element={<OrganizerAgency />}/>
							<Route path="/CongressVenue" element={<CongressVenue />}/>
							<Route path="/Contacts" element={<Contacts />}/>
							<Route path="/CongressLanguage" element={<CongressLanguage />}/>
							<Route path="/ScientificProgram" element={<Timeline />}/>
							<Route path="/MedTechEurope" element={<MedTech />}/>
							<Route path="/LocalCorner" element={<LocalCorner />}/>
							<Route path="/Accommodation" element={<Accommodation />}/>
							{/* <Route path="/TestAccommodation" element={<Accommodation />}/> */}
							<Route path="/AccomodationTransferForm" element={<AccomodationTransferForm />}/>
							<Route path="/CongressOrganizingCommittee" element={<CongressOrganizingCommittee />}/>
							<Route path="/ScientificProgrammeCommittee" element={<ScientificProgrammeCommittee />}/>
							<Route path="/AbstractTopic/:id" element={<Posters />}/>
						</Routes>
					</div>
				</Fragment>
			</Router>
		</Provider>
	);
}

export default App;
