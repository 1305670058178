import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useNavigate } from "react-router-dom";

export const Accommodation = () => {
	const navigate = useNavigate();

	const onOpenAccomodationTransferForm = () => {
		navigate("/AccomodationTransferForm");
	};

	return (
		<div className="home-root">
            <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
                <Header isMain />
                <div className='h-96  relative w-full overflow-hidden' style={{ zIndex: 22, height: 400 }}>
                    <div className='absolute top-0 left-0 w-full h-full' style={{ backgroundColor: '#182c54a3', zIndex: 22, background: 'linear-gradient(#001746, #123275)' }} />
                </div>
                <div className='w-full relative z-40 -mt-60 pb-52'>
                    <div className='w-full md:w-3/5 m-auto text-white text-center pb-10'>
                        <h1> Accommodation</h1>
                    </div>
                    <div className='w-full lg:w-4/5 xl:w-4/5 2xl:w-4/6  pb-24 m-auto  rounded-lg shadow-lg  overflow-visible relative bg-white'>
                        
                        <div className='w-5/6 m-auto pt-10'>

						<div className="w-fit mt-5">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Congress Travel Agency
								</h2>
							</div>
							<p>
								Services of accommodation and transfer from / to
								the airport, for BCLF2023 participants and
								accompanying persons, will be provided by the
								Congress Travel Agency {" "}
								<a
									href="http://www.congresstravel.me"
									target="_blank"
									className="text-blue-800 underline"
								>
									(http://www.congresstravel.me)
								</a>
								.
							</p>
							<div className="w-fit">
								<h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
									Hotel
								</h2>
							</div>
							<div>
								<h3 className="text-lg pl-5">Hunguest Hotel Sun Resort</h3>
								<ul>
									<li>
										Address: Sveta Bubala bb Herceg Novi
										85340 
									</li>
									<li>
										Standard room, single use €78,50 / per
										person / per day
									</li>
									<li>
										The price is for bed & breakfast
										service

									</li>
                                    <li>
                                    City tax of €1,50 / per person
										/ per day is included in the price
                                    </li>
								</ul>
							</div>

							<div className="w-fit">
                                <div className="w-fit">
                                    <h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
                                        Transfer
                                    </h2>
                                </div>
								
								<p>
									Congress Travel can provide transfer from
									Airport Podgorica / Tivat / Dubrovnik to
									Herceg Novi, and vice versa. Rates for
									one-way transfer per person are:
								</p>
                                <ul>
									<li>
                                        Airport Podgorica – Herceg Novi (preferred
                                        hotel) - €110,00 / per person / per one
                                        direction
									</li>
									<li>
                                        Airport Tivat – Herceg Novi (preferred
                                        hotel) - €70,00 / per person / per one
                                        direction
									</li>
									<li>
                                        Airport Dubrovnik – Herceg Novi (preferred
                                        hotel) - €80,00 / per person / per one
                                        direction
									</li>
								</ul>
							</div>
                            <div className="w-fit">
                                <div className="w-fit">
                                    <h2 className="text-3xl  border-b-2 border-b-[#e78c31]">
                                        Notes
                                    </h2>
                                </div>
                                <ul>
									<li>
                                        Accommodation reservations for dates out of event timeframe are possible and depend only on hotel availability.
									</li>
									<li>
                                        For additional requests, upgrades or similar, you can contact official Congress Agency: <a className="underline text-blue-600" href="mailto: bclf2023@congresstravel.me">bclf2023@congresstravel.me</a> 
									</li>
								</ul>
                                <div className="w-fit">
                                    <h3 className="text-lg">
                                        Other contacts
                                    </h3>
                                </div>
                                <ul>
									<li>
                                    GSM +382 67 163 878 – Tijana Maslovarić, MICE representative.
									phone / fax: + 382 20 229 550, 229 551, 228 410
									</li>
								</ul>
							</div>
                            <div className="p-10 flex justify-center flex-col items-center border-2 mt-5">
                                <h3  className="text-xl">
                                  Delegates who wish to book accommodation and / or transfer services have to fill the form below
                                </h3>  
                                <div className="w-full flex justify-center p-5">
                                    <button
                                        onClick={onOpenAccomodationTransferForm}
                                        className=" text-white font-bold bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300  rounded-xl text-base w-full sm:w-auto px-12 py-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        Accommodation / Transfer form
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
	);
};
