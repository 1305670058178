import React from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const speakers = [
    {
        name: "Prof. Khosrow Adeli, PhD, FCACB, DABCC, FAACC",
        name1: "Prof. Khosrow Adeli",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `Dr. Adeli is the Head of Clinical Biochemistry in the Department of Paediatric Laboratory
        Medicine, as well as a Senior Scientist in the Molecular Medicine Program of the Research
        Institute, at the Hospital for Sick Children in Toronto, Canada. He is also a Full Professor and
        Vice Chair of Quality in the Department of Laboratory Medicine and Pathobiology at the
        University of Toronto. He currently serves as the President of the International Federation of
        Clinical Chemistry and Laboratory Medicine (IFCC), a worldwide organization with 95
        member countries and more than 45,000 laboratory physicians and scientists around the world.
        He is also the current Editor-in-Chief of Critical Reviews in Clinical Laboratory Sciences.`,
        text2: `Since 1988, Dr. Adeli has been actively involved in both molecular and clinical laboratory
        research. He has over 30 years experience in clinical chemistry service, education, and
        research, with over 600 articles and abstracts published to date. His achievements have been
        recognized through several distinctions and careers awards. Dr. Adeli’s main area of research
        is focused on understanding the pathophysiology of obesity, metabolic syndrome, and type 2
        diabetes. Specifically, his laboratory is investigating the neuroendocrine mechanisms
        regulating intestinal and hepatic lipoprotein overproduction in insulin resistant states and the
        role of the gut-brain-liver axis in lipid and lipoprotein metabolism. Dr. Adeli is also active in
        clinical chemistry research and is the principal investigator of the CALIPER (Canadian
        Laboratory Initiative on Pediatric Reference Interval Database) program to establish a
        laboratory reference interval database for biomarkers of pediatric health and disease.`,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Adeli"
    },
    {
        name: "Prof. Ole Andreassen, MD, PhD",
        name1: "Prof. Ole Andreassen",
        text1: `Prof. Andreassen is director of the Centre of Excellence NORMENT and professor at the
        University of Oslo. He is also a co-director at KG Jebsen Centre for Neurodevelopmental
        disorders at University of Oslo. He graduated in 1993, received a PhD at University of Bergen
        in 1996 and completed postdoctoral training from 1998 to 2000 at Harvard Medical School.
        He has been working as a psychiatry specialist at Oslo University Hospital since 2006. Prof.
        Andreassen’s research areas include psychiatric genetics, brain imaging genetics, somatic
        health and immunological factors in severe mental disorders, clinical pharmacogenetics,
        prediction tools and precision medicine. Since 1995 he has received a great number of honours
        and awards, such as: several Awards for the Best Scientific Publication and Excellent
        Researcher Award from the Oslo University Hospital, Bergesen Foundation Prize in 2018,
        University of Oslo's Research Prize in 2020, Dementia Research Prize from Norwegian Health
        Association in 2021, etc. Prof. Andreassen is a member of the editorial board of the following
        scientific journals Schizophr Bull, Acta Psych Scand, W J Biol Psych, Eur J Pharmacol and
        Acta Neuropsychiatrica, also, he is an ad hoc referee in several others. As a grant reviewer, he
        is affiliated with many organizations and foundations. He has published more than 400 papers
        in peer reviewed clinical and scientific journals and has been the main and co-supervisor of 37
        PhD students.`,
        lastName: "Andreassen"
    },
    {
        name: "Assoc. Prof. Aleksandra Antovic / Aleksandra Antović, MD, PhD",
        name1: "Assoc. Prof. Aleksandra Antovic / Aleksandra Antović",
        text1: `Aleksandra Antovic is a senior consultant in rheumatology, at the Rheumatology unit at
        Karolinska University Hospital. She is a head of the outpatient clinic for systemic lupus
        erythematosus at Karolinska University Hospital. Dr Antovic is Associate Professor in
        coagulation research with extensive clinical and research experience in the field of thrombotic
        disorders. During last ten years she has been a PI for several projects investigating thrombotic
        complications in patients with rheumatic diseases. She has significant international
        collaborations resulting in several international projects and more than 50 articles published in
        the per-review journals. Dr Antovic is a member of a Swedish National Working group for
        cardiovascular disorders in patients with rheumatic diseases and Swedish National Working
        group for treatment of patients with rheumatic diseases during pregnancy within Swedish
        Society of Rheumatology.`,
        lastName: "Antovic"
    },
    {
        name: "Prof. Jovan P. Antovic / Jovan P. Antović, MD, PhD",
        name1: "Prof. Jovan P. Antovic / Jovan P. Antović",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `Dr. Antovic is a professor in coagulation & clinical chemistry and a research group leader at
        the department of Molecular Medicine & Surgery at Karolinska Institutet, Stockholm,
        Sweden. In the clinical part he is a senior consultant, medical head of coagulation diagnostics
        at Clinical Chemistry, Karolinska University Laboratory, Karolinska University Hospital,
        Stockholm, Sweden. He is also a visiting professor in hematology at Faculty of Medicine Nis
        and Faculty of Medicine Kragujevac in Serbia. He is a member of EQUALIS (Swedish
        external quality assessment for clinical laboratory investigations) expert group in coagulation.
        He was a co-chair of the Standardization Scientific Committee on the Control of
        Anticoagulation, of the International Society on Thrombosis & Hemostasis.`,
        text2: `He participates in several national and international projects in the field of both thrombosis
        and bleeding and leads a project about global hemostatic assays in hemophilia with the main
        focus of the research on cardiovascular status in aging patients with hemophilia. He is also
        interested in the diagnosis and improvement of treatment of another bleeding disorder - Von
        Willebrand’s disease. Apart from that, his area of interests are microparticles and cross-talk
        between coagulation and inflammation as well as markers of thrombin generation and
        visualization of the fibrin structure. On the clinical side he is particularly interested in the
        improvement of the laboratory diagnostics of microangiopathies e.g heparin induced
        thrombocytopenia, thrombotic thrombocytopenic purpura, antiphospholipid syndrome. He is
        also focused on laboratory methods for follow up of direct oral anticoagulants. He is author or
        co-author in more than 80 articles published in peer-review journals and co-editor of guide
        textbook Essential guide to coagulation (two editions). He was a member of the Editorial
        Board of J Thromb Haemost and currently is a Review Editor on the board of Thrombosis of
        Frontiers in Cardiovascular Medicine.`,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Antovic"
    },
    {
        name: "Asst. Prof. Aleksandra Atanasova-Boshku / Александра Атанасова-Бошку, MD, PhD, EuSpLM",
        name1: "Asst. Prof. Aleksandra Atanasova-Boshku / Александра Атанасова-Бошку",
        text1: `Dr. Aleksandra Atanasova Boshku, a specialist in medical biochemistry, EuSpLM, graduated
        at the Faculty of Medicine, University of St. Cyril and Methodius, Skopje, Republic of
        Macedonia in 2001, where she also completed her specialist studies in medical biochemistry,
        and her PhD. Immediately after graduation, she started her internship at the University Clinic
        for Gynecology and Obstetrics, where she works still, since 2010, at the position of the Head
        of the Department. Since 2020 she is engaged as the Teaching Assistant at the Department for
        Gynecology and Obstetrics of the Faculty of Medicine, University St.Cyril and Methodius,
        Skopje. Dr. Aleksandra Atanasova Boshku is an active member of several national and
        international associations such as the Association of Specialists in Medical Biochemistry and
        Laboratory Medicine, the European Association for Laboratory Medicine, as well as
        International Federation for Clinical Chemistry, Interdisciplinary Association of Doctors
        for Good Clinical Practice, as well as a corresponding member of the ethics group - IFCC
        Task Force on Ethics.`,
        lastName: "Atanasova"
    },
    {
        name: "Nikola Bakic / Nikola Bakić, MD",
        name1: "Nikola Bakic / Nikola Bakić",
        text1: `Dr Bakic has been working as internist-hematologist at Clinic of Hematology at Clinical
        Center of Montenegro since 2018. He graduated from the University of Nis Medical School in
        2002, specialized in internal medicine at University of Nis in 2014 and subspecialized in
        internal medicine – hematology at University of Belgrade Medical School in 2018. He is the
        author of several papers published in prominent international journals. Dr. Bakic is a member
        of Advisory Board for implementation of treatment of patients with haemophilia A and
        member of the Advisory Board for implementation of new drugs in treatment of multiple
        myeloma in Podgorica, Montenegro. He is currently working on two projects on use and safety
        of drugs for hematological patients. Dr Bakic has participated in numerous conferences and
        was an invited speaker at educational workshops “New Guidelines in Hemophilia” and
        “Treatment of Anemia with Application of Contemporary Therapy”, both held in Podgorica in
        2019, as well as an invited speaker at a seminar “The Role of Pharmacists in Homeostasis of
        hematologic Patients” held in Podgorica in 2019.`,
        lastName: "Bakić"
    },
    {
        name: "Urska Bidovec-Stojkovic / Urška Bidovec-Stojkovič, PhD",
        name1: "Urska Bidovec-Stojkovic / Urška Bidovec-Stojkovič",
        text1: `Dr. Bidovec-Stojkovic is currently the deputy head and organizational leader of the Laboratory for
        Clinical Immunology and Molecular Genetics at the University Clinic of Respiratory and Allergic
        Diseases Golnik, Slovenia. Her responsibilities include ensuring the smooth operation of both routine and
        research processes, as well as overseeing the quality of work. She leads the Slovenian national program
        for external quality assessment of laboratory results - Sneqas, in the field of immunoglobulin E
        determination. Her work in this area has contributed significantly to the improvement of laboratory
        testing accuracy and reliability in Slovenia. In addition to her laboratory duties, she is actively involved in
        research, particularly in the areas of molecular allergology and immunology. She is considered a key
        researcher in Slovenia in the field of recombinant allergens, and her work in this area has contributed
        significantly to the development of new diagnostic and therapeutic tools for allergies. She is also an
        expert in the field of allergology and cell biology, especially in the field of anaphylaxis. Overall, Urska’s
        professional work demonstrates a deep understanding of the complex and rapidly evolving field of
        clinical immunology and molecular genetics, as well as a commitment to advancing knowledge and
        improving patient outcomes through high-quality laboratory processes and innovative research. She has
        authored or co-authored numerous research articles, book chapters, and conference proceedings in top-tier
        scientific journals and publications. She is a member of the executive board of the Immunology Society
        of Slovenia, a member of the Slovenian association for allergology and clinical immunology, the
        Slovenian Microbiological Society and the European Society of Mycobacteriology.`,
        lastName: "Bidovec"
    },
    {
        name: "Assoc. Prof. Marta R. Bizic / Marta R. Bižić, MD, PhD",
        name1: "Assoc. Prof. Marta R. Bizic / Marta R. Bižić",
        text1: `Dr. Bizic is a Specialist in Pediatric Surgery with employment in Pediatric Urology
        Department of University Children&#39;s Hospital, Tirsova 10, 11000 Belgrade, Serbia. She
        graduated in 2003 from the School of Medicine, University of Belgrade, Serbia. She became
        Master of Medical Sciences in Pediatric Surgery in 2010, by defending a thesis entitled “One
        day surgery in the treatment of congenital urogenital anomalies” and a Master of
        Organizational Sciences in Health Care Management in 2014, by defending a thesis entitled
        “Strategic marketing planning in pediatric hospital”. Dr. Bizic defended her PhD dissertation
        entitled “Genital reconstruction in male-to-female gender confirmation surgery” in 2017.`,
        text2: `She is a member of many international societies, such as the American Urological
        Association (AUA), the European Association of Urology (EUA), the European Pediatric
        Surgeons’ Association (EUPSA), the Serbian Medical Association (SLD), the World
        Professional Association for Transgender Health (WPATH), European Professional
        Association for Transgender Health (EPATH), Society of Genitourinary Reconstructive
        Surgeons (GURS) and Société Internationale d’Urologie (SIU). Dr. Bizic authored and
        coauthored more than 350 scientific publications and her papers were cited 670 times and the
        Hirsh index is 16. Also, she was awarded for the presentation of her scientific work at
        prestigious European and world congresses of urologists and pediatric surgeons. Dr. Marta
        Bizic is an invited reviewer for several international scientific journals, as well as an invited
        lecturer at numerous domestic and international conferences and congresses. Other than that,
        she is a subcontractor on the Scientific Fund project of the Republic of Serbia IDEAS entitled
        „Possibilities for penile transplantation from live donors: Cadaveric study of surgical anatomy
        and dissection”.`,
        lastName: "Bizic"
    },
    {
        name: "Prof. Aneta Boskovic / Aneta Bošković, MD, MSc, PhD, FESC",
        name1: "Prof. Aneta Boskovic / Aneta Bošković",
        text1: `Prof. Boskovic is a full professor and head of the Department of Internal medicine at Faculty
        of Medicine, University of Montenegro. Since 1992 she is employed at the Cardiology Center
        of the Clinical Center of Montenegro. From 2015 to 2017, she was the head of the Department
        of Cardiology with the Coronary Unit. Prof. Boskovic graduated at Faculty of Medicine,
        University of Belgrade in 1987, defended MSc thesis in cardiology in Belgrade in 1998 and
        PhD thesis in cardiology at the same university in 2002. She completed her specialization in
        internal medicine in 1996 and subspecialisation of cardiology in 2002 at the University of
        Belgrade.`,
        text2: `Professor Boskovic held a series of lectures on call and published a significant number of
        scientific papers in reference medical journals. The author is of the monograph on secondary
        prevention of cardiovascular diseases published by the University of Montenegro. She is a
        coordinator of the mentors for specialisation of internal medicine and a mentor for the field of
        cardiology within postgraduate specialist studies in internal medicine. She has also been a
        mentor for master and doctoral thesis several times. Prof. Boskovic is a president of
        Montenegro Society of Cardiology and EAPC National CVD prevention coordinator. She has
        been a member of two working groups for preparation of national registers from the field of
        cardiology and principal investigator in several international and national studies and projects.
        She is also an editor of Bulletin of Medical Chamber of Montenegro and a member of Medical Committee of the Montenegrin Academy of sciences and Arts. Prof. Boskovic is the head of
        Montenegro Unit of UNESCO Chair in Bioethics too.`,
        lastName: "Bošković"
    },
    {
        name: "Prof. Etienne Cavalier, PhD, EuSpLM",
        name1: "Prof. Etienne Cavalier",
        text1: `Etienne Cavalier is currently Professor of Clinical Chemistry at the University of Liege and Head of the
        Department of Clinical Chemistry of the University Hospital of Liege, Belgium. He graduated as Master
        in Pharmaceutical sciences in 1994 and Advanced Master in Clinical biology in 1999 from the University
        of Liege, which allowed him to be recognized as a European Specialist in Laboratory Medicine
        (EuSpLM). In 2010, he obtained his PhD thesis at the University of Liege. His current research topics are
        bone markers, vitamin D, PTH, vascular calcification markers, markers of acute kidney diseases,
        glomerular filtration rate (estimation, biomarkers), markers of frailty and sarcopenia, LCMS/MS methods
        for steroids and peptides quantification. Prof. Cavalier is the actual President of the Royal Belgian Society
        of Laboratory Medicine and is the Chairman of the IFCC/IOF Committee for bone markers. He is also
        member of the Board of the European Society for Clinical and Economic Aspects of Osteoporosis,
        Osteoarthritis and Musculoskeletal Diseases (ESCEO) and of the Belgian Bone Club. He is member of
        the Committee of scientific advisors of the International Osteoporosis Foundation (IOF) and General
        secretary of ESCEO as well as member of various working groups of the French Society for Clinical Biology. He is also a member of Editorial Boards of several eminent international scientific Journals.
        Professor Cavalier has published more than 400 scientific papers in peer-reviewed Journals indexed on
        Pubmed (his h-index is 59) and has written 8 books or chapter books.`,
        lastName: "Cavalier"
    },
    {
        name: "Assoc. Prof. Murat Cihan / Murat Cihan, MD, EuSPLM",
        name1: "Assoc. Prof. Murat Cihan / Murat Cihan",
        text1: `Dr. Cihan is physician in chief of Ordu University Hospital. Previously, he worked as a head
        of University Hospital Clinical Laboratory at Ordu University. He graduated from Gulhane
        School of Medicine in 2000 and became a specialist in medical biochemistry in 2006. From
        2005 to 2006 he was a researcher at Keio university in Tokyo. He was a laboratory director in
        Corlu Military Hospital (2006-2014), in Girne Military Hospital (2014-2016) and in Van
        Research Hospital (2016-2017). Dr. Cihan has been an associate professor in medical
        biochemistry since 2022. He has published articles and papers in various highly ranked
        scientific journals (H-index WOS: 8 and Google scholar: 9).`,
        lastName: "Cihan"
    },
    {
        name: "Prof. Sergej Djuranovic/Sergej Đuranović, PhD",
        name1: "Prof. Sergej Djuranovic / Sergej Đuranović",
        text1: `Dr. Sergej Djuranovic is currently Professor of Cell Biology and Physiology at Washington
        University School of Medicine in St. Louis, USA. He finished BSc and MSc studies of
        Biochemistry at School of Chemistry at University of Belgrade, Serbia, working on
        interactions of dopamine and serotonin receptors with G-proteins. He earned his PhD degree
        in Biochemistry at Max-Planck Institute for Developmental Biology and Eberhard Karls
        University of Tuebingen, Germany, working on protein evolution and AAA ATPase proteins.
        His postdoctoral training was at Howard Hughes Medical Institute and Johns Hopkins
        University School of Medicine in mechanisms that control gene expression at the level of
        protein synthesis. In 2013 he joined as an assistant professor Department of Cell Biology and
        Physiology at Washington University School of Medicine where he formed his own research
        group. His group studies mechanisms of gene expression regulation at the level of mRNA and
        protein synthesis, with special emphasis on microRNAs, RNA-binding proteins, ribosomes
        and nascent polypeptide chain interactions. His focus of translational studies is within fields of
        early development, cancer, rare genetic disorders, neurodegeneration and malaria. He is an
        author on numerous scientific papers, member of medical and basic science associations as
        well as boards for pharmaceutical companies, science and diversity groups. He has received
        numerous awards and research funds for his research with the latest awards from Chan
        Zuckerberg Initiative for Neurodegenerative Diseases and RTW Charitable Foundation for
        Rare Disease.`,
        lastName: "Djuranovic"
    },
    {
        name: "Prof. Srdjan Djurovic / Srđan Đurović, PhD",
        name1: "Prof. Srdjan Djurovic / Srđan Đurović",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `Professor Djurovic is the group leader of the psychiatric molecular genetics group at the Oslo
        University Hospital, Department of Medical Genetics and core researcher at NORMENT
        Norwegian Centre for Mental Disorders Research/University of Bergen with broad expertise
        in initiating, organizing and leading translational studies in molecular psychiatry and stem cell
        biology. He is the Chair of the European Consortium on Stem Cells in Neuropsychiatric
        Diseases (EURICND) and participates in several large European studies of schizophrenia
        genetics. Dr. Djurovic is also a member of the Psychiatric Genetics Consortium (PGC) and his
        very active collaborative interactions include eminent universities across the world.`,
        text2: `Dr. Djurovic is a specialist in Medical Biochemistry & Clinical Laboratory Diagnostics and he
        got his PhD at University of Zagreb, Croatia, as well as University of Graz, Austria. He earned
        his M. Sc. degree in Biology-Biomedicine at University of Zagreb, Croatia. Dr. Djurovic
        published about 370 articles in the field of neuropsychiatric genetics, stem cells, functional
        genomics and biobanking. His research interests include molecular mechanisms in psychiatry,
        modeling psychiatric disorders using induced pluripotent stem cells (iPSC) technology,
        functional genomics in psychiatry, as well as neuro-immune interactions.`,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Djurovic"
    },
    {
        name: "Prof. Maurizio Ferrari, MD, PhD",
        name1: "Prof. Maurizio Ferrari",
        text1: `Dr. Ferrari is a Full Professor of Clinical Pathology, University Vita-Salute San Raffaele,
        Milan, Italy, and Chief Medical Officer (CMO) of Synlab Italy. He was a Scientific
        Coordinator of Clinical Research, IRCCS H San Raffaele, Milan (1996-1999), Chairman of
        Committee on Clinical Molecular Biology Curriculum of IFCC (2002-2007), member of the
        Education and Management Division of IFCC (2008-2011), Chairman of the Education and
        Management Division of IFCC (2012-2014), member of IFCC Task Force on
        Pharmacogenetics (2008-2014), advisor of CLSI Committee on Molecular Methods.`,
        text2: `Dr. Ferrari  is a Dean of Master Degree in Molecular and Cellular Medical Biotechnology
        (2008 at present) and President of the European Society of Predictive Medicine (2009 at
        present). He is a former IFCC President (2015-2017 and 2019-2020). He received the IFCC-
        Abbott Award for significant contributions in molecular diagnostics (2004), as well as
        honorary membership of the Hungarian Society of Laboratory Diagnostics (2014). He was
        also awarded an honorary Diploma of “Societas Medicorum Bohemorum J.E Purkyne“ (2015)
        and Jendrassik L. Award of Societas Diagnostica Laboratorialis Clinicalis Hungarica (2016)
        as well as SIBioC Award for Internalization (2019). Dr. Ferrari is author of more than 1000 publications: peer reviewed journals: 361 , book: 1, chapter's book: 45 and 610 abstracts at International and National Congress.`,
        "lastName": "Ferrari"
    },
    {
        name: "Muhammed Fevzi Kilinckaya / Muhammed Fevzi Kılınçkaya, MD, EuSPLM",
        name1: "Muhammed Fevzi Kilinckaya / Muhammed Fevzi Kılınçkaya",
        text1: `Muhammed Fevzi Kilinckaya was born in 1988. In 2014, Kilinckaya graduated from the
        Faculty of Medicine and completed his specialty training in Medical Biochemistry between
        2014-2018.  Kilinckaya, who is currently working at Etimesgut Sehit Sait Erturk State
        Hospital, has 12 publications in journals indexed as SCI and SCI-E and 5 oral presentations in
        national and international congresses. Kilinckaya, who has authored chapters in three books
        in the national literature, is a full member of the Information Technologies Group of the
        Turkish Biochemical Society and a corresponding member of the IFCC Committee on
        Clinical Applications of Cardiac Biomarkers. Kilinckaya, who is also a member of the
        European Register of Specialists in Laboratory Medicine, is married and has a son.`,
        "lastName": "Fevzi"
    },
    {
        name: "Sorin Giju / Sorin Gîju, PhD, EuSpLM",
        name1: "Sorin Giju / Sorin Gîju",
        text1: `Sorin Giju is a senior medical chemist, working in the Central Laboratory of Clinical Emergency Hospital
        Timisoara, Romania, the biggest in the western part of the country, since 1996. He holds a PhD at the
        Biochemistry – “Contributions to the study of troponins influence in monitoring progress dialysis
        patients”. He finished MSc at Clinical Laboratory at University of Medicine and Pharmacy
        “IuliuHateganu” in Cluj Napoca. Since 1998 he is a head of the Urinary Biochemistry Department of the
        Clinical Emergency Hospital Timisoara. He had attended numerous conferences both in Romania and
        abroad. In 2013, together with the team of the Nephrology Department of the hospital, he published an
        exhaustive book related to urinary sediment and its usefulness in the diagnosis of kidney disease, namely
        “Atlas of urinalysis from a clinical and laboratory perspective”. Sorin Giju was a recipient of a Gallwas International Travel Grant for 2005 and 2006 and participated at the AACC Annual Meeting in Orlando,
        Florida (2005) and at the AACC Annual Meeting in Chicago, Illinois (2006).`,
        "lastName": "Gîju"
    },
    {
        name: "Asst. Prof. Julieta Hristova / Жулиета Христова, MD, PhD",
        name1: "Asst. Prof. Julieta Hristova / Жулиета Христова",
        text1: `Dr. Hristova holds a PhD in Clinical Laboratory. She is an assistant professor at Medical
        University in Sofia and works at UMHAT Alexandrovska in Sofia as head of Therapeutic
        Drug Monitoring &amp; Clinical Pharmacology at Central Clinical Laboratory. She has a broad
        experience in bioanalytical methods and clinical proteomics after more than 15 years of work
        in the field with focus on protein biomarker analysis, protein separation techniques, and
        handling of large data sets. She has published numerous articles and papers in various
        scientific journals, most of them high ranked, and holds several distinctions for her
        contribution to medical science. Dr. Hristova is assigned a Principal Investigator in a variety
        of scientific research projects to supervise the performance of the analytical process,
        especially when determining novel little-known parameters. She is also a secretary of Balkan
        Clinical Laboratory Federation.`,
        "lastName": "Hristova"
    },
    {
        name: "Prof. Radivoj Jadric / Radivoj Jadrić, MD, PhD",
        name1: "Prof. Radivoj Jadric / Radivoj Jadrić",
        text1: `Dr. Med. Sc. Radivoj Jadric, medical biochemistry specialist, was born on July 16,
        1966, in Sarajevo, where he completed primary and secondary school, and the Faculty of
        Medicine. In the period 1991–2023, he underwent scientific and professional training in the
        country and abroad, with academic advancement to the title of full professor at the
        Department of Medical Biochemistry, Faculty of Medicine, University of Sarajevo.
        In addition to being involved in the high school and higher education teaching process
        at all university cycles (I, II, and III cycles), he is a member of scientific and professional
        commissions, both at universities and at the level of ministries of health, a participant in
        numerous successfully realized domestic and international projects, and is in the management
        of several scientific and professional associations.`,
        text2: `In the course of his professional and scientific work, he profiled himself in the fields
        of good practice in the clinical laboratory, application of molecular methods in medicine,
        investigation of biochemical markers of myocardial damage and risk factors for the
        development of cardiovascular and cerebrovascular disorders, as well as examination of
        changes in the concentration of other biomarkers.`,
        "lastName": "Jadrić"
    },
    {
        name: "Verica Jakjimoska / Верица Јаќимоска, EuSpLM",
        name1: "Verica Jakjimoska / Верица Јаќимоска",
        text1: `Verica Jakjimoska is a specialist in medical biochemistry, EuSpLM. She graduated at the
        Faculty of Pharmacy, University of St. Cyril and Methodius, Skopje, Republic of Macedonia
        in 2011. Jakjimoska became a specialist in Pharmaceutical Affairs in 2014 and specialist of
        medical biochemistry and laboratory medicine in 2017 at the same university. She is also a
        doctorate candidate in medical science in the field of Allergology.`,
        text2: ``,
        "lastName": "Jadrić"
    },
    {
        name: "Asst. Prof. Marina Jaksic / Marina Jakšić, MD, PhD",
        name1: "Asst. Prof. Marina Jaksic / Marina Jakšić",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `Since 2011 until today she has been employed at the Faculty of Medicine in Podgorica as a
        Teaching Assistant for Pathological Physiology and Laboratory Medicine Course. Since 2022
        she has been working as a Medical Doctor specialized in Clinical Biochemistry at the
        Department of Laboratory Diagnostics, Institute for Children’s Diseases of the Clinical Center
        of Montenegro. Her field of interest concerns childhood obesity, obesity biomarkers and
        pathophysiology of metabolic syndrome. She is a current member of Montenegrin Association
        of Clinical Chemistry and Laboratory Medicine (MACC), and European Federation of
        Clinical Chemistry and Laboratory Medicine (EFLM). Currently, she holds the position of the
        IFCC Task Force for Young Scientists (TF-YS) corresponding member on behalf of the
        MACC. She is also the author or co-author of a number of scientific papers published in
        journals that can be found in international and national databases.`,
        text2: ``,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Jaksic"
    },
    {
        name: "Aleksandra Klisic / Aleksandra Klisić, MD, PhD",
        name1: "Aleksandra Klisic / Aleksandra Klisić",
        text1: `Dr Klisic is a Specialist in Clinical Biochemistry and Subspecialist in Laboratory
        Endocrinology in the Center for Laboratory Diagnostics, Primary Health Care Center,
        Podgorica, Montenegro. She is also a certified examiner for Medical laboratories-
        requirements for quality and competence (ISO 15189).
        She graduated in 2004 at the School of Medicine, University of Belgrade, Serbia. She
        received her Master’s degree in 2011 and her Doctor′s degree in 2015. Other than that, she
        became a member of the board of certified specialists in Clinical Biochemistry in 2011 and
        completed her Postgraduate studies in Laboratory Endocrinology in 2018. Her research
        interests include clinical biochemistry, cardiology, diabetology, hepatology, lipidology,
        obesity and oxidative stress.`,
        text2: `Dr. Aleksandra Klisic has published more than 100 articles as an author/co-author and has
        been a project leader/collaborator in several national and international projects. She reviewed
        more than 500 articles. She has been invited as a speaker to a number of regional/international
        conferences.`,
        "lastName": "Klisić"
    },
    {
        name: "Ivana Lapic / Ivana Lapić, PhD",
        name1: "Ivana Lapic / Ivana Lapić",
        text1: `Ivana Lapic, PhD, specialist in Medical Biochemistry and Laboratory Medicine, graduated
        from the Faculty of Pharmacy and Biochemistry, University of Zagreb in 2011. Since 2014 she has
        been working at the Department of Laboratory Diagnostics, University Hospital Center
        Zagreb in Croatia. Her main professional interests include laboratory hematology and
        coagulation, as well as automation and optimization of laboratory processes. She has
        published more than 40 scientific and professional papers, numerous congress abstracts and
        participated as a lecturer on several national conferences in the field of Laboratory Medicine.
        She is a member of the Editorial Board of the journal Clinical Chemistry and Laboratory
        Medicine. She received the Annual Award Kreso Lipovac for the most successful Young
        Scientist by the Croatian Society of Medical Biochemistry and Laboratory Medicine in 2019,
        as well as the scholarship “L’Oreal- UNESCO For women in science“ in Croatia in 2021.`,
        text2: ``,
        "lastName": "Lapic"
    },
    {
        name: "Irini Leimoni / Ειρήνη Λεϊμονή, PhD",
        name1: "Irini Leimoni / Ειρήνη Λεϊμονή",
        text1: `Irini Leimoni studied Biology in the University of Athens and received a PhD in Biology (Biochemistry)
        from the same University. The main topic of her thesis was the purification and study of a specific for
        poly (U) and poly (C) Ribonuclease from human tissues. She worked as Biochemical Analyst and
        member of Technical Management Group and Quality Manager in the Central Clinical Laboratory of
        Euromedica S.A, Athens, Greece (2002-2009). From April 2009, she joined AFFIDEA as Director of
        Laboratories Development and Quality Assurance Officer. In the frame of this position, she worked for
        the development of Clinical Laboratories in several towns of Greece. Since 2016, she is working as
        Country Quality Manager, Country Data Protection Officer and Member of the Clinical Governance
        Committee in AFFIDEA Greece. As from 2008, after special training and evaluation, she joined the
        Hellenic National Accreditation Body (ESYD), as an Assessor and later (2011) as a Lead Assessor for
        Clinical Laboratories (ISO 15189). In the frame of this role, she participated in the assessment of more
        than 350 clinical laboratories in Greece, Albania and Cyprus. She is also Lead Assessor of ESYD for the
        ISO Standards 17043 &amp; ISO 17025. For the period 2017-2020, she was elected member of the executive
        council of the Hellenic Society of Clinical Chemistry-Clinical Biochemistry. She is also a corresponding
        member of the Working Group of EFCC for the Biological Variation.`,
        "lastName": "Leimoni"
    },
    {
        name: "Dusica Markovic Zigic / Dušica Marković Žigić, MD, PhD",
        name1: "Dusica Markovic Zigic / Dušica Marković Žigić",
        text1: `Dr. Markovic Zigic is a specialist in psychiatry, sexologist and research associate at the Faculty of
        Medicine in Belgrade. She completed her graduate studies in medicine, specialist studies in the field of
        psychiatry, as well as master&#39;s and doctoral studies in the field of psychiatry and sexology at the Faculty
        of Medicine of the University of Belgrade, where she also obtained the title of Scientific Associate. She
        has spent her working life so far at the Psychiatry Clinic/Hospital of KBC “Dr. Dragisa Misovic” in
        Belgrade, for the last 10 years as the head of the Department for Psychosomatics and Non-Psychotic
        Disorders. She founded and successfully managed two outpatient counselling centres with her teams:
        Counselling Centre for Sexuality and Gender Dysphoria and Counselling Centre for Women in Crisis.
        She acquired knowledge and skills in the field of group psychotherapy, psychiatry and narcology in
        eminent scientific centres throughout Europe. During the research for her doctoral thesis in the field of
        sexology, she gained additional knowledge in the field of endocrinology, gynaecological endocrinology and women&#39;s health in general. In the last few years, she was a collaborator in two multidisciplinary
        research projects: “Sexual functioning of patients with cardiovascular insufficiency” and “Morphological
        characteristics of the brain of transsexual persons and other developmental disorders”. She is an exhibitor
        and lecturer at domestic and international professional-scientific gatherings and educations with
        numerous printed works. Dr Markovic Zigic is a member of the second generation of the “gender change”
        team and has been included in protection of transsexual and transgender health for years.`,
        "lastName": "Markovic"
    },
    {
        name: "Assoc. Prof. Neda Milinkovic / Neda Milinković, PhD",
        name1: "Assoc. Prof. Neda Milinkovic / Neda Milinković",
        text1: `Neda Milinkovic graduated in 2002 at the University of Belgrade-Faculty of Pharmacy. From
        2002 to 2018, she was employed at the Center for Medical Biochemistry of the Clinical
        Center of Serbia in Belgrade, where she worked as a graduate pharmacist-medical biochemist,
        and from 2012 as a specialist in medical biochemistry. In 2014, she received the PhD from the
        University of Belgrade-Faculty of Pharmacy. From 2018 to 2021, she was employed as an
        Assistant with a PhD, and from 2021, she was employed as an Assistant Professor at the
        Department of Medical Biochemistry, University of Belgrade-Faculty of Pharmacy. Since
        2018, she has been a participant in the project of the Ministry of Education, Science and
        Technological Development of the Republic of Serbia, ON175036 “Biomarkers of organ
        damage and dysfunction”. She was elected a Research Associate at the University of
        Belgrade-Faculty of Pharmacy in 2020. She acquired the professional title of specialist in
        laboratory specialization in laboratory endocrinology in 2021. She is the mentor and a member
        of the student scientific research and the Commission for master’s thesis. She is a member of
        the Society of Medical Biochemists of Serbia (SMBS), where she participates in the
        implementation of the program of continuous medical education and has been a member of the
        Chamber of Biochemists of Serbia since its establishment. Since 2018, she has been a
        corresponding member of the Working Group of the European Association for Laboratory
        Medicine (EFLM) for accreditation and ISO/CEN standards. She is a member of the European
        Academy for Laboratory Medicine Specialists (EuSpLM). She is the author of over 50
        publications and papers published in prominent international and domestic journals and a
        reviewer of many international prominent journals.`,
        text2: ``,
        "lastName": "Milinković"

    },
    {
        name: "Marina Minic-Novcic / Marina Minić-Novčić, MD, MSc, MRCP, PhD",
        name1: "Marina Minic-Novcic / Marina Minić-Novčić",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `Dr Minic-Novcic is currently a Specialist Registrar in Chemical Pathology and Metabolic
        Medicine Cambridge University Hospitals NHS Trust. She is a clinician with more than 15
        years of experience. She is an internal medicine specialist, having worked in Montenegro,
        Serbia and most recently in the NHS in the UK. In 2015 Dr Minic-Novcic, completed doctoral
        studies at the University of Cambridge, after successfully competing and securing the highly
        competitive Gates Scholarship. Her PhD project resulted in identification of a novel syndrome
        of neonatal hypoglycaemia and left sided overgrowth, caused by an activating mutation in
        AKT2, a critical regulator of the insulin signaling cascade. Results of these studies were
        published in journals Science and JCEM. After completion of her PhD studies, Dr Minic-
        Novcic, focused on establishing a career as a practicing physician in the UK. She expanded
        her clinical experience in internal medicine and endocrinology, as well as medical education,
        working at Imperial College Healthcare NHS Trust, and Royal Papworth Hospital NHS
        Foundation Trust. Since 2020, Dr Minic-Novcic has been a trainee in Chemical Pathology and
        Metabolic medicine. Through training she has gained significant experience in managing
        patients with familial hypercholesterolaemia, complex lipid disorders, and complex diabetes.
        She was the sub-investigator in two clinical trials – FLOW and ONWARDS-6.`,
        text2: ``,
        "lastName": "Minic-Novcic"

    },
    {
        name: "Ledina Mino / Ledina Mino, MD",
        name1: "Ledina Mino / Ledina Mino",
        text1: `Ledina Mino studied medicine at the University of Medicine in Tirana. She was specialized in
        Laboratory Medicine at the Laboratory Department of this same University and conducted a
        fellowship at “Azienda Ospedaliero Universitaria di Modena” where she studied blood cell
        morphology at the specialized onco-hematology unit. She is head of a private medical diagnostic
        center in Tirana since 10 years now. Ledina is also a part time lecturer at the University of
        Medicine in Tirana, where she teaches medical biochemistry. In 2017, Ledina was part of IFCC
        Professional Management Exchange Programme (PMEP), at “Azienda Ospedale Universita
        Padova” where she improved her skills on laboratory management, quality control and ISO
        15189 Accreditation. She has successfully conducted the work for accreditation of the laboratory
        she directs, which was accredited with ISO 15189 since 2016. She has also been a member of
        “Albania’s Directorate of Accreditation-Working Group on Accreditation of Medical
        Laboratories” for 3 years, where she contributed in creating specific policies for medical
        laboratories accreditation. Ledina is an active member of Albania’s Society of Laboratory
        Medicine –ASOLAM; this year she was certified by EFLM as TF-GSL expert and appointed
        National Representative of Green and Sustainable Laboratories-Task Force. She was also active
        in many EFLM and IFCC activities and was appointed IFCC Champion in promoting “Global
        Med Lab Week 2023”.`,
        text2: ``,
        "lastName": "Mino"

    },
    {
        name: "Prof. Gary W. Moore, BSc, DBMS, CSci, FIBMS, CBiol, FRSB, CertMHS",
        name1: "Prof. Gary W. Moore",
        text1: `Professor Gary Moore worked in diagnostic pathology for 38 years in the UK NHS, finishing
        as Head of the Diagnostic Haemostasis & Thrombosis Laboratories at St. Thomas’ Hospital in
        London, the largest of their kind in the UK, from 1997-2019. He then took up the post of
        Director of Clinical Diagnostic Research for Technoclone in Vienna, Austria, and in 2020 was
        appointed Honorary Consultant Biomedical Scientist for the Specialist Haemostasis Unit at
        Addenbrooke’s Hospital in the UK, and Visiting Professor for Middlesex University London
        in the UK. His main areas of scientific interest and research are antiphospholipid antibody
        detection, thrombophilia testing, and diagnostic applications of snake venoms. He received his
        Doctor of Biomedical Science degree in 2003 for a thesis on lupus anticoagulant detection. He
        has published 80 papers on laboratory haemostasis/thrombosis diagnostics, including
        guidelines and reviews, published two text books and other book chapters, and is a regular
        peer reviewer for several journals. He is also a regular presenter at national and international
        meetings, and has been consultant or advisory board member for various diagnostic and
        pharmaceutical companies. He is a member of the International Society on Thrombosis and
        Haemostasis and co-chair of their Scientific & Standardisation Committee sub-committee on
        plasma coagulation inhibitors.`,
        text2: ``,
        "lastName": "Moore"
    },
    {
        name: "Asst. Prof. Emir Muzurovic / Emir Muzurović, MD",
        name1: "Asst. Prof. Emir Muzurovic / Emir Muzurović",
        text1: `Dr. Muzurovic is working as an endocrinologist/diabetologist at Department of Endocrinology, Internal
        Clinic of Clinical Center of Montenegro, where he has been employed since 2009. Previously, he worked
        as MD in Primary Health Care Center and Emergency Center in Podgorica. He got his master’s degree in
        2013, the same year he earned his degree of Specialist of Internal Medicine. In 2017, dr. Muzurovic
        became a Subspecialist-Endocrinologist and currently he is working on his doctoral thesis. In addition to
        working in the Clinical Center, since 2016, he has been employed at the Medical Faculty in Podgorica. He
        is also a consultant in two general hospitals in Montenegro. Dr. Muzurovic has authored and co-authored
        several papers published in peer reviewed international journals and he was a speaker at international
        conferences across Europe. From 2021 he has been a member of the Management Committee of
        CA20122 - Harmonizing clinical care and research on adrenal tumours in European countries
        (HARMONISATION) and a member of the Editorial board of Journal of Cardiovascular Pharmacology
        and Therapeutics. Dr. Muzurovic is also a member of ESE, EASD and ENSAT.`,
        text2: ``,
        "lastName": "Muzurovic"
    },
    {
        name: "Prof. Tomris Ozben, MD, EuSpLM, Ph.D, D.Sc. Med. Lab. Specialist",
        name1: "Prof. Tomris Ozben",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `Dr Ozben is the President of the European Federation of Clinical Chemistry and Laboratory
        Medicine (EFLM) and President-Elect of the International Federation of Clinical Chemistry
        and Laboratory Medicine (EFLM). She is the BCLF Past-President and BCLF Executive
        Board member. She is the Chair of the EFLM Task Force-Green and Sustainable Laboratories
        (TF-GSL). She is one of the Board of Directors of the IFCC Foundation for Emerging Nations
        (FEN). She is the FORUM Consultant of the IFCC Task Force-Young Scientists. Over her
        tenure at Akdeniz University, Prof. Ozben has been the Vice Rector, General Director of the
        Central Laboratory of the University Hospital, and Chair of the Dept. of Medical
        Biochemistry, Ethical Committee member of Clinical studies. She is the supervisor at the
        Doctorate Program entitled “Clinical and Experimental Medicine” at the Medical Faculty,
        University of Modena and Reggio Emilia, Italy. She is an external examiner for the Post-
        graduate Master and Ph.D. education at the University of Zimbabwe, College of Health
        Sciences, Department of Chemical Pathology. She is the Honorary Member of the Slovak
        Society of Clinical Biochemistry (SSCB).`,
        text2: `She works in the identification of early diagnostic and prognostic markers in cancer, acute and
        chronic diseases, liquid profiling in cancer, validation of new tests, and laboratory
        management. She is the Editorial Board member of several Scientific Journals. She has
        published over 180 papers, edited several books, and been invited to speak at more than 200
        international conferences.`,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Ozben"
    },
    {
        name: "Slavica Pavlovic Djuranovic / Slavica Pavlović Đuranović, PhD",
        name1: "Slavica Pavlovic Djuranovic / Slavica Pavlović Đuranović",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `Pavlovic Djuranovic received her bachelor’s degree from the University of Belgrade and
        completed her doctoral training at the School of Pharmacy and Chemistry in Tübingen,
        Germany. Slavica completed a postdoctoral fellowship at Johns Hopkins University in the
        Howard Huges Medical Investigator Lab and afterward transitioned to work with Nobel
        Laureate Peter Agre at the Johns Hopkins Bloomberg School of Public Health at Malaria
        Research Institute to study the differences between chimp and human forms of malaria. After
        joining CB&P at Wash U, Slavica spent several years working in parallel with her husband,
        Sergej Djuranovic, in his lab studying long poly-adenosine (poly(A)) stretches that modify
        protein expression. As a tenure-track professor and PI, Slavica will focus on Plasmodium
        falciparum, a protozoan parasite that causes malaria in humans.`,
        text2: `Plasmodium spp. affects more than 50% of the human population every year, resulting in half
        a million deaths, with most victims under the age of five. The Pavlovic-Djuranovic Lab will
        attempt to determine why Plasmodium falciparum translates long poly(A) stretches and the
        resulting protein expression without issue when this is not seen in any other organism. The lab
        has had some early success inhibiting the growth of Plasmodium falciparum. This approach
        has even worked on treatment-resistant strains of malaria. The work of the Pavlovic-Djuranovic Lab has global implications and can help prevent millions of unnecessary deaths in
        the years to come.`,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Pavlovic"
    },
    {
        name: "Milena Petrovic / Milena Petrović, MD",
        name1: "Milena Petrovic / Milena Petrović",
        text1: `Dr. Petrovic was born in 1991 in Cetinje, Montenegro. She finished primary and secondary
        school with excellent results and held the Luca I diploma. She entered the Faculty of
        Medicine of the University of Belgrade in 2010 and graduated in 2016 with the title of Doctor
        of Medicine. After short-term employment at the Institute for Emergency Medical
        Department PJ Kolasin, she began her residency in the field of psychiatry. In the same year,
        in 2018, she enrolled in postgraduate studies at the Faculty of Medicine of the University of
        Montenegro.`,
        text2: ` She received a scholarship for doctoral research from the Ministry of Science of Montenegro.
        As a clinician, she participated in the IMPULS H2020 project, as well as in the COST action
        CA17130.`,
        text3: `During her doctoral research, she trained in the field of psychiatric genetics at the Institute of
        Psychiatry and Neuroscience in Paris (INSERM), as well as at the Faculty of Pharmacy of the
        University of Belgrade. She is the author and co-author of several scientific papers.`,
        "lastName": "Petrović"
    },
    {
        name: "Prof. Mario Plebani, MD",
        name1: "Prof. Mario Plebani",
        date: "JUL. 20",
        text1: `Mario Plebani obtained his medical degree summa cum laude from the Medical School of the
        University of Padova in Italy and completed residency training and specialization in
        Laboratory Medicine, and subsequently in Gastroenterology, at the same University. He is
        Honorary Professor of Clinical Biochemistry and Clinical Molecular Biology at the School of
        Medicine of the University of Padova, Honorary Professor at the University of Buenos Aires
        (Argentina), Facultad de Farmacia y Bioquimica and Adjunct Professor at University of
        Texas, Medical Branch (Department of Pathology). President Elect of the European
        Federation of Laboratory Medicine (EFLM). In 2017 it was appointed Dean of the Medical
        School of the University of Padova for a three years mandate. Prof. Plebani is Editor-in-Chief
        of Clinical Chemistry and Laboratory Medicine, and Editor in Chief of Diagnosis (Dx). He
        has published 1509 full papers, more than 900 abstracts and several books and book chapters,
        HI 115. Invited speakers at many national and international meetings and conferences such as
        the AACC annual meetings in 1998, 2005 and 2007-2012, the IFCC/EFCC International
        Congresses in Florence (1999), Kyoto (2002), the IFCC-FESSC EuroMedLab in Prague
        (2001), Barcelona (2003), Glasgow (2005), Innsbruck (2009), Berlin (2012) and Milan (2013)
        and key-note speaker at many national meetings of IFCC member societies, including
        Australia, UK, Ireland, Spain, Switzerland, Belgium, Finland, Turkey, Chile, Argentina,
        Uruguay, Perù. Awards received: 1991-King Prize for achievement and original research in
        Clinical Enzymology, International Society for Clinical Enzymology (ISE); 2007-AACC
        Management Sciences Division, Outstanding Contributions to Management Sciences Award;
        2008-AACC Award for Outstanding Clinical Laboratory Contributions to Patient Safety;
        2011-ACB (UK) International Lecture Award, 2014-IFCC (International Federation of
        Clinical Chemistry and Laboratory Medicine) Distinguished Award for Laboratory Medicine
        and Patient Care and 2014-AACC/NACB Award for Outstanding Contributions to Clinical
        Chemistry in a Selected Area of Research and the 2014 Canadian Society (CSCC) “Travelling
        Lecture” Award; 2019- AACC 2019 Outstanding Contributions Through Service to the
        Profession of Clinical Chemistry Award. Main areas of research are quality management in
        laboratory medicine, diagnostic and laboratory errors, biomarkers in cancer and cardiovascular
        diseases, and in vitro allergy diagnostics.`,
        text2: ``,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Plebani"
    },
    {
        name: "Vladimir Prelevic / Vladimir Prelević, MD",
        name1: "Vladimir Prelevic / Vladimir Prelević",
        date: "JUL. 20",
        text1: `Dr. Prelevic was born in Podgorica, Montenegro. He has graduated from the School of
        Medicine University of Montenegro. He works in Clinic for nephrology, Clinical Center of
        Montenegro and currently is finishing his residency program in clinical nephrology in
        University Hospital Center Zagreb, Croatia. Also he is in final preparation of his Ph.D. thesis
        in the School of medicine, University of Zagreb Croatia in the area of early vascular aging.
        During his professional work he was involved in the establishment and development of the
        national programme of kidney transplantation in Montenegro and currently he is a member of
        National Commission for transplantation programme, Ministry of Health of Montenegro. He
        is author and co-author of about 20 scientific papers indexed in CC scientific bases. He has
        participated in many international projects, and one of the most important was the HERIC
        project “HLA in MNE”, which resulted in the first accredited laboratory for HLA typing in
        Montenegro. His clinical and research areas of interest are vascular aging, arterial
        hypertension, kidney diseases, molecular and immunological mechanisms of kidney disease,
        but also different modalities of renal replacement therapy and kidney transplantation.`,
        text2: ``,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Prelevic"
    },
    {
        name: "Kenan Preljevic / Kenan Preljević, MSc",
        name1: "Kenan Preljevic / Kenan Preljević",
        text1: `Kenan Preljevic was born in 1997. In 2016 he started with his studies at the Faculty of
        Natural Sciences and Mathematics, department of Biology at the University of Montenegro.
        Three years later he graduated and got a degree, Bachelor of Biology.
        In 2020 he specialised at the department of Experimental biology and Biotechnology. During
        the same year, he did an internship in Prona - Montenegrin Science Promotion Foundation.
        He defended master&#39;s thesis entitled „Phenolic profile and evaulation of antioxidant and
        antimicrobial activity of plant extracts of Centaurium erythraea from Montenegro“ at the
        Faculty of Natural Sciences and Mathematics in Podgorica in 2021.
        Since 2019, he has been acquiring new skills working in the fields of: microbiology and food
        technology, education, molecular biology and PCR diagnostics, clinical biochemistry. In
        2022, he started PhD studies in the fields of biochemistry of medicinal plants and their
        application in molecular biology and microbiology.`,
        text2: ``,
        "lastName": "Preljević"
    },
    {
        name: "Prof. Nela Raseta Simovic/Nela Rašeta Simović, MD, PhD",
        name1: "Prof. Nela Raseta Simovic / Nela Rašeta Simović",
        date: "JUL. 20",
        text1: `Prof. Raseta Simovic is the head of the Department of Pathophysiology and Clinical
        Biochemistry at University of Banja Luka, Faculty of Medicine, as well as coordinator of
        Clinical Biochemistry and Medical Biochemistry specializations. She is also the head of the
        Biochemical - Hematology Laboratory and consultant of the Cabinet for Osteoporosis of the
        Institute for Physical Medicine and Rehabilitation “Dr Miroslav Zotovic“ in Banja Luka. Prof.
        Raseta Simovic is author of several university textbooks, scientific and professional articles
        published in indexed journals, lecturer at national and international congresses, leader of
        several national and international scientific projects and president of the Association of
        Pathophysiologists in Bosnia and Herzegovina.`,
        text2: ``,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Raseta"
    },
    {
        name: "Prof. Sverre Sandberg, MD, PhD",
        name1: "Prof. Sverre Sandberg",
        date: "JUL. 20",
        text1: `Dr. Sandberg is director of the Norwegian Quality Improvement of Laboratory Examinations
        (NOKLUS) and the Norwegian Porphyria Center (NAPOS). 
        He has been chair of the Committee on Evidence-Based Laboratory Medicine in IFCC,
        president of the European Organization for External Quality Assurance Providers in
        Laboratory Medicine (EQALM), president of the European Federation of Clinical chemistry
        and Laboratory Medicine, EFLM and board member of IFCC. He is currently the president of
        the European Porphyria Network Association (EPNET) and chair of the council of the
        International cooperation for harmonization of clinical laboratory results (ICHCLR). He is
        Chair of the EFLM WG of biological variation.`,
        text2: `Prof Sandberg has extensive expertise as a basic scientist in clinical chemistry and
        photobiology, and as a clinician in the diagnosis and treatment of porphyria diseases. His main
        research interests are in the use and interpretations of laboratory tests, external quality control,
        porphyrias, photobiology, evidence based laboratory medicine, post-analytical quality
        assurance and laboratory use in diabetes mellitus. Prof Sandberg has published more than 450
        papers in peer reviewed clinical and scientific journals and several book chapters. He has been
        the main supervisor of 14 PhD students and has given on average 10-15 international lectures
        per year the last 20 years.`,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Sandberg"
    },
    {
        name: "Asst. Prof. Marija Saric Matutinovic / Marija Sarić Matutinović, PhD, teaching assistant, research assistant",
        name1: "Asst. Prof. Marija Saric Matutinovic / Marija Sarić Matutinović",
        date: "JUL. 20",
        text1: `Marija Saric Matutinovic was born in 1987 in Belgrade, Serbia. She graduated from the
        Faculty of Pharmacy, University of Belgrade in 2012. After graduation, she completed a
        mandatory internship at the Center for Medical Biochemistry, University Clinical Center of
        Serbia and passed the state exam for Master of Pharmacy - medical biochemists in 2013. She
        defended her doctoral thesis at the Faculty of Pharmacy, University of Belgrade, module
        Medical Biochemistry, in 2022. She is currently employed as a teaching assistant and research
        assistant at the Department of Medical Biochemistry, Faculty of Pharmacy, University of
        Belgrade. She participated in the realization of 1 national scientific research project funded by
        the Ministry of Education, Science and Technological Development of the Republic of Serbia
        (“Biomarkers of organ damage and dysfunction” led by Prof. Dr. Svetlana Ignjatovic) and is
        currently a member of HI-MOM project team (Science Fund of the Republic of Serbia). She
        authored and reviewed several publications published in prominent international and domestic
        journals. She is a member of the Society of Medical Biochemists of Serbia, the Chamber of
        Biochemists of Serbia, and EFLM Academy (European Federation for Laboratory Medicine
        Academy), as well as a working group member of two ongoing COST actions. Her research
        interest has been mainly focused on the field of laboratory endocrinology, especially thyroid
        autoimmune pathology and thyroid-eye disease.`,
        text2: ``,
        "lastName": "Saric"
    },
    {
        name: "Miljan Savkovic / Miljan Savković, PhD",
        name1: "Miljan Savkovic / Miljan Savković",
        text1: `Miljan Savkovic graduated at the study program Pharmacy - Medical Biochemistry at the
        Faculty of Pharmacy, University of Belgrade in 2011. After successfully passing the
        professional exam, he began his professional engagement at the Center for Medical
        Biochemistry, University Clinical Center of Serbia. In 2022, he defended doctoral dissertation
        “The Importance of Determining Hepcidin-25 and Selected Genetic Variants for Treating
        Anemia in Patients with End-Stage Renal Disease” at the Faculty Pharmacy, University of
        Belgrade. He is currently attending specialist studies for the needs of healthcare, study
        program medical biochemistry.`,
        text2: `For seven years at the Department of Medical Biochemistry of the Faculty of Pharmacy, he
        participated in practical teaching in several courses: Laboratory hematology, Laboratory
        hemostasis and Clinical chemistry with molecular diagnostics. He actively participates in the
        work of foreign and domestic associations in the field of Clinical Chemistry and Laboratory
        Medicine. Within the International Federation of Clinical Chemistry and Laboratory Medicine
        (IFCC), he was engaged for two terms as a member of the Task Force Young Scientists. He is
        a member of the Society of Medical Biochemists of Serbia and a member of the Chamber of
        Biochemists of Serbia. Miljan Savkovic is the author of three manuscripts published in
        international journals, as well as announcements at international and domestic scientific
        meetings with international participation.`,
        "lastName": "Savkovic"
    },
    {
        name: "Prof. Ron NH van Schaik, PhD, FACB",
        name1: "Prof. Ron NH van Schaik",
        text1: `Prof. Schaik is a registered European Specialist Laboratory Medicine and a Full Professor of
        Pharmacogenetics since 2013. He is head of the Dept. Clinical Chemistry at the Erasmus MC
        University Medical Center Rotterdam, and Director of the International (IFCC) Expert center
        for Pharmacogenetics. Main interest is the implementation of pharmacogenetics in clinical
        practice. He published over 350 peer reviewed articles in this field. Specific research topics
        include pharmacogenetic applications in oncology, cardiology, psychiatry and pain
        medication. Additionally, Prof. van Schaik performs research on prostate cancer (PSA) as
        well as on cardiac biomarkers (TnT). Prof van Schaik participates in several national and
        international groups on Pharmacogenetics, such as ESPT (Co-Founder/Past President), PGRN
        (founding member), CPIC, DPWG, IUPHAR, EMA, PharmVar and AMP. He is the recipient
        of the Ortho Clinical Diagnostics Award for Outstanding Research (2001), the AACC
        Outstanding Speaker Award (2009), and the AACC/Mol Pathology Award for Outstanding
        Scientific Research (2010). H-index 81 (Google Scholar).`,
        "lastName": "Schaik"
    },
    {
        name: "Prof. Aylin Sepici Dincel / Aylin Sepici Dinçel, MD, PhD",
        name1: "Prof. Aylin Sepici Dincel / Aylin Sepici Dinçel",
        text1: `Aylin Sepici Dinçel MD, PhD is Professor of Biochemistry, completed her studies in Medicine at
        the University of Gazi, Turkey in 1993. She did her PhD at the Department of Medical
        Biochemistry, Gazi University in the field of diabetes and biochemical alterations during treatments
        (2000). She has been working at Gazi University Faculty of Medicine since 2005 and an executive
        board member of the Turkish Biochemical Society since 2015. She is the head of the Body Fluids
        Working Group of Turkish Biochemical Society, vice editor of Turkish Journal of Biochemistry,
        Turkish Ambassador of FEBS Education Committee, corresponding member of the IFCC
        Congresses and Conferences Committee and IFCC Committee on Bone Metabolism. She is the
        thematic field coordinator of toxicology, which started in 2019 within the scope of Council of
        Higher Education 100/2000 Scholarship.`,
        text2: `Her main research interests include bone markers and bone healing mechanisms, risk assessment
        tools by biochemical markers and both graduate and undergraduate biochemistry laboratory
        education.`,
        "lastName": "Sepici"
    },
    {
        name: "Prof. Ebru Sezer / Ebru Sezer, MD, EuSpLM",
        name1: "Prof. Ebru Sezer / Ebru Sezer",
        text1: `Prof. Sezer graduated from Istanbul University Faculty of Medicine in 1997 as an MD and earned her
        Medical and Clinical Biochemistry Specialist degree from Ege University Faculty of Medicine in 2003.
        Since then, she has been working in the Dept. of Medical Biochemistry in the same university and has
        acquired the Professor of Medical Biochemistry title in 2022. Besides her educational lecturing
        responsibility in Ege University Faculty of Medicine and other Health Science Faculties she has been
        working as a mentor and a member of the Commission of Research Training Programme. She also has
        responsibility in the Pediatric Metabolic and Lysosomal Diseases Laboratory. Her recent work mainly
        concentrates on inherited metabolic diseases, lysosomal storage diseases and LC-MSMS techniques. Ebru
        Sezer is a member of the European Academy for Laboratory Medicine Specialists (EuSpLM) and has
        been a corresponding member of the EFLM Biological Variation WG and a member of the Biological Variation Database Task Group. She has completed many research projects, published more than 40
        scientific papers, numerous congress abstracts and participated as a lecturer in national conferences. She
        has been a member of Turkish Biochemical Society (TBD) and had been a member of the Steering
        Committee TBD Izmir Branch as the treasurer and the secretary between 2010-2018.`,
        "lastName": "Sezer"
    },
    {
        name: "Assoc. Prof. Vjeroslava Slavic / Vjeroslava Slavić, MD, PhD",
        name1: "Assoc. Prof. Vjeroslava Slavic / Vjeroslava Slavić",
        
        text1: `Vjeroslava Slavic, born in 1972 in Podgorica, Montenegro completed her primary and
        secondary education there. She graduated from the Faculty of Medicine at the University of
        Nis, Serbia in 1998 and obtained the title of "Specialist in Immunology" in 2005. Under
        Professor Kamenov's mentorship at the same faculty, she defended her master's thesis on
        "Chemokines Interleukin-8 and Monocyte Chemoattractant Protein-1 in Rheumatoid Arthritis
        Pathogenesis" in 2005. In 2013, she earned a doctorate in clinical immunology with a thesis
        on "Glycogen Phosphorylase Type bb and Heat Shock Protein 27 as Myocardial Stress
        Indicators in Water Polo Players."
        \n
        Since 1998, she has worked at the "Dr. Simo Milosevic" Institute in Igalo, Montenegro
        initially as a departmental physician and later as an immunologist in the outpatient
        department. In 2005, she assumed the role of Head of Laboratory Diagnostics, and in 2018,
        she became the Head of the Center for Scientific Research.
        \n
        From 2017 to 2019, she participated as a researcher in the "BEPMARK" project, exploring
        the effects of Peloid, Mineral Water and Aromatic oils on Inflammatory Response in
        Rheumatoid and Cardiovascular Diseases. In 2020, she became the leader of the research
        team at the Institute Igalo for the project aimed at establishing a Center of Excellence for
        Biomedical Research - "CEBIMER" focusing on implementing Membrane Apheresis to
        improve therapeutic options and health tourism in Montenegro.
        \n 
        She also worked as a lecturer at the "Queen Jelena" Nursing College Lovisenberg University,
        Norway from 2006 to 2010. In 2014, she held the position of a teaching associate at the
        Faculty of Medicine, University of Montenegro, where she taught on study programs Applied
        Physiotherapy and Pharmacy.
        \n 
        Throughout her career, Vjeroslava has been devoted to studying the connection between the
        immune response and preventive medicine. She has presented her findings at various
        international congresses, including the World Congress of Sports Medicine in Puerto Rico in
        2010 and the World Congress of Psychiatry in Portugal in 2019.
        \n 
        As an accomplished author, she has published several scientific papers. Her latest work, titled
        "Nanomembrane-based Apheresis as Safe and Effective Therapy for Cytomegalovirus and
        Epstein-Barr Virus Reactivation," was released in 2021.
        `,
        "lastName": "Sezer"
    },
    {
        name: "Prof. Dobrin Svinarov / Добрин Свинаров, MD, PhD, Dr. Med.Sc",
        name1: "Prof. Dobrin Svinarov / Добрин Свинаров",
        text1: `Dr. Dobrin Svinarov is professor and Head of Clinical Laboratory and Clinical Pharmacology
        at Alexandrovska University Hospital, Faculty of Medicine, Medical University of Sofia,
        former president of the Bulgarian Society of Clinical laboratory, current president of the
        Balkan Clinical Laboratory Federation and academician at the Bulgarian Academy of Science and Art. He had specialized at NIH, Bethesda MD, and had performed visiting professorship
        of paediatric clinical pharmacology at Columbus Children’s Hospital, OSU, USA. As one the
        founding fathers of the International Association of Therapeutic Drug Monitoring and
        Clinical Toxicology (IATDMCT) in 1990 and councillor of its’ Executive Board, he is
        acknowledged as a pioneer of precision medicine and individualized pharmacotherapy in his
        region. Later he was elected a Bureau member and Director at large of the World Association
        of the Societies of Pathology and Laboratory Medicine (WASPaLM) for the period 2013-
        2017 year. Prof. Svinarov is author and co-author of over 350 scientific works, Editorial
        Board member of several peer reviewed journals with hundreds performed evaluations.`,
        "lastName": "Svinarov"
    },
    {
        name: "Prof. Katerina Tosheska-Trajkovska / Катерина Тошеска-Трајковска, MD, PhD, EuSpLM",
        name1: "Prof. Katerina Tosheska-Trajkovska / Катерина Тошеска-Трајковска",
        text1: `Prof. dr. Katerina Tosheska-Trajkovska, a specialist in medical biochemistry, EuSpLM,
        graduated at the Faculty of Medicine, University of St. Cyril and Methodius, Skopje, Republic
        of Macedonia in 1997, where she also completed her specialist studies in medica
        biochemistry. She is MSc in molecular biology and genetic engineering. She obtained her PhD
        in medicine in 2011. She is full professor of chemistry, biochemistry and clinical biochemistry at the Department of biochemistry and clinical biochemistry of the Faculty of Medicine,
        University St.Cyril and Methodius, Skopje. She is full member of the WG- “Register” and
        WG- “Accreditation and ISO/CEN standards” of the EFLM and National representative in the
        IFCC.`,
        text2: `Prof.Tosheska-Trajkovska is a certified internal auditor and technical assessor for
        accreditation of medical laboratories and Deputy President of the Technical Committee for
        medical laboratories of the Institute for accreditation of the Republic of North Macedonia. She
        is Head of the Institute of Medical and Experimental Biochemistry, Medical Faculty in
        Skopje, where she works since 1997. Prof. Tosheska-Trajkovska is a President of the
        Macedonian Society of Medical Biochemistry and Laboratory Medicine.`,
        imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
        "lastName": "Tosheska"
    },
    {
        name: "Marko Trtica / Marko Trtica",
        name1: "Marko Trtica / Marko Trtica",
        text1: `He graduated from Faculty of Pharmacy, Department of Medical Biochemistry, University of
        Belgrade in 2008. After graduation, he completed a mandatory internship at the Center for
        Medical Biochemistry, University Clinical Center of Serbia and passed the state exam for
        Master of Pharmacy - medical biochemists. He is currently PhD candidate at Faculty of
        Pharmacy, University of Belgrade, module Medical Biochemistry. He is a member of the
        Society of Medical Biochemists of Serbia and EFLM Academy (European Federation for
        Laboratory Medicine Academy). His research interest has been mainly focused on the field of
        laboratory hematology, especially anemia and iron deficiency as well as cellular morphology.`,
        text2: ``,
        "lastName": "Trtica"
    },
    {
        name: "Prof. Christos Tsatsanis / Χρήστος Τσατσάνης, PhD",
        name1: "Prof. Christos Tsatsanis / Χρήστος Τσατσάνης",
        text1: `He is a Professor of Clinical Chemistry at Medical School University of Crete, Greece, and
        Director of the Clinical Chemistry Laboratory of the University Hospital of Heraklion, Crete,
        Greece. He received BSc degree in Biology from the University of Athens, Greece (1991),
        and PhD from the Beatson Institute for Cancer Research and the University of Glasgow,
        Scotland in collaboration with the University of Crete, Greece (1995). From 1995 to 1999 he
        was a Postdoctoral fellow at Fox Chase Cancer Center and the Department of Microbiology
        and Immunology, Kimmel Cancer Center, Thomas Jefferson University, Philadelphia, PA,
        USA, working on mechanisms of inflammation and biomarkers of cancer and inflammation.
        Since 2000 he is faculty member at the Laboratory of Clinical Chemistry, Medical School,
        University of Crete, and since 2016 Professor of Clinical Chemistry. In 2009-2010 he worked
        as Visiting Assistant Professor at Tufts University, Boston, MA, USA and in 2016-2017 as
        Visiting Professor at Lund University Medical School, Sweden, remaining until now affiliated
        as Visiting Researcher at the Department of Translational Medicine. He served as Vice Dean
        of the Medical School, University of Crete (2014-2017) and as head of the Technology
        Transfer Office of the University of Crete (2019-2023). His research focuses on analysing
        mechanisms controlling innate immune responses in inflammation and sepsis and identifying
        related biomarkers, including ncRNAs. He has published over 120 peer reviewed papers. He
        was the primary supervisor of 10 PhD thesis and 9 postdoctoral scientists. He participates in
        the Congresses and Conferences Committee of the IFCC and is currently representative of the
        Greek Society of Clinical Chemistry-Biochemistry in EFLM.`,
        "lastName": "Tsatsanis"
    },
    {
        name: "Asst. Prof. Petra Uljarevic / Petra Uljarević",
        name1: "Asst. Prof. Petra Uljarevic / Petra Uljarević",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `She graduated in Biochemistry at the Faculty of Chemistry, University of Belgrade in
        collaboration with the University Medical Centre Ljubljana, Slovenia, on Hormonal profile of
        patients with polycystic ovaries (2005). Since 2007, she has been employed in the Department
        of Laboratory Diagnostics of University Medical Centre in Maribor, Slovenia, where she
        currently holds positions of the head of laboratory for hematology and the head of laboratory
        for urine diagnostics.`,
        text2: `Her professional speciality is Cytology in Bone Marrow Aspirate Analysis. She finished her
        residency training in Laboratory Medicine at the University Medical Centre Maribor, Slovenia
        from 2008 to 2012. Since 2016, she has been a member of the Working group for Laboratory
        Hematology of Slovenia, and since 2021 Medical Assistant at Medical School of the
        University Maribor, Slovenia.`,
        "lastName": "Uljarević"
    },
    {
        name: "Assoc. Prof. Ozlem Unay Demirel / Özlem Unay Demirel, MD, PhD",
        name1: "Assoc. Prof. Ozlem Unay Demirel / Özlem Unay Demirel",
        date: "JUL. 20",
        time: "5:55 p.m. - 6:55 p.m.",
        text1: `Ozlem Unay Demirel is currently working as an Associate Professor of Medical
        Biochemistry at the Bahçeşehir University, Faculty of Medicine, as well as
        laboratory director of BAU Medicine Goztepe Medical Park Hospital. She is
        involved as “General secretary” of Turkish Biochemical Society İstanbul
        branch. She is an author or co-author in more than 40 articles published in peer-
        review journals. Her primary research interest is clinical biochemistry.`,
        "lastName": "Unay"
    },
    {
        name: "Assoc. Prof. Milena Velizarova / Милена Велизарова, MD, PhD, EuSpLM",
        name1: "Assoc. Prof. Milena Velizarova / Милена Велизарова",
        text1: `Prof. Velizarova is the current President of the Bulgarian Society of Clinical Laboratory (BSCL). She is
        the National Representative (NR) in the Balkan Clinical Laboratory Federation (BCLF) and EFLM.
        Presently, prof. Velizarova is the Chair of the Dept. of Clinical Laboratory at Medical University of Sofia.
        She has been working for more than 20 years in the Central Clinical Laboratory at University Hospital
        “Alexandrovska”, Sofia, which includes Haematology, Coagulation, Clinical Chemistry, Therapeutic
        Drug Monitoring, Emergency and Molecular Genetics. She serves as the Chief Coordinator of the Clinical
        Laboratory Expert Board of the Bulgarian Ministry of Health and is a Corresponding Member of the
        EFLM working group “Patient Focused Laboratory Medicine”. Prof. Velizarova is a principal investigator
        of many research projects. Her research interests include laboratory haematology, oncohaematology,
        molecular markers, coagulation, new technologies and their applications in clinical haematology and
        molecular genetics, quality control and management, teaching, mentoring, research, and laboratory
        management. She is an author of 60 peer reviewed manuscripts and 2 book chapters. She has taken part in
        over 50 scientific congresses, conferences and symposia. Teaching Clinical Laboratory Medicine to
        medical and non-medical students, residents, and fellows has been a primary activity in Dr Velizarova’s
        career. Prof. Velizarova is a member of the examination board of certification for Clinical Laboratory
        Specialists.`,
        "lastName": "Velizarova"
    },
    {
        name: "Jeroen Vervalcke, MD",
        name1: "Jeroen Vervalcke",
        text1: `Jeroen Vervalcke is a specialist registrar (Internal Medicine) at the Faculty of Medicine and
        Health Sciences of the University of Ghent, Ghent, Belgium. During his time in Medical
        School, he has taken a special interest in the pharmacokinetics of antimicrobial agents in older
        adults. This lead to the publication of an exhaustive, peer-reviewed systematic review on ß
        lactam antibiotics in geriatric patients. Following his graduation as a Medical Doctor in 2022,
        he enrolled in a doctorate degree programme at his alma mater. His work mainly focusses on
        the cardiometabolic effects of long term gender-affirming hormone therapy (GAHT) in
        gender-diverse individuals. He conducts his research at the Ghent University Hospital,
        Department of Endocrinology, under the promotorship of prof. dr. Guy T’Sjoen. Prof. dr. T’Sjoen is considered one of the leading global authorities on gender-affirming care. The
        Ghent University Hospital is one of the founding members of the European Network for the
        Investigation of Gender Incongruence (ENIGI) study. This is a multicentric, prospective study
        that has provided unique insights into the effects of GAHT.`,
        "lastName": "Vervalcke"
    },
    {
        name: "Assoc. Prof. Adie Viljoen, MD, MBChB, FCP(SA), MMed, FRCPath, MBA",
        name1: "Assoc. Prof. Adie Viljoen",
        text1: `Dr Viljoen is currently working as a Consultant Chemical Pathologist at East and North
        Hertfordshire NHS Trust & Honorary Consultant at Cambridge Universities NHS Trust. He
        has worked as a consultant in the NHS for 18 years. He is program director for Metabolic
        Medicine training in the East of England and Senior Lecturer in Medicine at the University of
        Hertfordshire. He is also clinical responsible for a large biochemistry laboratory which
        services a catchment population of 500 000.`,
        text2: `He conducts numerous phase 2, 3 and 4 clinical trials in lipids, diabetes and obesity and has
        been the UK Chief Investigator, key signatory and co-author for several of these studies
        including the SUSTAIN-7 and SURPASS-2 trials. Dr Viljoen has co-authored more than 170
        papers in peer reviewed medical journals including The Lancet and Diabetes Care and Clinical
        Chemistry as well as several chapters in medical textbooks. He has also served as a topic
        expert for various reviews by NICE such as the Familial Hypercholesterolaemia Quality
        Standards (QS41) and Technology Appraisals for PCSK9 inhibitors and received the reward
        for clinical excellence from the National Health Service in 2019.`,
        "lastName": "Viljoen"
    },
];

export const Speakers = () => {

    const abcSpeakers = useMemo(() => {
        const arr = speakers.reduce((acc, speaker) => {
            const index = acc.findIndex((a) => a.abc === speaker.lastName.charAt(0).toUpperCase());
            if (index >= 0) {
                acc[index].speakers.push(speaker);
                return acc;
            }

            return [
                ...acc,
                {
                    abc: speaker.lastName.charAt(0).toUpperCase(),
                    speakers: [
                        speaker
                    ]
                }
            ]


        }, []);

        return arr;
    }, []);

    return (
        <div className="home-root">
            <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
                <Header />
                <main>
                    <div className="mt-48 px-5 m-auto w-full sm:w-3/4 pb-20">
                        <div className="text-center w-full flex justify-center">
                            <div className="">
                                <h1
                                    className="text-black text-3xl border-b-4 border-b-[#e78c31] pb-3"
                                    style={{ fontSize: 50 }}
                                >
                                    Lecturers
                                </h1>
                            </div>
                        </div>
                        <div class="flex flex-col mt-12">
                            {abcSpeakers.map((item) => (
                                <>
                                    <div class="text-xl font-bold mb-4 p-2 bg-blue-100 rounded-sm">{item.abc}</div>
                                    <ul class="list-none pb-1">

                                        {item.speakers.map(({ name1, name, date, time, imgUrl, text1, text2, text3 }) => (
                                            <li class="p-2 ml-6 sm:ml-10 border-b border-gray-300 last-of-type:border-b-0 underline">
                                                <Link to={{ pathname: "/Lecturer", name, date, time, imgUrl, text1, text2, text3 }} state={{ name, date, time, imgUrl, text1, text2, text3 }} params={{ name, date, time, imgUrl, text1, text2, text3 }}>
                                                    {name1}
                                                </Link>
                                            </li>
                                        ))}

                                    </ul>
                                </>
                            ))}
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    );
};
