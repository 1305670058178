import React, { useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import katic from "../assets/hotel1.jpg";
import { Field } from "formik";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PersonalInfo from "../components/StepForms/PersonalInfo";
import Accommodation from "../components/StepForms/Accommodation";
import Transfers from "../components/StepForms/Transfers/Transfers";
import Invoice from "../components/StepForms/Invoice";
import DropDownList from "../components/DropDownList";

const IP_SERVER2 = "https://bclf-backend.vercel.app";
const IP_SERVER = "http://localhost:5001";

export const AccomodationTransferForm = () => {
	const [loading, setloading] = useState(false);
	const navigate = useNavigate();

	// PERSONAL INFORMATION
	const [title, setTitle] = useState("Mr.");
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [country, setCountry] = useState("");
	const [city, setCity] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [organisation, setOrganisation] = useState("");
	const [adress, setAdress] = useState("");
	const [dietary, setDietary] = useState("");
	//ACCOMMODATION
	const [shouldAccommodate, setShouldAccommodate] = useState("No");
	const [arrivalDate, setArrivalDate] = useState(undefined);
	const [departureDate, setDepartureDate] = useState(undefined);
	//TRANSFER
	const [arrivalTransfer, setArrivalTransfer] = useState("No");
	const [arrivalDateTransfer, setArrivalDateTransfer] = useState(undefined);
	const [arrivalFlightTransfer, setArrivalFlightTransfer] = useState("");
	const [departureDateTransfer, setDepartureDateTransfer] = useState(undefined);
	const [departureFlightTransfer, setDepartureFlightTransfer] = useState("");

	//INVOICE
	const [invoiceIssue, setInvoiceIssue] = useState('No');
	const [invoiceCompany, setInvoiceCompany] = useState('');
	const [invoiceCountry, setInvoiceCountry] = useState('');
	const [invoiceCity, setInvoiceCity] = useState('');
	const [invoiceAdress, setInvoiceAdress] = useState('');
	const [invoiceRegNum, setInvoiceRegNum] = useState('');
	const [invoiceTax, setInvoiceTax] = useState('');
	const [paymentmethod, setPaymentmethod] = useState('');

	//ERRORS
	const [errors, setErrors] = useState({});

	const onSendData = async (e) => {

		try {
			setloading(true);
			// Prepare the data to be sent to the server

			const errors = validate();
			if (!(Object.keys(errors).length === 0)) {
				// Submit the form

				setloading(false);
				return;
			}

			let data = {
				personal: {
					title,
					name,
					surname,
					email,
					phone,
					country,
					city,
					postalCode,
					address: adress,
					fax: dietary,
				},
				accommodation:
					shouldAccommodate === "Yes"
						? {
							arrivalDate: arrivalDate ? moment(arrivalDate).format("DD/MM/YYYY"): undefined,
							departureDate: departureDate ? moment(departureDate).format("DD/MM/YYYY"): undefined,
						}
						: null,
				transfer:
					arrivalTransfer === "Yes"
						? {
							arrivalDateTransfer: arrivalDateTransfer ? moment(arrivalDateTransfer).format("DD/MM/YYYY HH:mm") : undefined,
							arrivalFlightTransfer,
							departureDateTransfer: departureDateTransfer ? moment(departureDateTransfer).format("DD/MM/YYYY HH:mm"): undefined,
							departureFlightTransfer
						}
						: null,
				invoice:
					paymentmethod === "Invoice"
						? {
							invoiceCompany,
							invoiceCountry,
							invoiceCity,
							invoiceAdress,
							invoiceRegNum,
							invoiceTax
						}
						: null,
				creditCard: paymentmethod === "Credit card"
			};

			//Upload file on server and get url location
			// Send the data to the server
			axios
				.post(IP_SERVER2 + "/api/sendAccomodationFormData", data)
				.then((response) => {


					const server_data = response.data;
					setloading(false);
					if (server_data?.success) {
						alert("Successfull Registration. Please check your email.");

						window.location.reload();
						//Load data 
					} else {
						alert("Something went wrong. Please try again.");
					}

					navigate("/");
				})
				.catch((error) => {
					setloading(false);
					alert("Something went wrong. Please try again.");

					// console.log(error);
					// Do something with the error, for example, show an error message
				})
				.finally(() => {
					setloading(false);

					// Reset the form's state
				});
		} catch (error) {

		}

	};

	const validate = () => {
		let errors = {};

		if (!title.trim()) {
			errors.title = "Title is required";
		}

		if (!name.trim()) {
			errors.name = "Name is required";
		}

		if (!surname.trim()) {
			errors.surname = "Surname is required";
		}

		if (!email.trim()) {
			errors.email = "Email is required";
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = "Email is invalid";
		}

		if (!phone.trim()) {
			errors.phone = "Phone number is required";
		}

		if (!country.trim()) {
			errors.country = "Country is required";
		}

		if (!city.trim()) {
			errors.city = "City is required";
		}

		if (!postalCode.trim()) {
			errors.postalCode = "Postal code is required";
		}

		if (!adress.trim()) {
			errors.adress = "Address is required";
		}

		if (paymentmethod !== "Credit card") {
			//INVOICE
			if (!invoiceCompany.trim()) {
				errors.invoiceCompany = "invoiceCompany is required";
			}

			if (!invoiceCountry.trim()) {
				errors.invoiceCountry = "invoiceCountry is required";
			}

			if (!invoiceCity.trim()) {
				errors.invoiceCity = "City is required";
			}

			if (!invoiceAdress.trim()) {
				errors.invoiceAdress = "Address is required";
			}

			if (!invoiceRegNum.trim()) {
				errors.invoiceRegNum = "Reg. No is required";
			}

			if (!invoiceTax.trim()) {
				errors.invoiceTax = "Tax is required";
			}
		}

		setErrors(errors);
		return errors;
	}

	const isErrorForm = useMemo(() => {
		if (!(Object.keys(errors).length === 0)) {
            return true;
        }

        return false;
    }, [errors])

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header isMain />
				{loading && (
					<div
						className="fixed top-0 left-0 h-full w-full flex justify-center items-center"
						style={{ zIndex: 999999, background: "#00174680" }}
					>
						<LoadingOverlay
							active={loading}
							spinner
						></LoadingOverlay>
					</div>
				)}
				<div
					className="h-96  relative w-full overflow-hidden"
					style={{ zIndex: 22, height: 400 }}
				>

					<div
						className="absolute top-0 left-0 w-full h-full"
						style={{
							backgroundColor: "#182c54a3",
							zIndex: 22,
							background: "linear-gradient(#001746, #123275)",
						}}
					/>
				</div>
				<div className="w-full relative z-40 -mt-60 pb-32">
					<div className="w-full md:w-3/5 m-auto text-white text-center pb-16">
						<h1 className="text-3xl">Accommodation and Transfer</h1>
					</div>
					<div className=" w-full lg:w-4/5 xl:w-4/5 2xl:w-4/6 pb-0 m-auto  rounded-lg shadow-lg  overflow-visible relative bg-white">
						<div className="absolute left-0 top-0 w-full justify-center flex">
							<div className="w-28 h-28 bg-white -mt-14 rounded-full border-gray-200 border flex justify-center items-center">
								<i
									className="material-icons likes-icon text-blue-500 text-3xl"
									style={{
										fontSize: 35,
										color: "#123275",
										cursor: "pointer",
									}}
								>
									apartment
								</i>
							</div>
						</div>
						<div className="p-0 sm:p-24 pt-32">
							<div className="pl-0">
								<h3>Personal Information</h3>
							</div>

							<PersonalInfo
								errors={errors}
								name={name}
								surname={surname}
								email={email}
								phone={phone}
								country={country}
								city={city}
								postalCode={postalCode}
								organisation={organisation}
								adress={adress}
								dietary={dietary}
								//invitationLetter={invitationLetter}
								/*           SET CALLBACKS                */
								setName={setName}
								setSurname={setSurname}
								setEmail={setEmail}
								setPhone={setPhone}
								onCountryChange={setCountry}
								setPostalCode={setPostalCode}
								setCity={setCity}
								setOrganisation={setOrganisation}
								setAdress={setAdress}
								setDietary={setDietary}
								//setInvitationLetter={setInvitationLetter}
								title={title}
								setTitle={setTitle}
							/>
							<div className="pl-0">
								<h3>Hotel reservation</h3>
							</div>
							<Accommodation
								enable={shouldAccommodate === "Yes"}
								shouldAccommodate={shouldAccommodate}
								arrivalDate={arrivalDate}
								departureDate={departureDate}
								//nights={nights}
								//roomDeposit={roomDeposit}
								//noteAccommodate={noteAccommodate}
								/*           SET CALLBACKS                */
								setShouldAccommodate={setShouldAccommodate}
								setArrivalDate={setArrivalDate}
								setDepartureDate={setDepartureDate}
							//setNights={setNights}
							//setRoomDeposit={setRoomDeposit}
							//setNodeAccommodate={setNodeAccommodate}
							/>
							<div className="pl-0">
								<h3>Transfer reservation</h3>
							</div>
							<Transfers
								arrivalTransfer={arrivalTransfer}
								//arrivalPersonsTransfer={arrivalPersonsTransfer}
								arrivalDateTransfer={arrivalDateTransfer}
								arrivalFlightTransfer={arrivalFlightTransfer}
								//arrivalFromTransfer={arrivalFromTransfer}
								//departureTransfer={departureTransfer}
								// departurePersonsTransfer={
								// 	departurePersonsTransfer
								// }
								departureDateTransfer={departureDateTransfer}
								departureFlightTransfer={
									departureFlightTransfer
								}
								//departureFromTransfer={departureFromTransfer}
								/*           SET CALLBACKS                */
								setArrivalTransfer={setArrivalTransfer}
								//setArrivalPersonsTransfer={
								//	setArrivalPersonsTransfer
								//}
								setArrivalDateTransfer={setArrivalDateTransfer}
								setArrivalFlightTransfer={
									setArrivalFlightTransfer
								}
								//setArrivalFromTransfer={setArrivalFromTransfer}
								//setDepartureTransfer={setDepartureTransfer}
								//setDeparturePersonsTransfer={
								//	setDeparturePersonsTransfer
								//}
								setDepartureDateTransfer={
									setDepartureDateTransfer
								}
								setDepartureFlightTransfer={
									setDepartureFlightTransfer
								}
							//setDepartureFromTransfer={
							//	setDepartureFromTransfer
							//}
							/>
							<div className="pl-0">
								<h3>Payment</h3>
							</div>
							<div class="w-full pt-2">
								<div className='w-full'>
									<label for="surname" class="block  text-sm font-medium text-gray-400 pr-3 pb-2">
										Choose payment method
									</label>
								</div>
								<div className='w-full pb-8 max-w-xs'>
									<DropDownList
										placeholder='Choose'
										options={[{ label: 'Invoice', value: 'Invoice' }, { label: 'Credit card', value: 'Credit card' }]}
										selectedValue={paymentmethod}
										onValueChange={setPaymentmethod}
									/>
								</div>
							</div>
							{paymentmethod === "Invoice" ? <Invoice
								invoiceIssue={invoiceIssue}
								setInvoiceIssue={setInvoiceIssue}
								invoiceCompany={invoiceCompany}
								setInvoiceCompany={setInvoiceCompany}
								invoiceCountry={invoiceCountry}
								setInvoiceCountry={setInvoiceCountry}
								invoiceCity={invoiceCity}
								setInvoiceCity={setInvoiceCity}
								invoiceAdress={invoiceAdress}
								setInvoiceAdress={setInvoiceAdress}
								invoiceRegNum={invoiceRegNum}
								setInvoiceRegNum={setInvoiceRegNum}
								invoiceTax={invoiceTax}
								setInvoiceTax={setInvoiceTax}
							/> : null}
							{paymentmethod === "Credit card" && <div className=" pl-4">
								<p className=" pt-5">
									For payment with credit card you should contact official Congress Agency: <a className="underline text-blue-600" href="mailto: bclf2023@congresstravel.me">bclf2023@congresstravel.me</a>.

								</p>
							</div>}
							<div className="pt-10">
								<div className="py-4 px-4 pt-2 w-full bg-[#932004d1] bg-gray-500 rounded-md">
									<div>
										<i
											className="material-icons likes-icon text-white text-3xl"
											style={{
												fontSize: 30,
												color: "#fff",
											}}
										>
											warning
										</i>
									</div>
									<div className="px-2 sm:px-10 pb-2">
										<span className="font-bold text-base text-white">
											<p className="font-normal">
												Cancellation for accommodation without penalty is possible 60 days prior to arrival.
												<br></br>Cancellation for transfer arrangements without penalty is possible if cancelled minimum 7 days prior to arrival.
												<br></br>In case of cancellation within the above stated period or in case of no-show all reservations will be fully charged.
												<br></br>	<br></br>The payment via invoice must be done until 1st September 2023.
												<br></br>The payment via credit card – all credit cards will be charged upon final confirmation of a reservation from your
												side.
												<br></br>Additional charge for credit card payment will be 2% bank fee.
												<br></br>Registration after successfully completing and sending this form.
											</p>
										</span>
									</div>

								</div>
							</div>

							<div className="w-full flex justify-center p-5">
								<h3 className="text-center ">
									After completing, please click SEND.
									Accommodation / transfer form will be
									automatically sent to email of the Congress
									Travel Agency, as well as your email.
								</h3>
							</div>
							<div className="w-full flex justify-center p-5">
								<button
									onClick={onSendData}
									className=" text-white font-bold bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300  rounded-xl text-base w-full sm:w-auto px-20 py-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
								>
									SEND
								</button>
							</div>
							<div className='w-full flex justify-center p-3'>
                                    {isErrorForm && <span className="error text-base">Please fill al required fields</span>}
                                </div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

const ErrorMessage = ({ name }) => (
	<Field name={name}>
		{({ field, form: { touched, errors } }) =>
			touched[name] && errors[name] ? (
				<div className="text-red-500 text-xs">{errors[name]}</div>
			) : null
		}
	</Field>
);
