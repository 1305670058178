import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import katic from "../assets/panorama.jpg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DropDownList from "../components/DropDownList";
import { Link, useNavigate } from "react-router-dom";

export const Contacts = () => {
	const navigate = useNavigate();

	const onSubmitWorkClick = () => {
		navigate("/SendAbstract");
	};

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header />
				<div className="w-full relativeh-screen mt-28 pb-20">
					<main>
						<div className="w-full sm:w-5/6 m-auto px-5 pt-20">
                            <div className="shadow-md p-10">
                                <div className="w-fit">
                                    <h1 className="text-2xl sm:text-4xl border-b-4 border-b-[#e78c31] pb-1">Contacts</h1>
                                </div>
                                <p className="pt-3">
                                <b>General information </b> -    <a className="underline text-blue-600" href="mailto: info@bclf2023.org">info@bclf2023.org</a> 
                                </p>
                                    <br></br>
                                <p>
                                    <b>Abstracts and posters information </b>-  <a className="underline text-blue-600" href="mailto: posters@bclf2023.org">posters@bclf2023.org</a> 
                                </p>
                                    <br></br>
                                <p>
                                <b>Registration information </b> -  <a className="underline text-blue-600" href="mailto: bclf2023@congresstravel.me">bclf2023@congresstravel.me</a>  and <a className="underline text-blue-600" href="mailto: info@bclf2023.org">info@bclf2023.org</a>
                                </p>
                                    <br></br>
                                <p>
                                <b>Accommodation and transfer information </b> - <a className="underline text-blue-600" href="mailto: bclf2023@congresstravel.me">bclf2023@congresstravel.me</a> 
                                </p>
                            </div>
							
						</div>
					</main>
				</div>
			</div>
			<Footer />
		</div>
	);
};
