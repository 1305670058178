import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header/Header'
import budva from '../assets/herceg.jpg';
import hn1 from '../assets/hn1.jpeg';
import bclf from '../assets/bclf3.svg';
import bscl from '../assets/macc.jpg';
import eflm from '../assets/eflm.png';
import ifcc from '../assets/ifcc.png';
import Footer from '../components/Footer/Footer';
import Speaker from '../components/Speaker';
import CountModal from '../components/Countdown/CountModal';

/**
 *                 <div className='h-96  relative w-full' style={{ background: 'linear-gradient(#001746, #133987)', zIndex: 22, height: 400}}>

 * @returns <div className='h-96  relative w-full overflow-hidden' style={{zIndex: 22, height: 400}}>
					<img className='absolute top-0 left-0 w-full h-full' src={budva} style={{ height: 700}} />
					<div className='absolute top-0 left-0 w-full h-full' style={{ backgroundColor: '#182c54a3', zIndex: 22, background: 'linear-gradient(#001746, #012160a3)'}} />
				</div>
 */


const speakers = [
	{
		name: "Prof. Gary W. Moore, BSc, DBMS, CSci, FIBMS, CBiol, FRSB, CertMHS",
		name1: "Prof. Gary W. Moore",
		date: "JUL. 20",
		time: "5:55 p.m. - 6:55 p.m.",
		text1: `Professor Gary Moore worked in diagnostic pathology for 38 years in the UK NHS, finishing
        as Head of the Diagnostic Haemostasis & Thrombosis Laboratories at St. Thomas’ Hospital in
        London, the largest of their kind in the UK, from 1997-2019. He then took up the post of
        Director of Clinical Diagnostic Research for Technoclone in Vienna, Austria, and in 2020 was
        appointed Honorary Consultant Biomedical Scientist for the Specialist Haemostasis Unit at
        Addenbrooke’s Hospital in the UK, and Visiting Professor for Middlesex University London
        in the UK. His main areas of scientific interest and research are antiphospholipid antibody
        detection, thrombophilia testing, and diagnostic applications of snake venoms. He received his
        Doctor of Biomedical Science degree in 2003 for a thesis on lupus anticoagulant detection. He
        has published 80 papers on laboratory haemostasis/thrombosis diagnostics, including
        guidelines and reviews, published two text books and other book chapters, and is a regular
        peer reviewer for several journals. He is also a regular presenter at national and international
        meetings, and has been consultant or advisory board member for various diagnostic and
        pharmaceutical companies. He is a member of the International Society on Thrombosis and
        Haemostasis and co-chair of their Scientific & Standardisation Committee sub-committee on
        plasma coagulation inhibitors.`,
		text2: ``,
		imgUrl: "https://www.w3schools.com/howto/img_avatar.png",
		"lastName": "Moore"

	}, {
		name: "Asst. Prof. Julieta Hristova / Жулиета Христова, MD, PhD",
		name1: "Asst. Prof. Julieta Hristova / Жулиета Христова",
		text1: `Dr. Hristova holds a PhD in Clinical Laboratory. She is an assistant professor at Medical
        University in Sofia and works at UMHAT Alexandrovska in Sofia as head of Therapeutic
        Drug Monitoring &amp; Clinical Pharmacology at Central Clinical Laboratory. She has a broad
        experience in bioanalytical methods and clinical proteomics after more than 15 years of work
        in the field with focus on protein biomarker analysis, protein separation techniques, and
        handling of large data sets. She has published numerous articles and papers in various
        scientific journals, most of them high ranked, and holds several distinctions for her
        contribution to medical science. Dr. Hristova is assigned a Principal Investigator in a variety
        of scientific research projects to supervise the performance of the analytical process,
        especially when determining novel little-known parameters. She is also a secretary of Balkan
        Clinical Laboratory Federation.`,
		"lastName": "Hristova"
	},
	{
		name: "Prof. Maurizio Ferrari, MD, PhD",
		name1: "Prof. Maurizio Ferrari",
		text1: `Dr. Ferrari is a Full Professor of Clinical Pathology, University Vita-Salute San Raffaele,
        Milan, Italy, and Chief Medical Officer (CMO) of Synlab Italy. He was a Scientific
        Coordinator of Clinical Research, IRCCS H San Raffaele, Milan (1996-1999), Chairman of
        Committee on Clinical Molecular Biology Curriculum of IFCC (2002-2007), member of the
        Education and Management Division of IFCC (2008-2011), Chairman of the Education and
        Management Division of IFCC (2012-2014), member of IFCC Task Force on
        Pharmacogenetics (2008-2014), advisor of CLSI Committee on Molecular Methods.`,
		text2: `Dr. Ferrari  is a Dean of Master Degree in Molecular and Cellular Medical Biotechnology
        (2008 at present) and President of the European Society of Predictive Medicine (2009 at
        present). He is a former IFCC President (2015-2017 and 2019-2020). He received the IFCC-
        Abbott Award for significant contributions in molecular diagnostics (2004), as well as
        honorary membership of the Hungarian Society of Laboratory Diagnostics (2014). He was
        also awarded an honorary Diploma of “Societas Medicorum Bohemorum J.E Purkyne“ (2015)
        and Jendrassik L. Award of Societas Diagnostica Laboratorialis Clinicalis Hungarica (2016)
        as well as SIBioC Award for Internalization (2019). Dr. Ferrari is author of more than 1000 publications: peer reviewed journals: 361 , book: 1, chapter's book: 45 and 610 abstracts at International and National Congress.`,
		"lastName": "Ferrari"
	},
];

const openingSpeaker = {
	name: "Prof. Tomris Ozben, MD, EuSpLM, Ph.D, D.Sc. Med. Lab. Specialist",
	name1: "Tomris Ozben",
	text1: `Dr Ozben is the President of the European Federation of Clinical Chemistry and Laboratory
        Medicine (EFLM) and President-Elect of the International Federation of Clinical Chemistry
        and Laboratory Medicine (EFLM). She is the BCLF Past-President and BCLF Executive
        Board member. She is the Chair of the EFLM Task Force-Green and Sustainable Laboratories
        (TF-GSL). She is one of the Board of Directors of the IFCC Foundation for Emerging Nations
        (FEN). She is the FORUM Consultant of the IFCC Task Force-Young Scientists. Over her
        tenure at Akdeniz University, Prof. Ozben has been the Vice Rector, General Director of the
        Central Laboratory of the University Hospital, and Chair of the Dept. of Medical
        Biochemistry, Ethical Committee member of Clinical studies. She is the supervisor at the
        Doctorate Program entitled “Clinical and Experimental Medicine” at the Medical Faculty,
        University of Modena and Reggio Emilia, Italy. She is an external examiner for the Post-
        graduate Master and Ph.D. education at the University of Zimbabwe, College of Health
        Sciences, Department of Chemical Pathology. She is the Honorary Member of the Slovak
        Society of Clinical Biochemistry (SSCB).`,
	text2: `She works in the identification of early diagnostic and prognostic markers in cancer, acute and
        chronic diseases, liquid profiling in cancer, validation of new tests, and laboratory
        management. She is the Editorial Board member of several Scientific Journals. She has
        published over 180 papers, edited several books, and been invited to speak at more than 200
        international conferences.`,
	"lastName": "Ozben"
}
//4b83f73b

export const Main = () => {
	
	return (
		<div className="home-root">
			<div className="home-main min-h-screen bg-white overflow-hidden">
				<Header isMain />
				
				<div className='h-cover-mobile h-screen   relative bg-white'   >
					<div className='absolute -top-0 left-0 w-full h-screen overflow-hidden z-0'>
						<img className='w-full z-0' src={budva} style={{ height: '100%' }} />{/*182c54a3 //'linear-gradient(#001746, #012160a3)'*/}
					</div>
					<div className='absolute top-0 left-0 w-full h-full' style={{ backgroundColor: '#182c54a3', zIndex: 1, background: 'linear-gradient(#001746, #01605200)' }} >
					</div>
					<div className='relative w-4/5 sm:w-3/4 m-auto h-full flex  items-center justify-center  z-10 '>
					
						<div className=' -mt-24 sm:mt-0 text-center'>
							<h1 className='text-white text-2xl lg:text-5xl   lg:leading-[70px]' style={{}}>
								XXX Meeting of the Balkan Clinical Laboratory Federation
								<br></br>
								BCLF 2023
							</h1>
							<h1 className='text-white text-xl lg:text-4xl   mt-10' style={{ color: '#ffffffbc' }}>
								II Montenegrin Conference of Clinical Chemistry and Laboratory Medicine
							</h1>
							<div className='sm:mt-10 pt-10 sm:pt-0 flex-col justify-center items-center'>
								<div className='flex  items-center justify-center relative'>
									<div className='pl-8 relative'>
										<div className='absolute left-0 top-4'>
											<i className="material-icons likes-icon text-yellow-400 text-base lg:text-2xl" style={{ paddingRight: 10, cursor: 'pointer' }}>event</i>
										</div>
										<h4 className='text-white font-medium text-base lg:text-2xl my-4'>
											SEPTEMBER 27TH - SEPTEMBER 30TH 2023
										</h4>
									</div>
								</div>
								<div className='flex  items-center justify-center relative'>
									<div className='relative pl-8'>
										<div className='absolute left-0 top-4'>
											<i className="material-icons likes-icon text-yellow-400 text-base lg:text-2xl" style={{ paddingRight: 10, cursor: 'pointer' }}>
												room
											</i>
										</div>
										<h4 className='text-white font-medium text-base lg:text-2xl my-4'>
											HERCEG NOVI, BOKA BAY, MONTENEGRO
										</h4>
									</div>
									{/* <div className="p-2 flex justify-center items-center w-full absolute -bottom-28 sm:-bottom-20 left-0">
										<div className='blink-bg p-5 rounded-lg text-white font-bold '>
											Registration will be open till 3rd September 2023!
										</div>
									</div> */}
								</div>
								
							</div>
						</div>
					</div>
					<div class=" custom-shape-divider-bottom-1673425483">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
							<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
						</svg>
					</div>
				</div>
				{/* <div className='flex justify-center items-center mt-16'>
					<CountModal />
				</div> */}
				<section className=' bg-[#fff] py-5 pt-10' style={{}}>
					<div className='w-full relative py-12 pt-5 m-auto' style={{ maxWidth: 2500 }}>
						<div className=' w-full flex justify-center items-center'>
							<div className='px-2'>
								<h2 className='text-[38px] sm:text-[45px] font-bold text-center'>BCLF2023 album of memories</h2>
							</div>
						</div>
						<div className='pt-8 flex flex-wrap justify-center items-center w-4/5 h-full m-auto gap-10'>
							<iframe className='w-full shadow-md  aspect-video ' src="https://www.youtube.com/embed/mX9_okk5SiQ?si=UyKTGOMKafsGVker" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
						</div>
					</div>
				</section>
				<section className=' bg-[#FFF] py-5' style={{}}>
					<div className='w-full relative py-12 m-auto' style={{ maxWidth: 2500 }}>
						<div className=' w-full flex justify-center items-center'>
							<div className=''>
								<h2 className='text-[38px] sm:text-[45px] font-bold'>Opening lecture</h2>
							</div>
						</div>
						<div className='pt-10 flex flex-wrap justify-center items-center w-4/5 h-full m-auto gap-10'>
							{/* {speakers.map((sp) => (
								<div className=' w-full p-5 md:w-1/2 lg:w-1/4 flex justify-center items-center'>
									<Speaker 
										{...sp}
									/>
								</div>
							))} */}
							<Link 
								to={{ pathname: "/Lecturer", ...openingSpeaker }} 
								state={{ ...openingSpeaker }} 
								params={{ ...openingSpeaker }} 
								className='w-full sm:w-96  bg-[#001746] rounded-2xl text-white '
							>
								<div className='w-full flex justify-center items-center pt-10'>
									<i className="material-icons text-4xl text-gray-50">person</i>
								</div>
								<div className='pt-5 flex justify-center items-center text-center mt-3 hover:underline'>
									<p className='text-white text-lg sm:text-xl font-bold'>
										Prof. Tomris Ozben
									</p>
								</div>
								<div className='p-4 flex justify-center items-center m-4 text-center'>
									<p className='text-white text-base '>
										Implementation of sustainable practices in medical laboratories. Switching the Clinical Laboratories to Green Labs
									</p>
								</div>
							</Link>
						</div>
					</div>
				</section>
				<section className=' bg-[#FFF] py-5 pt-0' style={{}}>
					<div className='w-full relative py-12 pt-5 m-auto' style={{ maxWidth: 2500 }}>
						<div className=' w-full flex justify-center items-center'>
							<div className=''>
								<h2 className='text-[38px] sm:text-[45px] font-bold'>Plenary lectures</h2>
							</div>
						</div>
						<div className='pt-10 flex flex-wrap justify-center items-center w-4/5 h-full m-auto gap-10'>
							{/* {speakers.map((sp) => (
								<div className=' w-full p-5 md:w-1/2 lg:w-1/4 flex justify-center items-center'>
									<Speaker 
										{...sp}
									/>
								</div>
							))} */}
							<Link to={{ pathname: "/Lecturer", ...speakers[0] }} state={{ ...speakers[0] }} params={{ ...speakers[0] }} className='w-72 h-72 bg-[#001746] rounded-full text-white '>
								<div className='w-full flex justify-center items-center pt-10'>
									<i className="material-icons text-4xl text-gray-50">person</i>
								</div>
								<div className='pt-5 flex justify-center items-center text-center mt-3 hover:underline'>
									<p className='text-white text-lg sm:text-xl font-bold'>
										Prof. Gary W. Moore
									</p>
								</div>
								<div className='p-4 flex justify-center items-center m-4 text-center'>
									<p className='text-white text-base '>
										Thrombophilia screening - not so straightforward
									</p>
								</div>
							</Link>
							<Link to={{ pathname: "/Lecturer", ...speakers[2] }} state={{ ...speakers[2] }} params={{ ...speakers[2] }} className='w-72 h-72 bg-[#001746] rounded-full text-white '>
								<div className='w-full flex justify-center items-center pt-10'>
									<i className="material-icons text-4xl text-gray-50">person</i>
								</div>
								<div className='pt-5 flex justify-center items-center text-center mt-3 hover:underline'>
									<p className='text-white text-lg sm:text-xl font-bold'>
										{speakers[2].name1}
									</p>
								</div>
								<div className='p-4 flex justify-center items-center m-4 text-center'>
									<p className='text-white text-base '>
										Big data and aging
									</p>
								</div>
							</Link>
							<Link to={{ pathname: "/Lecturer", ...speakers[1] }} state={{ ...speakers[1] }} params={{ ...speakers[1] }} className='w-72 h-72 bg-[#001746] rounded-full text-white '>
								<div className='w-full flex justify-center items-center pt-10'>
									<i className="material-icons text-4xl text-gray-50">person</i>
								</div>
								<div className='pt-5 flex justify-center items-center text-center mt-3 hover:underline'>
									<p className='text-white text-lg sm:text-xl font-bold'>
										Asst. Prof. Julieta Hristova
									</p>
								</div>
								<div className='p-4 flex justify-center items-center m-4 text-center'>
									<p className='text-white text-base '>

										Mass spectrometry-based omics
									</p>
								</div>
							</Link>
						</div>
						<div className='w-full flex justify-center items-center mt-16'>
							<Link to={{ pathname: "/Lecturers" }} style={{ borderColor: '#092863' }} className='cursor-pointer hover:bg-blue-50 border border-blue-800 px-8 h-16 rounded-full flex justify-center items-center'>
								<span style={{ color: '#092863' }} className='text-blue-800 text-xl font-bold uppercase'>
									VIEW ALL Lecturers
								</span>
							</Link>
						</div>
					</div>
				</section>
				
				<section className='hidden bg-[#FFF] py-5' style={{}}>
					<div className='w-full relative py-12 m-auto' style={{ maxWidth: 2500 }}>
						<div className=' w-full flex justify-center items-center'>
							<div className=''>
								<h2 className='text-4xl font-bold'>Lecturer</h2>
							</div>
						</div>
						<div className='pt-10 flex flex-wrap justify-center items-center w-4/5 h-full m-auto'>
							{speakers.map((sp) => (
								<div className=' w-full p-5 md:w-1/2 lg:w-1/4 flex justify-center items-center'>
									<Speaker
										{...sp}
									/>
								</div>
							))}
						</div>
						<div className='w-full flex justify-center items-center mt-16'>
							<Link to={{ pathname: "/Lecturers" }} style={{ borderColor: '#092863' }} className='cursor-pointer hover:bg-blue-50 border border-blue-800 px-8 h-16 rounded-full flex justify-center items-center'>
								<span style={{ color: '#092863' }} className='text-blue-800 text-xl font-bold'>
									VIEW ALL SPEAKERS
								</span>
							</Link>
						</div>
					</div>
				</section>
				
				<section className='pb-32 bg-[#eff6ff] hidden'>
					<div className='w-full lg:w-3/4 m-auto pt-5 p-10 sm:p-0 sm:pt-32 relative'>
						<div className='flex justify-center items-center flex-wrap pt-10 p-5 sm:p-0'>
							<div className='w-full p-2 md:w-1/2 xl:w-1/4 '>
								<Link to="/AbstractSubmission" className="bg-[#31436ead] p-5 md:p-14 w-full h-44 sm:h-48 flex justify-center items-center cursor-pointer  hover:underline text-white">
									<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit text-white text-xl">
										Poster presentations
									</h1>
								</Link>
							</div>
							{/* <div className='w-full p-2 md:w-1/2 xl:w-1/4 '>
								<Link to="/Registration" className="bg-[#31436ead] p-5 md:p-14 w-full h-44 sm:h-48 flex justify-center items-center cursor-pointer  hover:underline text-white">
									<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit text-white text-xl">
										Registration Form
									</h1>
								</Link>
							</div> */}
							<div className='w-full p-2 md:w-1/2 xl:w-1/4'>
								<Link to="/MainTopics" className="bg-[#31436ead] p-5 md:p-14 w-full h-44 sm:h-48 flex justify-center items-center cursor-pointer  hover:underline text-white">
									<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit text-white text-xl">
										Main Topics
										<br></br>{" "}
									</h1>
								</Link>
							</div>
							<div className='w-full p-2 md:w-1/2 xl:w-1/4 '>
								<Link to="/ScientificProgram" className="bg-[#31436ead] p-5 md:p-14 w-full h-44 sm:h-48 flex justify-center items-center cursor-pointer  hover:underline text-white">
									<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit text-white text-xl">
										Scientific Program
									</h1>
								</Link>
							</div>

						</div>

					</div>
				</section>
				{/* {<section className='p-5 sm:p-20 bg-[#eff6ff] '>
					<div className='w-full  lg:w-3/4 m-auto  rel'>
						<div className='flex justify-center items-center flex-wrap'>
							<div className='w-full p-3 md:w-1/2 xl:w-1/4'>
								<div className='bg-white  rounded-2xl shadow-md p-8 pl-5 flex items-center justify-start'>
									<div className='flex items-center justify-start'>
										<div>
											<div className='circle1  bg-blue-300  w-16 h-16 flex items-center justify-center rounded-2xl ' style={{ backgroundColor: "#092863"}}>
												<div className=' rounded-full bg-blue-300 w-10 h-10 flex items-center justify-center' style={{backgroundColor: "#092863"}}>
													<i className="material-icons likes-icon text-blue-500 text-xl"  style={{ color: '#fff' ,cursor: 'pointer'}}>book</i>
												</div>
											</div>
										</div>
										<div className='pl-5 w-2/3'>
											<Link to="/AbstractSubmission" className='btn-text text-base font-bold text-blue-900 cursor-pointer hover:underline'>
												Abstract Submission
											</Link>
										</div>
									</div>
									
								</div>
							</div>
							<div className='w-full p-3 md:w-1/2 xl:w-1/4'>
								<div className='bg-white  rounded-2xl shadow-md p-8 pl-5 flex items-center justify-start'>
									<div className='flex items-center justify-start'>
										<div>
											<div className='circle1  bg-blue-300  w-16 h-16 flex items-center justify-center rounded-2xl ' style={{ backgroundColor: "#092863"}}>
												<div className=' rounded-full bg-blue-300 w-10 h-10 flex items-center justify-center' style={{backgroundColor: "#092863"}}>
													<i className="material-icons likes-icon text-blue-500 text-xl"  style={{ color: '#fff' ,cursor: 'pointer'}}>how_to_reg</i>
												</div>
											</div>
										</div>
										<div className='pl-5 w-2/3'>
											<Link to="/Registration" className='btn-text text-base font-bold text-blue-900 cursor-pointer hover:underline'>
												Registration Form
											</Link>
										</div>
									</div>
									
								</div>
							</div>
							<div className='w-full p-3 md:w-1/2 xl:w-1/4'>
								<div className='bg-white  rounded-2xl shadow-md p-8 pl-5 flex items-center justify-start'>
									<div className='flex items-center justify-start'>
										<div>
											<div className='circle1  bg-blue-300  w-16 h-16 flex items-center justify-center rounded-2xl ' style={{ backgroundColor: "#092863"}}>
												<div className=' rounded-full bg-blue-300 w-10 h-10 flex items-center justify-center' style={{backgroundColor: "#092863"}}>
													<i className="material-icons likes-icon text-blue-500 text-xl"  style={{ color: '#fff' ,cursor: 'pointer'}}>article</i>
												</div>
											</div>
										</div>
										<div className='pl-5 w-2/3'>
											<Link to="/MainTopics" className='btn-text text-base font-bold text-blue-900 cursor-pointer hover:underline'>Main Topics</Link>
										</div>
									</div>
									
								</div>
							</div>
							<div className='w-full p-3 md:w-1/2 xl:w-1/4'>
								<div className='bg-white  rounded-2xl shadow-md p-8 pl-5 flex items-center justify-start'>
									<div className='flex items-center justify-start'>
										<div>
											<div className='circle1  bg-blue-300  w-16 h-16 flex items-center justify-center rounded-2xl ' style={{ backgroundColor: "#092863"}}>
												<div className=' rounded-full bg-blue-300 w-10 h-10 flex items-center justify-center' style={{backgroundColor: "#092863"}}>
													<i className="material-icons likes-icon text-blue-500 text-xl"  style={{ color: '#fff' ,cursor: 'pointer'}}>featured_play_list</i>
												</div>
											</div>
										</div>
										<div className='pl-5 w-2/3'>
											<Link to="/ScientificProgram" className='btn-text text-base font-bold text-blue-900 cursor-pointer hover:underline'>
												Scientific program
											</Link>
										</div>
									</div>
									
								</div>
							</div>
							
						</div>
						
					</div>
				</section>} */}
				<section className='p-16 sm:p-20 bg-[#eff6ff] '>
					<div className='w-full  lg:w-3/4 m-auto  relative' style={{ maxWidth: 1250 }}>
						<div className='flex justify-center items-center flex-wrap'>
							<div className='w-full p-3 md:w-1/2 xl:w-1/4'>
								<div className='bg-white  rounded-2xl  p-6 pb-16 relative flex items-center justify-center'>
									<div className='flex flex-col  items-center justify-center z-50'>
										<div>
											<div className='circle1  bg-blue-300  w-16 h-16 flex items-center justify-center rounded-2xl ' style={{ backgroundColor: "#092863" }}>
												<div className=' rounded-full bg-blue-300 w-10 h-10 flex items-center justify-center' style={{ backgroundColor: "#092863" }}>
													<i className="material-icons likes-icon text-blue-500 text-xl" style={{ color: '#fff', cursor: 'pointer' }}>book</i>
												</div>
											</div>
										</div>
										<div className=' w-full pt-5 text-center'>
											<Link to="/AbstractSubmission" className='btn-text text-base font-bold text-blue-900 cursor-pointer hover:underline'>
												Poster presentations
											</Link>
										</div>
									</div>
									<div className='absolute bottom-0 left-0 w-full  overflow-hidden bg-[#d3d8e2]'>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
											<path fill="#fff" fill-opacity="1" d="M0,224L34.3,234.7C68.6,245,137,267,206,240C274.3,213,343,139,411,96C480,53,549,43,617,80C685.7,117,754,203,823,202.7C891.4,203,960,117,1029,117.3C1097.1,117,1166,203,1234,229.3C1302.9,256,1371,224,1406,208L1440,192L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<div className='w-full p-3 md:w-1/2 xl:w-1/4'>
								<div className='bg-white  rounded-2xl shadow-md p-6  pb-16 relative flex items-center justify-center'>
									<div className='flex flex-col items-center justify-center z-50'>
										<div>
											<div className='circle1  bg-blue-300  w-16 h-16 flex items-center justify-center rounded-2xl ' style={{ backgroundColor: "#092863" }}>
												<div className=' rounded-full bg-blue-300 w-10 h-10 flex items-center justify-center' style={{ backgroundColor: "#092863" }}>
													<i className="material-icons likes-icon text-blue-500 text-xl" style={{ color: '#fff', cursor: 'pointer' }}>how_to_reg</i>
												</div>
											</div>
										</div>
										<div className=' w-full pt-5 text-center'>
											<Link to="/Registration" className='btn-text text-base font-bold text-blue-900 cursor-pointer hover:underline'>
												Info for participants
											</Link>
										</div>
									</div>
									<div className='absolute bottom-0 left-0 w-full  overflow-hidden bg-[#d3d8e2]'>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
											<path fill="#fff" fill-opacity="1" d="M0,224L34.3,234.7C68.6,245,137,267,206,240C274.3,213,343,139,411,96C480,53,549,43,617,80C685.7,117,754,203,823,202.7C891.4,203,960,117,1029,117.3C1097.1,117,1166,203,1234,229.3C1302.9,256,1371,224,1406,208L1440,192L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<div className='w-full p-3 md:w-1/2 xl:w-1/4'>
								<div className='bg-white  rounded-2xl  p-6 pb-16 relative flex items-center justify-center'>
									<div className='flex flex-col items-center justify-center z-50'>
										<div>
											<div className='circle1  bg-blue-300  w-16 h-16 flex items-center justify-center rounded-2xl ' style={{ backgroundColor: "#092863" }}>
												<div className=' rounded-full bg-blue-300 w-10 h-10 flex items-center justify-center' style={{ backgroundColor: "#092863" }}>
													<i className="material-icons likes-icon text-blue-500 text-xl" style={{ color: '#fff', cursor: 'pointer' }}>article</i>
												</div>
											</div>
										</div>
										<div className=' w-full pt-5 text-center'>
											<Link to="/MainTopics" className='btn-text text-base font-bold text-blue-900 cursor-pointer hover:underline'>Main Topics</Link>

										</div>
									</div>
									<div className='absolute bottom-0 left-0 w-full  overflow-hidden bg-[#d3d8e2]'>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
											<path fill="#fff" fill-opacity="1" d="M0,224L34.3,234.7C68.6,245,137,267,206,240C274.3,213,343,139,411,96C480,53,549,43,617,80C685.7,117,754,203,823,202.7C891.4,203,960,117,1029,117.3C1097.1,117,1166,203,1234,229.3C1302.9,256,1371,224,1406,208L1440,192L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<div className='w-full p-3 md:w-1/2 xl:w-1/4'>
								<div className='bg-white  rounded-2xl  p-6 pb-16 relative flex items-center justify-center'>
									<div className='flex flex-col items-center justify-center z-50'>
										<div>
											<div className='circle1  bg-blue-300  w-16 h-16 flex items-center justify-center rounded-2xl ' style={{ backgroundColor: "#092863" }}>
												<div className=' rounded-full bg-blue-300 w-10 h-10 flex items-center justify-center' style={{ backgroundColor: "#092863" }}>
													<i className="material-icons likes-icon text-blue-500 text-xl" style={{ color: '#fff', cursor: 'pointer' }}>featured_play_list</i>
												</div>
											</div>
										</div>
										<div className=' w-full pt-5 text-center'>
											<Link to="/ScientificProgram" className='btn-text text-base font-bold text-blue-900 cursor-pointer hover:underline'>
												Scientific program
											</Link>
										</div>
									</div>
									<div className='absolute bottom-0 left-0 w-full  overflow-hidden bg-[#d3d8e2]'>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
											<path fill="#fff" fill-opacity="1" d="M0,224L34.3,234.7C68.6,245,137,267,206,240C274.3,213,343,139,411,96C480,53,549,43,617,80C685.7,117,754,203,823,202.7C891.4,203,960,117,1029,117.3C1097.1,117,1166,203,1234,229.3C1302.9,256,1371,224,1406,208L1440,192L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='hidden w-full bg-purple-100' style={{ backgroundColor: '#fbfbfb' }}>
					<div className='w-full px-2 lg:px-24 m-auto flex flex-wrap text-black '>
						<div className='w-full p-10 lg:w-1/2 flex items-center  flex-col'>
							<div className='text-center pb-10  lg:pl-16 animate animate-fade-in-up'>
								<h1> Announcements </h1>
							</div>
							<div className="relative overflow-hidden h-full w-full ilu-box animate animate-fade-in-up  rounded-full flex justify-center items-center bg-gray-100 rounded-t-full rounded-br-2xl h-ilu-mobile lg:h-ilu-desk" style={{ backgroundColor: "#518192" }}>
								<img src={hn1} className="w-full h-full" />
								<div className='absolute top-0 left-0 w-full h-full' style={{ backgroundColor: '#182c54a3', zIndex: 22, background: 'linear-gradient(#504a4a69, #01605200)' }} />
							</div>
						</div>
						<div className=' w-full lg:w-1/2 pt-10 flex items-center'>
							<div className='text-center pb-10  '>
								<div className='mb-20 animate animate-fade-in-up font-semibold pb-3 text-2xl '>We welcome you to Montenegro and hope you enjoy a wonderful and useful stay here during the conference!</div>
								<div className=''>
									<div className='text-2xl text-[#518192] font-semibold text-2xl' style={{ color: '#d67616' }}>
										Official Opening Ceremony
									</div>
									<div className='font-bold text-[#518192] text-xl' style={{ color: '#d67616' }}>20th July 2023</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='hidden jelenas-section py-5 px-5 pb-20 w-full bg-white' style={{ backgroundColor: '#fbfbfb' }}>
					<div className='relative'>
						<div className='text-center flex justify-center space-x-12 mb-5'>
							<div className='border date-card1 border-[#e79748]  bg-[#e79748] rounded p-5 w-52 text-white'>
								<p className='text-xl font-semibold'>20th</p>
								<p className='mt-5'>July</p>
								<p>2023</p>
							</div>
							<div className='min-w-465'>
								<h3>Late abstract submission opening:</h3>
								<p>OPEN</p>
							</div>
							{/*
							<div className='flex items-center'>
								<p className='border border-orange-200 rounded p-1'>Learn more</p>
							</div>
							**/}
						</div>
						<div className='text-center flex justify-center space-x-12 mb-5'>
							<div className='border date-card border-[#092863] bg-[#092863] rounded p-5 w-52 text-white'>
								<p className='text-xl font-semibold'>20th</p>
								<p className='mt-5'>July</p>
								<p>2023</p>
							</div>
							<div className=' min-w-465'>
								<h3>Late abstract submission deadline:</h3>
								<p>20th July 2023</p>
							</div>
						</div>
						<div className='text-center flex justify-center space-x-12 mb-5'>
							<div className='border border-[#e79748]  bg-[#e79748] rounded p-5 w-52 text-white'>
								<p className='text-xl font-semibold'>20th</p>
								<p className='mt-5'>July</p>
								<p>2023</p>
							</div>
							<div className=' min-w-465'>
								<h3>Late abstract submission deadline:</h3>
								<p>20th July 2023</p>
							</div>
						</div>
					</div>
				</section>

				<section className='sponsors bg-[#518192]' style={{ backgroundColor: '#003A70' }}>
					<div className='w-full relative  z-40 py-10'>
						<div className=' flex flex-wrap justify-center items-center w-5/6 h-full m-auto'>
							<div className='w-full p-5 md:w-1/5 flex flex-wrap justify-center items-center'>
								<span className='text-gray-500'>Under the auspices of</span>
								<img src={ifcc} className="" />
							</div>
							<div className='w-full p-5 md:w-1/5 flex flex-wrap justify-center items-center'>
								<span className='text-gray-500'>Under the auspices of</span>
								<img src={eflm} className="" />
							</div>
							<div className='relative w-full p-5 md:w-1/4 flex flex-wrap justify-center items-center'>
								<img src={bclf} className=" w-32 h-32" />

							</div>
							<div className='w-full p-5 md:w-1/5 flex flex-wrap justify-center items-center'>
								<img src={bscl} className=" w-48 h-32" />
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</div>
	)
}
