const topics = [
    { label: 'Accreditation', value: 'Accreditation' },
    { label: 'Allergology', value: 'Allergology' },
    { label: 'Autoimmune Disease', value: 'Autoimmune Disease' },
    { label: 'Biomarkers in Psychiatric Disorders', value: 'Biomarkers in Psychiatric Disorders' },
    { label: 'Bone and Mineral metabolism', value: 'Bone and Mineral metabolism' },
    { label: 'Cardiovascular Diseases and Cardiovascular Diseases risk prediction', value: 'Cardiovascular Diseases and Cardiovascular Diseases risk prediction' },
    { label: 'Cerebrospinal fluid analysis', value: 'Cerebrospinal fluid analysis' },
    { label: 'COVID-19', value: 'COVID-19' },
    { label: 'Dementia and Neurological diseases', value: 'Dementia and Neurological diseases' },
    { label: 'Diabetes, Adiposity, Metabolic Syndrome', value: 'Diabetes, Adiposity, Metabolic Syndrome' },
    { label: 'Dyslipidemia and Atherosclerosis', value: 'Dyslipidemia and Atherosclerosis' },
    { label: 'Endocrinology', value: 'Endocrinology' },
    { label: 'Gastrointestinal diseases', value: 'Gastrointestinal diseases' },
    { label: 'Hemostasis and Thrombosis', value: 'Hemostasis and Thrombosis' },
    { label: 'Immunodeficiencies', value: 'Immunodeficiencies' },
    { label: 'Inflammation and Oxidative stress', value: 'Inflammation and Oxidative stress' },
    { label: 'Interferences in laboratory testing', value: 'Interferences in laboratory testing' },
    { label: 'Kidney diseases and Transplantation', value: 'Kidney diseases and Transplantation' },
    { label: 'Laboratory Analytical Techniques', value: 'Laboratory Analytical Techniques' },
    { label: 'Laboratory Hematology', value: 'Laboratory Hematology' },
    { label: 'Laboratory Information Systems', value: 'Laboratory Information Systems' },
    { label: 'Laboratory Management', value: 'Laboratory Management' },
    { label: 'Laboratory medicine in transgender patients healthcare', value: 'Laboratory medicine in transgender patients healthcare' },
    { label: 'Molecular diagnostics', value: 'Molecular diagnostics' },
    { label: 'Nutrition and Nutrigenomics', value: 'Nutrition and Nutrigenomics' },
    { label: 'Pleural, peritoneal, pericardial fluid analysis', value: 'Pleural, peritoneal, pericardial fluid analysis' },
    { label: 'Postanalytical phase of Total Testing Process', value: 'Postanalytical phase of Total Testing Process' },
    { label: 'Preanalytical phase of Total Testing Process', value: 'Preanalytical phase of Total Testing Process' },
    { label: 'Precision Medicine', value: 'Precision Medicine' },
    { label: 'Prenatal and Neonatal testing', value: 'Prenatal and Neonatal testing' },
    { label: 'Quality Control of the Clinical laboratory', value: 'Quality Control of the Clinical laboratory'},
    { label: 'Sepsis', value: 'Sepsis' },
    { label: 'Smart technology labs', value: 'Smart technology labs' },
    { label: 'Synovial fluid analysis', value: 'Synovial fluid analysis' },
    { label: 'Tumor markers', value: 'Tumor markers' },
    { label: 'Urin analysis - qualitative and quantitative', value: 'Urin analysis - qualitative and quantitative' },
    { label: 'Other topic', value: 'Other topic' }
];

function capitaliseFirstWord(name) {
    let words = name.split(" ");
    let capitalisedWords = words.map((word, index) => {
        if(index === 0) return word;

        return word.charAt(0).toLowerCase() + word.slice(1);
    });
    return capitalisedWords.join(" ");
}

const topicsOnlyFirstWordUpperCase = topics.map(({ label, value}) => ({
    label: capitaliseFirstWord(label),
    value: capitaliseFirstWord(label),
}));


export default topicsOnlyFirstWordUpperCase;