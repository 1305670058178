import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import MultiStep from '../components/MultiStep';
import budva from '../assets/budva.jpg';
import PersonalInfo from '../components/StepForms/PersonalInfo';
import RegistrationForm from '../components/StepForms/RegistrationForm';
import GeneralInfo from '../components/StepForms/GeneralInfo';
import Accommodation from '../components/StepForms/Accommodation';
import Transfers from '../components/StepForms/Transfers/Transfers';
import Invoice from '../components/StepForms/Invoice';
import Submit from '../components/StepForms/Submit';
import { constants } from '../_constants/constants';
import validator from 'validator';
import katic from '../assets/herceg.jpg';
import DropDownList from '../components/DropDownList';
import { useNavigate } from 'react-router-dom';

export const Registration = () => {
    const [loading, setloading] = useState(false);
    const [completedSteps, setCompletedSteps] = useState([false, false, false, false, false, false]);
    const [selectedStep, setSelectedStep] = useState(0);
    // PERSONAL INFORMATION
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [adress, setAdress] = useState('');
    const [dietary, setDietary] = useState('');
    const [invitationLetter, setInvitationLetter] = useState(false);
    //REGISTRATION
    const [registrationPlan, setRegistrationPlane] = useState(null);
    //ACCOMMODATION
    const [shouldAccommodate, setShouldAccommodate] = useState('No');
    const [arrivalDate, setArrivalDate] = useState(new Date());
    const [departureDate, setDepartureDate] = useState(new Date());
    const [nights, setNights] = useState('');
    const [roomDeposit, setRoomDeposit] = useState('');
    const [noteAccommodate, setNodeAccommodate] = useState('');
    //TRANSFER
    const [arrivalTransfer, setArrivalTransfer] = useState('No');
    const [arrivalPersonsTransfer, setArrivalPersonsTransfer] = useState(1);
    const [arrivalDateTransfer, setArrivalDateTransfer] = useState(new Date());
    const [arrivalFlightTransfer, setArrivalFlightTransfer] = useState('');
    const [arrivalFromTransfer, setArrivalFromTransfer] = useState('');
    const [departureTransfer, setDepartureTransfer] = useState('No');
    const [departurePersonsTransfer, setDeparturePersonsTransfer] = useState(1);
    const [departureDateTransfer, setDepartureDateTransfer] = useState(new Date());
    const [departureFlightTransfer, setDepartureFlightTransfer] = useState('');
    const [departureFromTransfer, setDepartureFromTransfer] = useState('');
    //INVOICE
    const [invoiceIssue, setInvoiceIssue] = useState('No');
    const [invoiceCompany, setInvoiceCompany] = useState('');
    const [invoiceCountry, setInvoiceCountry] = useState('');
    const [invoiceCity, setInvoiceCity] = useState('');
    const [invoiceAdress, setInvoiceAdress] = useState('');
    const [invoiceRegNum, setInvoiceRegNum] = useState('');
    const [invoiceTax, setInvoiceTax] = useState('');
    const [fieldValue, setFieldValue] = useState(false);
    const [togetherDinner, setTogetherDinner] = useState(false);
    const [paymentmethod, setPaymentmethod] = useState('');

    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        window.scrollTo(0, 0);
    }, [selectedStep])


    useEffect(() => {
        validatePersonalInfo();
    }, [name, surname, email, phone, country, city, postalCode, organisation, adress, dietary]);

    useEffect(() => {
        validatePersonalInfo();
    }, [registrationPlan]);

    useEffect(() => {
        validateAccomodation();
    }, [shouldAccommodate, arrivalDate, departureDate, nights, roomDeposit, noteAccommodate]);

    useEffect(() => {
        validateTransfer();
    }, [arrivalTransfer, arrivalPersonsTransfer, arrivalDateTransfer, arrivalFlightTransfer, arrivalFromTransfer, departureTransfer, departurePersonsTransfer, departureDateTransfer, departureFlightTransfer, departureFromTransfer]);

    useEffect(() => {
        validateInvoice();
    }, [invoiceIssue, invoiceCompany, invoiceCountry, invoiceCity, invoiceAdress, invoiceRegNum, invoiceTax]);

    useEffect(() => {
        //loadFormDataLocaly();
    }, []);

    const loadFormDataLocaly = async () => {
        const local_data = localStorage.getItem(constants.USER_TOKEN);

        if (!local_data) {
            saveFormDataLocaly();
        }
        else {
            const form_data = await JSON.parse(local_data);

            if (form_data) {
                setCompletedSteps(form_data.completedSteps);
                //SET STATES
            }
        }
    }

    const saveFormDataLocaly = async () => {
        const form_data = {
            completedSteps,
            name, surname, email, phone, country, city, postalCode, organisation, adress, dietary, invitationLetter,
            registrationPlan,
            shouldAccommodate, arrivalDate, departureDate, nights, roomDeposit, noteAccommodate,
            arrivalTransfer, arrivalPersonsTransfer, arrivalDateTransfer, arrivalFlightTransfer, arrivalFromTransfer, departureTransfer, departurePersonsTransfer, departureDateTransfer, departureFlightTransfer, departureFromTransfer,
            invoiceIssue, invoiceCompany, invoiceCountry, invoiceCity, invoiceAdress, invoiceRegNum, invoiceTax,
        }

        localStorage.setItem(constants.USER_TOKEN, JSON.stringify(form_data));
    }

    const validatePersonalInfo = () => {
        let isValidate = true;

        if (!name) isValidate = false;
        if (!surname) isValidate = false;
        if (!validator.isEmail(email)) isValidate = false;
        if (!phone) isValidate = false;
        if (!country) isValidate = false;
        if (!city) isValidate = false;
        if (!postalCode) isValidate = false;
        if (!organisation) isValidate = false;
        if (!adress) isValidate = false;
        if (!dietary) isValidate = false;

        if (completedSteps[1] !== isValidate) {
            setCompletedSteps(
                completedSteps.map((c, i) => (i === 1 ? !c : c))
            )
        }
    }

    const validateRegistration = () => {
        let isValidate = true;

        if (!registrationPlan) isValidate = false;

        if (completedSteps[2] !== isValidate) {
            setCompletedSteps(
                completedSteps.map((c, i) => (i === 2 ? !c : c))
            )
        }
    }

    const validateAccomodation = () => {
        let isValidate = true;

        if (!shouldAccommodate) isValidate = false;
        if (!arrivalDate) isValidate = false;
        if (!departureDate) isValidate = false;
        if (!nights) isValidate = false;
        if (!roomDeposit) isValidate = false;
        if (!noteAccommodate) isValidate = false;

        if (completedSteps[3] !== isValidate) {
            setCompletedSteps(
                completedSteps.map((c, i) => (i === 3 ? !c : c))
            )
        }
    }

    const validateTransfer = () => {
        let isValidate = true;

        if (!arrivalTransfer) isValidate = false;
        if (!arrivalPersonsTransfer) isValidate = false;
        if (!arrivalDateTransfer) isValidate = false;
        if (!arrivalFlightTransfer) isValidate = false;
        if (!arrivalFromTransfer) isValidate = false;
        if (!departureTransfer) isValidate = false;
        if (!departurePersonsTransfer) isValidate = false;
        if (!departureFlightTransfer) isValidate = false;
        if (!departureFromTransfer) isValidate = false;


        if (completedSteps[4] !== isValidate) {
            setCompletedSteps(
                completedSteps.map((c, i) => (i === 4 ? !c : c))
            )
        }
    }

    const validateInvoice = () => {
        let isValidate = true;

        if (!invoiceIssue) isValidate = false;
        if (!invoiceCompany) isValidate = false;
        if (!invoiceCountry) isValidate = false;
        if (!invoiceCity) isValidate = false;
        if (!invoiceAdress) isValidate = false;
        if (!invoiceRegNum) isValidate = false;
        if (!invoiceTax) isValidate = false;
        if (completedSteps[5] !== isValidate) {
            setCompletedSteps(
                completedSteps.map((c, i) => (i === 5 ? !c : c))
            )
        }
    }

    const onSubmit = async () => {
        await setloading(true);

        const form_data = {
            completedSteps,
            name, surname, email, phone, country, city, postalCode, organisation, adress, dietary, invitationLetter,
            registrationPlan,
            shouldAccommodate, arrivalDate, departureDate, nights, roomDeposit, noteAccommodate,
            arrivalTransfer, arrivalPersonsTransfer, arrivalDateTransfer, arrivalFlightTransfer, arrivalFromTransfer, departureTransfer, departurePersonsTransfer, departureDateTransfer, departureFlightTransfer, departureFromTransfer,
            invoiceIssue, invoiceCompany, invoiceCountry, invoiceCity, invoiceAdress, invoiceRegNum, invoiceTax,
        }

        axios.post('/api/register', form_data)
            .then((res) => {
                setloading(false);

                const server_data = res.data;
                if (server_data?.success) {
                    console.log({
                        success: server_data.msg
                    });
                    //Load data 
                } else {
                    console.log({
                        error: server_data?.msg || 'fatal error'
                    });
                }
            }, (error) => {
                setloading(false);
                console.log({ error });
            });
    }


    const onCountryChange = (value) => {
        setCountry(value);
    }


    return (
        <div className="home-root">
            <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
                <Header isMain />
                <div className='h-96  relative w-full overflow-hidden' style={{ zIndex: 22, height: 400 }}>
                    <img className='absolute top-0 left-0 w-full h-full' src={katic} style={{ height: 450 }} /> //'linear-gradient(#001746, #012160a3)'
                    <div className='absolute top-0 left-0 w-full h-full' style={{ backgroundColor: '#182c54a3', zIndex: 22, background: 'linear-gradient(#001746, #123275)' }} />
                </div>
                <div className='w-full relative z-40 -mt-60 pb-52'>
                    <div className='w-full md:w-3/5 m-auto text-white text-center pb-10'>
                        <h1> Info for participants </h1>
                        <h3 className='text-gray-200'>
                            All delegates must register for the congress
                        </h3>
                    </div>
                    <div className='w-full lg:w-4/5 xl:w-4/5 2xl:w-4/6  pb-10 m-auto  rounded-lg shadow-lg  overflow-visible relative bg-white'>
                        <div className='w-5/6 m-auto py-10'>

                            <div className=''>
                                <div>
                                    <h2>
                                        Registration desk
                                    </h2>
                                    <p>
                                        Registration will start on Wednesday, 27th September, at 11h.
                                    </p>
                                    <h2>
                                        Name badge
                                    </h2>
                                    <p>
                                        All participants will receive a name badge when they check-in at the registration desk. Name badge will
                                        contain name, surname and country of origin of the participant, as well as QR code for attendance
                                        monitoring, User ID and PIN code for loging into Smart event congress application.
                                    </p>
                                    <h2>
                                        Smart event congress application
                                    </h2>
                                    <p>
                                        BCLF2023 participants have to download Smart event congress application. Application will be used for
                                        attendance monitoring as well as for completing the post session evaluation quastionnaire.
                                        Links for downloading the application are:

                                        <br></br>
                                        <br></br>
                                        <h2>
                                            Android:

                                        </h2>

                                        <a
                                            href='https://play.google.com/store/apps/details?id=com.smarttech.smartevent&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img
                                                alt='Get it on Google Play'
                                                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                                style={{
                                                    borderRadius: "13px",
                                                    width: "290px",
                                                    height: "120px",
                                                }}
                                            />
                                        </a>


                                        <h2>
                                            Apple:

                                        </h2>
                                        <a
                                            href="https://apps.apple.com/us/app/smart-event/id6461775877?itsct=apps_box_badge&amp;itscg=30200"
                                            style={{
                                                display: "inline-block",
                                                overflow: "hidden",
                                                borderRadius: "13px",
                                                width: "250px",
                                                height: "83px",
                                                marginLeft: 20
                                            }}
                                        >
                                            <img
                                                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1692230400"
                                                alt="Download on the App Store"
                                                style={{
                                                    borderRadius: "13px",
                                                    width: "250px",
                                                    height: "83px",
                                                }}
                                            />
                                        </a>

                                    </p>

                                    <h2>
                                        BCLF2023 attendance monitoring

                                    </h2>
                                    <p>
                                        Participants at the 30 th BCLF2023 and 2 nd Montenegrin Conference of Clinical Chemistry and Laboratory
                                        Medicine must have their attendance established at the entrance and exit of each session. A dedicated
                                        staff will be responsible for reading badges (please be sure to have your badge read!)
                                    </p>

                                    <h2>
                                        BCLF2023 evaluation questionnaire
                                    </h2>
                                    <p>
                                        All participants have to complete the post-session evaluation questionnaire which could be found at the
                                        Smart event Congress application.
                                    </p>
                                    <h2>Certificate of attendance</h2>
                                    <div className='pl-0'>

                                        <p>
                                            All registered attendees will receive a Certificate of attendance via email, 10 days after the congress.
                                            Those participants wishing to receive
                                            <strong> EACCME® European Accreditation Council for Continuing Medical Education Certificate</strong>
                                            {" "} must have their attendance established at the entrance and exit of each session and, at the end of the congress complete post-session evaluation questionnaire available on Smart Event congress application.
                                        </p>
                                    </div>
                                    <div className='h-16 border-b'>

                                    </div>
                                    <h2>Registration fee</h2>
                                    <div className='pl-10'>

                                        <p>Registration fees are as follows:</p>
                                        <ul>
                                            <li>Full registration - €150</li>
                                            <li>Full registration for MACC (Montenegrin Association of Clinical Chemistry and Laboratory Medicine) members - €100</li>
                                        </ul>
                                        <p>On-site registration will not be possible.</p>
                                        <p>Online registration will be open till 3rd September 2023!.</p>
                                        <p>Daily registration is possible only for delegates from Montenegro (more information in Information / Local Corner).</p>
                                    </div>

                                    <h3>The full registration fee includes:</h3>
                                    <ul>
                                        <li>Entrance to lectures, laboratory workshops, industry-sponsored workshops, poster sessions</li>
                                        <li>Congress educational and promotional material</li>
                                        <li>Possibility to submit abstracts</li>
                                        <li>Certificate of attendance</li>
                                        <li>Lunch on 28th and 29th September</li>
                                        <li>Coffee breaks</li>
                                        <li>Opening Ceremony (Wednesday, 27th September)</li>
                                    </ul>
                                    <div className='pt-10'>
                                        <p>Get together dinner will be organized on Friday, September 29th, but it is not covered by the registration fee. All delegates have to register separately for the dinner if they want. The cost of the Get together dinner is €50.</p>
                                    </div>
                                    {/*  */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}
