import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import MultiStep from '../components/MultiStep';
import budva from '../assets/budva.jpg';
import PersonalInfo from '../components/StepForms/PersonalInfo';
import RegistrationForm from '../components/StepForms/RegistrationForm';
import GeneralInfo from '../components/StepForms/GeneralInfo';
import Accommodation from '../components/StepForms/Accommodation';
import Transfers from '../components/StepForms/Transfers/Transfers';
import Invoice from '../components/StepForms/Invoice';
import Submit from '../components/StepForms/Submit';
import { constants } from '../_constants/constants';
import validator from 'validator';
import katic from '../assets/panorama.jpg';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DropDownList from '../components/DropDownList';

export const ScientificAgenda = () => {
  const [loading, setloading] = useState(false);
  const validationSchema = Yup.object({
    familyName: Yup.string().required("Family name is required"),
    firstName: Yup.string().required("First name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address"),
    mobilePhone: Yup.string().required("Mobile phone is required"),
    country: Yup.string().required("Country is required"),
    over40: Yup.boolean().required("This field is required"),
    oralCommunication: Yup.boolean().required("This field is required"),
    topicName: Yup.string().required("Topic name is required"),
    abstractTitle: Yup.string().required("Abstract title is required"),
  });


  const handleSubmit = (values, actions) => {
    const { familyName, firstName, email, mobilePhone, country, over40, oralCommunication, topicName, abstractTitle } = values;

    // Prepare the data to be sent to the server
    const data = {
      familyName,
      firstName,
      email,
      mobilePhone,
      country,
      over40,
      oralCommunication,
      topicName,
      abstractTitle
    };

    console.log({
      data
    });

    // Send the data to the server using axios
    axios.post('/submit', data)
      .then(response => {
        console.log(response);
        // Do something with the response, for example, show a success message
      })
      .catch(error => {
        console.log(error);
        // Do something with the error, for example, show an error message
      })
      .finally(() => {
        // Reset the form's state
        actions.setSubmitting(false);
        actions.resetForm();
      });
  }

  return (
    <div className="home-root">
      <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
        <Header isMain />
        <div className='h-96  relative w-full overflow-hidden' style={{ zIndex: 22, height: 400 }}>
          <img className='absolute top-0 left-0 w-full h-full' src={katic} style={{ height: 450 }} /> //'linear-gradient(#001746, #012160a3)'
          <div className='absolute top-0 left-0 w-full h-full' style={{ backgroundColor: '#182c54a3', zIndex: 22, background: 'linear-gradient(#001746, #123275)' }} />
        </div>
        <div className='w-full relative z-40 -mt-60 pb-52'>
          <div className='w-full md:w-3/5 m-auto text-white text-center pb-16'>
            <h1> Send Abstract</h1>
          </div>
          <div className=' w-full lg:w-4/5 xl:w-4/5 2xl:w-4/6  pb-14 m-auto  rounded-lg shadow-lg  overflow-visible relative bg-white'>
            <div className='absolute left-0 top-0 w-full justify-center flex'>
              <div className='w-28 h-28 bg-white -mt-14 rounded-full border-gray-200 border flex justify-center items-center'>
									<i className="material-icons likes-icon text-blue-500 text-3xl"  style={{  fontSize: 35,color:'#123275',cursor: 'pointer'}}>article</i>
              </div>
            </div>
            <Formik
              initialValues={{
                familyName: "",
                firstName: "",
                email: "",
                mobilePhone: "",
                country: "",
                over40: false,
                oralCommunication: false,
                topicName: "",
                abstractTitle: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                handleSubmit(values, actions);

                setTimeout(() => {
                  console.log("Form data", values);
                  // Submit the form data to your backend here
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form className="bg-white p-6 rounded-lg w-3/4 m-auto pt-28 flex flex-row flex-wrap">
                  <div className="mb-4 w-1/2 pr-5">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="familyName">
                      Family Name
                    </label>
                    <Field
                        className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                      type="text"
                      name="familyName"
                      id="familyName"
                    />
                    <ErrorMessage name="familyName" className="text-red-500 text-xs err-msg" />

                  </div>
                  <div className="mb-4 w-1/2 pr-5">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="firstName">
                      First Name
                    </label>
                    <Field
                        className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        type="text"
                      name="firstName"
                      id="firstName"
                    />
                    <ErrorMessage name="firstName" className="text-red-500 text-xs" />
                  </div>
                  <div className="mb-4 w-1/2 pr-5">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                      Email
                    </label>
                    <Field
                        className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        type="email"
                      name="email"
                      id="email"
                    />
                    <ErrorMessage name="email" className="text-red-500 text-xs" />
                  </div>
                  <div className="mb-4 w-1/2 pr-5">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="mobilePhone">
                      Mobile Phone
                    </label>
                    <Field
                        className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        type="text"
                      name="mobilePhone"
                      id="mobilePhone"
                    />
                    <ErrorMessage name="mobilePhone" className="text-red-500 text-xs" />
                  </div>
                  <div className="mb-4 w-1/2 pr-5">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="country">
                      Country
                    </label>
                    <Field
                        className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        type="text"
                      name="country"
                      id="country"
                    />
                    <ErrorMessage name="country" className="text-red-500 text-xs" />
                  </div>
                  <div className="mb-4 w-1/2 pr-5">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="oralCommunication">
                      Can you do oral communication?
                    </label>
                    <div className='mt-4 w-40 h-10 border-gray-300 border flex flex-row justify-center items-center rounded-md overflow-hidden'>
                      <div onClick={() => setFieldValue("oralCommunication", true)} style={{ backgroundColor: values.oralCommunication ? '#084fde' : '#fff', color: values.oralCommunication ? '#fff' : 'black'}} className='text-white bg-blue-600 w-1/2 h-full flex justify-center items-center text-center font-bold text-sm  cursor-pointer'>
                        YES
                      </div>
                      <div onClick={() => setFieldValue("oralCommunication", false)} style={{ backgroundColor: !values.oralCommunication ? '#084fde' : '#fff', color: !values.oralCommunication ? '#fff' : 'black'}}  className=' w-1/2 text-center font-bold text-sm h-full flex justify-center items-center cursor-pointer'>
                        NO
                      </div>
                    </div>
                    <ErrorMessage name="oralCommunication" className="text-red-500 text-xs" />
                  </div>
                  <div className="mb-4 w-1/2 pr-5">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="topicName">
                      Topic Name
                    </label>

                    <div class="mb-3 w-full   ">
                        
                        <DropDownList
                            placeholder='Choose' 
                            options={[{label:'Yes', value: 'Yes'},{label:'No', value: 'No'}]} 
                            selectedValue={'Yes'} 
                            //onValueChange={setArrivalTransfer} 
                        />  
                    </div>

                    <ErrorMessage name="topicName" className="text-red-500 text-xs" />
                  </div>
                  <div className="mb-4 w-1/2 pr-5">
                    <label className="block text-gray-700 font-medium mb-2" htmlFor="abstractTitle">
                      Abstract Title
                    </label>
                    <Field
                        className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        type="text"
                      name="abstractTitle"
                      id="abstractTitle"
                    />
                    <ErrorMessage name="abstractTitle" className="text-red-500 text-xs" />
                  </div>
                  <div className='w-full flex justify-center pt-5'>
                    <button
                      className=" text-white font-bold bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300  rounded-xl text-sm w-full sm:w-auto px-10 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"                    type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                  
                </Form>
              )}
            </Formik>
            <div className='w-full absolute left-0 bottom-0 h-32  flex items-center justify-center gap-5 pr-20 pl-20'>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  )
}


const ErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ field, form: { touched, errors } }) =>
      touched[name] && errors[name] ? (
        <div className="text-red-500 text-xs">{errors[name]}</div>
      ) : null
    }
  </Field>
);