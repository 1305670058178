import React, { useEffect } from "react";
import COUNTRIES from "../../../_constants/countries";
import { RegistrationPageLabels } from "../../../_constants/labels";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import DropDownList from "../../DropDownList";
import CustomTable from "../../CustomTable";
import langService from "../../../_helpers/lang-service";
import useLanguage from "../../../redux/hooks/useLanguage";

const countriesArray = COUNTRIES.map((c) => ({
	label: c.name,
	value: c.code,
}));

const Accommodation = ({
	enable,
	shouldAccommodate,
	arrivalDate,
	departureDate,
	//nights,
	//roomDeposit,
	//noteAccommodate,
	setShouldAccommodate,
	setArrivalDate,
	setDepartureDate,
	//setNights,
	//setRoomDeposit,
	//setNoteAccommodate,
}) => {
	const [lang, setLang] = useLanguage();

	return (
		<div className="">
			<div className="flex flex-wrap">
				<div class="mb-3 w-full md:w-1/2 lg:w-1/3  ">
					<label
						for="surname"
						class="block mb-2 text-xs font-medium text-gray-400 "
					>
						I need a hotel reservation:
					</label>
					<DropDownList
						placeholder="Choose"
						options={[
							{ label: "Yes", value: "Yes" },
							{ label: "No", value: "No" },
						]}
						selectedValue={shouldAccommodate}
						onValueChange={setShouldAccommodate}
					/>
				</div>

				{/* {<div class="mb-3 w-full md:w-1/2 lg:w-1/3 p-4 ">
                    <label for="nights" class="block mb-2 text-xs font-medium text-gray-400 ">
                    {langService.getLabel("RegistrationPageLabels","NIGHTS",lang)}
                    </label>
                    <input 
                        value={nights} 
                        onChange={(e) => setNights(e.target.value)} 
                        placeholder="" 
                        type="text" 
                        id="nights" 
                        style={{ backgroundColor: "#2e80f10d"}} 
                        class="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        required
                    /> 
                </div>
                <div class="mb-3 w-full md:w-1/2 lg:w-1/3 p-4 ">
                    <label for="roomDeposit" class="block mb-2 text-xs font-medium text-gray-400 ">
                        
                        {langService.getLabel("RegistrationPageLabels","ROOM_DEPOSITE",lang)}
                    </label>
                    <input 
                        value={roomDeposit} 
                        onChange={(e) => setRoomDeposit(e.target.value)} 
                        placeholder="" 
                        type="number" 
                        id="roomDeposit" 
                        style={{ backgroundColor: "#2e80f10d"}} 
                        class="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        required
                    /> 
                </div>
                <div class="mb-3 w-full md:w-1/2 lg:w-1/3 p-4 ">
                    <label for="noteAccommodate" class="block mb-2 text-xs font-medium text-gray-400 -mt-4 " style={{ minHeight: 30}}>
                    {langService.getLabel("RegistrationPageLabels","ROOMATE_WITH",lang)}
                    </label>
                    <input
                        value={noteAccommodate} 
                        onChange={(e) => setNoteAccommodate(e.target.value)} 
                        placeholder="" 
                        type="text" 
                        id="noteAccommodate" 
                        style={{ backgroundColor: "#2e80f10d"}} 
                        class="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        required
                    /> 
                </div>} */}
			</div>
			<div className="flex flex-wrap">
				{enable && (
					<div class="mb-3 w-full md:w-1/2 lg:w-1/3 p-4 pt-2">
						<label
							for="postalCode"
							class="block mb-2 text-xs font-medium text-gray-400 "
						>
							Check in date
						</label>
						<DatePicker
							selected={enable ? arrivalDate : undefined}
							onChange={(date) => setArrivalDate(date)}
							placeholderText=""
                            wrapperClassName='w-full'
							className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
						/>
					</div>
				)}
				{enable && (
					<div class="mb-3 w-full md:w-1/2 lg:w-1/3 p-4 ">
						<label
							for="departureDate"
							class="block mb-2 text-xs font-medium text-gray-400 "
						>
							Check out date
						</label>
						<DatePicker
							selected={enable ? departureDate : undefined}
							onChange={(date) => setDepartureDate(date)}
							placeholderText=""
                            wrapperClassName='w-full'
							className="bg-blue-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
						/>
					</div>
				)}
			</div>
			<div className="relative py-10 hidden">
				<CustomTable />
			</div>
		</div>
	);
};

export default Accommodation;
