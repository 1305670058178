import React, { useState } from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import timeline from '../_constants/talks';
import CommercialWorkShops from './CommercialWorkShops';
import credit4 from '../assets/credits/credit4.png'
import credit5 from '../assets/credits/credit5.png'
import credit8 from '../assets/credits/credit8.png'

// Import Swiper styles
const ScheduleEvent = ({ time, title, speaker, isRed, aff }) => {
	return (
		<div style={{ minHeight: 100 }} className="sm:flex  flex-row justify-between items-center bg-white px-4 py-2 mb-2 rounded-lg shadow-md">
			<div className='pb-5'>
				<span className="text-base sm:text-lg font-bold">{time}</span>
				<div>
					<span className="block text-gray-500 text-sm sm:pr-40"><span className='font-semibold'>{speaker}</span> {aff ? ', ' + aff : ''}</span>
				</div>
			</div>
			<div className="sm:text-right sm:w-3/5 p-2">
				<span style={{ color: isRed ? 'red' : '' }} className="block text-sm text-gray-900 font-semibold whitespace-pre-line">{title}</span>
			</div>
		</div>
	);
};


const Session9 = ({ session, events, chairs, affs, selDay }) => {
	return (
		<div className={`my-10 relative `}>
			
			<div className="text-xl font-bold text-[#1a1a5e]  mb-4">{session}</div>
			{chairs.length > 0 && <div className=' mb-4 text-lg font-bold pl-2 relative'>
				Session {chairs.length > 1 ? 'chairs' : 'chair'}: {" "}
				<div className='-mt-2 pr-10'>
					{chairs.map((chair, i) => (
						<span key={'chair' + i} className='text-base text-gray-700 font-normal'><br></br><span className='font-semibold'>{chair}</span>{affs ? ', ' + affs[i] : ''}</span>
					))}
				</div>

				
			</div>}
			{events.map((event, i) => (
				<ScheduleEvent {...event} />
			))}
		</div>
	);
};


const Card1 = ({ day, date, onClick, isSelected }) => {
	return (
		<div onClick={onClick} className="relative bg-white border-r p-12 w-1/4 cursor-pointer">
			<div className="font-bold text-lg">{day}</div>
			<div className="text-gray-500 text-sm">{date}</div>
			{isSelected && (
				<div
					className="absolute bottom-0 z-20 left-0 bg-orange-500 h-1 transition-all duration-300"
					style={{ width: '100%', transform: 'translateX(-100%)' }}
				/>
			)}
		</div>
	);
};


const Card = ({ day, date, onClick, isSelected }) => {
	return (
		<div
			style={{ borderBottomColor: isSelected ? '#e78c31' : '#fff', borderBottomWidth: isSelected ? 4 : 4 }}
			onClick={onClick}
			className="bg-white border-r p-3  sm:p-12 w-1/4 cursor-pointer"
		>
			<div className="font-bold text-xs sm:text-lg">{day}</div>
			<div className="text-gray-500 text-xs sm:text-sm">{date}</div>
		</div>
	);
};


const Timeline = () => {
	const [selectedDay, setDay] = useState(0);
	const [selDay, setSelDay] = useState(0);


	const setSelectedDay = (day) => {
		setSelDay(-1);
		setDay(day);

		setTimeout(() => {
			setSelDay(day);
		}, 100);
	}

	const getImage = () => {

		switch (selDay) {
			case 0: return credit4;
			case 1: return credit8;
			case 2: return credit8;
			case 3: return credit5;
		
			default: return '';
		}
	}

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header />
				<main>
					<div className="mt-32  py-16">
						<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
							<div className="text-center mt-5">
								<h2 className="text-3xl font-bold text-gray-900">Scientific programme</h2>
							
							</div>
							<div className='mt-16 border-y-[5px] p-6 border-[#016fb9eb] rounded-md bg-gradient-to-t bg-white shadow-xl'>
								<div className='flex justify-between relative mb-5'>
									<h3 className="text-2xl font-bold text-gray-900 text-black">
										EACCME® Accreditation of event
									</h3>
									<div className=''>
										<img width={80} src='https://www.uems.eu/__data/assets/image/0009/2160/uems_logo.png' />
									</div>
								</div>
								
								<p className='pt-4 text-black'>
									The XXX Meeting of the Balkan Clinical Laboratory Federation (BCLF 2023) and II Montenegrin Conference of Clinical Chemistry and Laboratory Medicine, Herceg Novi, Montenegro 27/09/2023 - 30/09/2023, has been accredited by the European Accreditation Council for Continuing Medical Education (EACCME®) with 25.0 European CME credits (ECMEC®s). Each medical specialist should claim only those hours of credit that he/she actually spent in the educational activity.
								</p>
								<p className='pt-4 text-black'>
									Through an agreement between the Union Européenne des Médecins Spécialistes and the American Medical Association, physicians may convert EACCME® credits to an equivalent number of AMA PRA Category 1 CreditsTM. Information on the process to convert EACCME® credit to AMA credit can be found at 
									{" "}<a href='https://edhub.ama-assn.org/pages/applictations' className='text-blue-600' target='_blank'>
										https://edhub.ama-assn.org/pages/applictations
									</a>.
								</p>
								<p className='pt-4 text-black'>
									Live educational activities, occurring outside of Canada, recognised by the UEMS-EACCME® for ECMEC®s are deemed to be Accredited Group Learning Activities (Section 1) as defined by the Maintenance of Certification Program of the Royal College of Physicians and Surgeons of Canada.
								</p>
								<p className='pt-4 text-black'>
									The EACCME® awards ECMEC®s on the basis of 0,5 ECMEC® for 30 minutes of CME with a maximum of 8 ECMEC®s per day.

								</p>

							</div>
							<div className="text-left sm:mt-16 mt-10 flex justify-between items-center">
								<h3 className="text-2xl font-bold text-gray-900 ">Final programme</h3>
								<div>
									<div className="">
										<a href={'https://gtpgakufraytdhxtvqri.supabase.co/storage/v1/object/public/bclf/Scientific%20programme%20-%20final.pdf'} target="_blank" className="p-2 border-2 border-black text-black font-bold w-fit text-xs sm:text-sm hover:bg-black hover:text-white transition-all  rounded-lg">
											Download PDF
										</a>
									</div>
									
								</div>
							</div>
							<div className="sm:mt-5 mt-5 shadow-2xl rounded-md overflow-hidden bg-white">
								<div className='flex justify-center items-center rounded-lg border-b'>
									<Card isSelected={selectedDay === 0} day="Wednesday" date="27.09.2023." onClick={() => setSelectedDay(0)} />
									<Card isSelected={selectedDay === 1} day="Thursday" date="28.09.2023." onClick={() => setSelectedDay(1)} />
									<Card isSelected={selectedDay === 2} day="Friday" date="29.09.2023." onClick={() => setSelectedDay(2)} />
									<Card isSelected={selectedDay === 3} day="Saturday" date="30.09.2023." onClick={() => setSelectedDay(3)} />
									<Card isSelected={selectedDay === 4} day="Commercial workshops" date="" onClick={() => setSelectedDay(4)} />
								</div>
								<div className='bg-gray-100'>
									<div className={`  p-5 sm:p-10 transition-all relative ${selDay === 1 ? 'pt-24 sm:pt-20 ': 'pt-24 sm:pt-5'}`} style={{ minHeight: 600 }}>
										{selDay >= 0 && selDay < 4 && <div className=' absolute right-10 top-10 border shadow-md'>
											<img width={100} src={getImage()} />
										</div>}
										{selDay >= 0 && selDay < 4 && timeline[selDay].map((session) => (
											<Session9 {...session} selDay={selDay} />
										))}
										{selDay === 4 && <CommercialWorkShops />}
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>

			</div>
			<Footer />

		</div >
	)
}

export default Timeline;