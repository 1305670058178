import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import katic from '../assets/panorama.jpg';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DropDownList from '../components/DropDownList';
import { Link, useNavigate } from 'react-router-dom';

export const Information = () => {
  const navigate = useNavigate();

  const onSubmitWorkClick = () => {
    navigate("/SendAbstract");
  }

  return (
    <div className="home-root">
      <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
        <Header />
        <div className='w-full relativeh-screen mt-28 pb-20'>
          <main>
            <div className='w-full sm:w-5/6 m-auto px-5 pt-10'>
              <h1>About Montenegro</h1>
              <p>
                Montenegro is a country located in the west-central Balkans at the southern end of the Dinaric Alps, bounded by the Adriatic Sea. It stands on the edge between the east and the west, and different civilizations have left their mark on the country's rich cultural history. Roman floor mosaics, Illyrian gray castles, Venetian stony walls, vibrantly painted Orthodox monasteries, ornamental Catholic churches, modest mosque minarets, and socialist-style buildings can be found throughout the country.
              </p>
              <p>
                Visit <a href="https://www.montenegro.travel/en">Montenegro's official tourism website</a> to learn more.
              </p>
              <h1>About Herceg Novi</h1>
              <p>
                Herceg Novi is a coastal town in Montenegro located at the Western entrance to Boka Bay (Bay of Kotor) and at the foot of Mount Orjen. It has around 36,000 inhabitants and is recognizable by the abundance of mimosa trees and its numerous flights of stairs. The town has a unique architecture that is a result of the different civilizations that have controlled the city throughout the centuries.
              </p>
              <p>
                Visit <a href="https://www.hercegnovi.travel/en/">Herceg Novi's official tourism website</a> to learn more.
              </p>
              <h2>Climate</h2>
              <p>
                Herceg Novi has a mild Mediterranean climate with dry and warm summers and mild winters.
              </p>
              <h2>Major Telephone Codes and Numbers</h2>
              <ul>
                <li>International prefix: + 382</li>
                <li>Information: 1181</li>
                <li>Exact time: 125</li>
                <li>Fire department: 123</li>
                <li>Police and Emergency: 122 or 112</li>
                <li>Emergency: 124</li>
              </ul>
              <h2>Currency and Banking</h2>
              <p>
                The official currency of Montenegro is the Euro. Banks are open Monday through Friday and usually open at 08:00 or 08:30 and close around 16:00. On Saturdays, they close at 12:00. Only currencies can be changed at banks. ATMs work around the clock, and payment in shops, hotels, and restaurants can be made by credit or debit card.
              </p>
              <h2>Health Care</h2>
              <p>
                Pharmacies are usually open from 08:00 and close at 20:00 or 21:00. Emergency health services work around the clock, and all cities have emergency health systems. Hospitals and health centers are open from 7:00 to 20:00. Health care for foreigners is covered by international laws, so please check your health insurance coverage before traveling to Montenegro.
              </p>
              <h2>Supermarkets and Shops</h2>
              <p>
                Supermarkets tend to open at 06:00 and close at 22:00, larger supermarkets may work 24 hours a day except Sundays
                when they close for the night. General shops and clothes boutiques often work a double shift, opening in the morning,
                closing for around 3 hours in the afternoon and then opening again in the evening until late.                
              </p>
              <h2>Visa</h2>
              <p>
                Residents of most European countries do not need a visa. For more details on a specific country, see the link.
                  <a href="https://www.gov.me/en/diplomatic-missions/embassies-and-consulates-of-montenegro">
                  https://www.gov.me/en/diplomatic-missions/embassies-and-consulates-of-montenegro       
                  </a>     
              </p>
              <h2>How to get to Herceg Novi from nearby airports:</h2>
              <ul>
                <li><a className='text-blue-700' href="https://www.dubrovnikairport.hr/">Dubrovnik</a></li>
                <li><a  className='text-blue-700' href="https://podgorica-airport.com/">Podgorica</a></li>
                <li><a  className='text-blue-700' href="https://www.tivat-airport.com/">Tivat</a></li>
                <li><a  className='text-blue-700' href="https://tirana-airport.com/">Tirana</a></li>
              </ul>
            </div>
          </main>
        </div>

      </div>
      <Footer />
    </div>

  )
}
