import React from 'react'
import greiner from '../assets/greiner.svg';

// Import Swiper styles
const CommercialWorkShops = () => {
	return (
		<div className="my-10">
			<Schedule />
		</div>
	);
};


const Schedule = () => {
	return (
		<div className="p-0">
			<div className="mb-7">
				<h2 className="text-xl font-bold mb-2 text-red-500">Wednesday</h2>
				<div className="border p-4 rounded-xl shadow bg-white sm:flex">
					<div className='w-full'>
						<span className="text-lg mb-2"> <strong>Commercial workshop 1</strong>: Roche: Patient journey in HCC and importance of diagnostics in multidisciplinary approach, Western Balkan Initiative</span>
						<p className='pt-5 text-blue-700'><strong>Sanja Stankovic</strong>, Director of Center for Medical Biochemistry – University Clinical Center of Serbia,</p>
						<p className='pt-2 text-blue-700'><strong>Beti Todorovska</strong>, Head of the department at University Clinic for Gastroenterohepatology Skoplje North Macedonia</p>
						<p className='pt-2 text-blue-700'><strong>Damir Muhovic</strong>, Clinical Center of Montenegro</p>
					</div>
					<div className='p-5 w-[400px] px-12 flex justify-center items-center'>
						<img src={require('../assets/sponsors/gold/s1.jpg')} width={400} />
					</div>
				</div>
			</div>

			<div className="mb-7">
				<h2 className="text-xl font-bold mb-2 text-red-500">Thursday</h2>
				<div className="border p-4 rounded-xl shadow mb-4  bg-white sm:flex min-h-[250px]">
					<div className='w-full'>
						<span className="text-lg mb-2"><strong>Commercial workshop 2</strong>: Sysmex: The impact of pre-analytical variables and drug interference on coagulation tests</span>
						<p className='pt-5  text-blue-700'><strong>Michael Blume</strong>, Assistant Director Haemostasis, Sysmex Europe</p>
					</div>
					<div className='p-5 w-[400px] px-12 flex justify-center items-center'>
						<img src={require('../assets/sponsors/platinum/s2.png')}/>
					</div>
				</div>
				<div className="border p-4 rounded-xl shadow  bg-white sm:flex">
					<div className='w-full'>
						<span className="text-lg mb-2"><strong>Commercial workshop 3</strong>: Mindray: Envision more with advanced hematology automation</span>
						<p className='pt-5 text-blue-700'><strong>Adrian Scurtu</strong>, Senior Product Specialist IVD <br></br>
						Mindray Southeast Europe</p>
					</div>
					<div className='p-5 w-[400px] px-12 flex justify-center items-center'>
						<img src={require('../assets/mindray.png')} width={350} />
					</div>
				</div>
			</div>

			<div className="mb-7">
				<h2 className="text-xl font-bold mb-2 text-red-500">Friday</h2>
				<div className="border p-4 rounded-xl shadow mb-4  bg-white sm:flex  min-h-[250px]">
					<div className='w-full'>
						<span className="text-lg mb-2"><strong>Commercial workshop 4</strong>: Sysmex: Reliable Automated Urinalysis by Sysmex</span>
						<p  className='pt-5 text-blue-700'><strong>Vladimir Poleksic</strong>, EMEA Market Manager Urinalysis</p>
					</div>
					<div className='p-5 w-[400px] px-12 flex justify-center items-center'>
						<img src={require('../assets/sponsors/platinum/s2.png')}/>
					</div>

				</div>
				<div className="border p-4 rounded-xl shadow  bg-white sm:flex  min-h-[250px]">

					<div className='w-full'>
						<span className="text-lg mb-2"><strong>Commercial workshop 5</strong>: Greiner Bio-One: Preanalytical phase - a continuous challenge for laboratory professionals.</span>
						<p  className='pt-5 text-blue-700'><strong>Agnieszka Molas-Kilianek</strong>, Application Product Specialist for Preanalytics and Bioscience</p>
					</div>
					<div className='p-5 w-[400px] px-12 flex justify-center items-center'>
						<img src={greiner}  />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CommercialWorkShops;