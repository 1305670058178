import React from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const Speaker = ({ name, date, time, imgUrl, text1, text2}) => {
    return (
        <Link to={{pathname: "/Lecturer", name, date, time, imgUrl, text1, text2}} state={{name, date, time, imgUrl, text1, text2}} params={{ name, date, time, imgUrl, text1, text2}}>
            <div className=' cursor-pointer relative  bg-gray-200 rounded-t-full h-52 w-full overflow-hidden'>
                <img className="w-full h-full" src={imgUrl} />
                <div className='bg-[#e79748] font-bold text-sm text-white flex justify-center items-center absolute right-2 bottom-2  w-20 h-10 rounded-full'>
                    {date}
                </div>
                <div className='absolute top-0 left-0 w-full h-full' style={{ backgroundColor: '#182c54a3', zIndex: 22, background: 'linear-gradient(#00000026, #01605200)' }} />
            </div>
            <div className='w-full text-center' style={{ minHeight: 100}}>
                <span className='speaker-name cursor-pointer hover:underline'>{name}</span>
                <br></br>
                <span className='speaker-name cursor-pointer text-orange-500 text-sm'>
                   {time}
                </span>
            </div>
        </Link>
    )
}

export default Speaker;
