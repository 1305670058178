const abstracts_data = [
    {
        "topic": "",
        "abstract_title": "Allergology",
        "Name": "",
        "Country": "",
        "file_url": ""

    },
    {
        "topic": "Allergology",
        "abstract_title": "Cross-reactive anti carbohydrate determinants antibodies impact on inhalant allergens sensitization testing",
        "Name": "Marija Friscic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/135kWxZyZFTsLfZRchigS1lDkSNd3RhdQ/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Autoimmune disease",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Autoimmune disease",
        "abstract_title": "Diagnostic value of indirect immunofluorescence for the autoimmune bullous dermatoses. Our experience in Albania",
        "Name": "Margarita Prifti-Kurti",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1utro-AyEfFHfBOje2OtJxdHQ23gAzi3b/view?usp=drive_link"
    },
    {
        "topic": "Autoimmune disease",
        "abstract_title": "Significance of paraprotein determination in systemic connective tissue disease",
        "Name": "Olja Gabric",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/1xZ28Xs65X-rP5hZn2dIuxXOjICjMcoTN/view?usp=drive_link"
    },
    {
        "topic": "Autoimmune disease",
        "abstract_title": "Rods and rings antinuclear antibodies (ANA) pattern unrelated with hepatitis C treatment, a case report",
        "Name": "Helena Lame",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1yej2LM8haakLYRyA3tZAQ9I_1puO4fpE/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Bone and mineral metabolism",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Bone and mineral metabolism",
        "abstract_title": "Laboratory assessment of bone metabolism in patients with hemoglobinopathy by dosing the bone resorption marker, β-crosslaps",
        "Name": "Jorida Zoga",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/13cxCsE7j51QJOu05TUJPkpmDv2Cw-Yie/view?usp=drive_link"
    },
    {
        "topic": "Bone and mineral metabolism",
        "abstract_title": "Prevalence, and impact of vitamin D insufficiency/deficiency in multiple myeloma patients",
        "Name": "Daniela Gerova",
        "Country": "Bulgaria",
        "file_url": "https://drive.google.com/file/d/1vRIN2JAb8b08-Iu4XWTBr9vflzBMCgdo/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Cardiovascular diseases and cardiovascular diseases risk prediction",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Cardiovascular diseases and cardiovascular diseases risk prediction",
        "abstract_title": "Comparison of lipid status in premenopausal and menopausal women",
        "Name": "Aleksandra Todorovska",
        "Country": "North Macedonia",
        "file_url": ""
    },
    {
        "topic": "Cardiovascular diseases and cardiovascular diseases risk prediction",
        "abstract_title": "Soluble ST2 is associated with inflammatory and calcification markers in patients with atrial fibrillation or heart failure",
        "Name": "Bistra Galunska",
        "Country": "Bulgaria",
        "file_url": ""
    },
    {
        "topic": "Cardiovascular diseases and cardiovascular diseases risk prediction",
        "abstract_title": "Presentation of the results of BNP testing for a 3 years period from 2020 to 2023 in the Bitola Clinical Hospital, North Macedonia",
        "Name": "Kiril Chiplakovski",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/1-6epoOgDI3aa6X8uaTjnGMRCFFGqXxwB/view?usp=drive_link"

    },
    {
        "topic": "Cardiovascular diseases and cardiovascular diseases risk prediction",
        "abstract_title": "Could determination of Bcl2 and caspase 3 activity indicate plaque evolution in ischemic heart disease patients?",
        "Name": "Tatjana Ristic",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/11sHVJcDHWgeliPpybi6bIKuTXFHd4uIC/view?usp=drive_link"
    },
    {
        "topic": "Cardiovascular diseases and cardiovascular diseases risk prediction",
        "abstract_title": "Tumor necrosis factor-alpha, selectin-E and matrix metalloproteinase-7 as potential biomarkers for carotid artery disease",
        "Name": "Snezana Jovicic",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/10gESsGdcSpvWPX6tRRO9m5YLw6KzVnHw/view?usp=drive_link"
    },
    {
        "topic": "Cardiovascular diseases and cardiovascular diseases risk prediction",
        "abstract_title": "Heart failure during hospitalization for acute myocardial infarction: the prognostic role of monocytes",
        "Name": "Helena Lame",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1ylpioqz3vcedrterviX8a8Fbk3Ug2o1a/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Cerebrospinal fluid analysis",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Cerebrospinal fluid analysis",
        "abstract_title": "Cell analysis in cerebrospinal fluid (CSF) using Sysmex XN 550 hematology analyzers",
        "Name": "Biljana Ilkovska",
        "Country": "North Macedonia",
        "file_url": "https://docs.google.com/presentation/d/11Nxljtc-hX7rjPaRj-aVck79aPSv8oGG/edit?usp=drive_link&ouid=100309161525986125429&rtpof=true&sd=true"
    },
    {
        "topic": "Cerebrospinal fluid analysis",
        "abstract_title": "Analysis of cerebrospinal fluid samples using multiplex PCR - biofire FilmArray meningitis/encephalitis panel for 2022",
        "Name": "Roberto Cvetkovski",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/1vDO70poP34BbdVkWVjsXWfAw9INPh8cr/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "COVID-19",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "COVID-19",
        "abstract_title": "Changes in the level of D-dimers and serum concentration of CRP in patients who had a more severe clinical picture of COVID-19 - a retrospective study",
        "Name": "Julijana Dimovska",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/13iqDtgdQ-wuotwpCDtsj-Ch3L75Wmzb6/view?usp=drive_link"
    },
    {
        "topic": "COVID-19",
        "abstract_title": "D-dimer levels as a predictor of the bad progression of COVID-19 in Durres, Albania",
        "Name": "Majlinda Kallco",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/15TJX-hl5saF8BZEcmL2lZU1p1QAdmmrD/view?usp=drive_link"
    },
    {
        "topic": "COVID-19",
        "abstract_title": "Comparison of four systems for SARS-CoV-2 antibody at five time points after SARS-CoV-2 vaccination",
        "Name": "Aleksandra Atanasova Boshku",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/10wlwxkVOBU-VJGR19mH7Gljti2RqGQML/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Diabetes, adiposity, metabolic syndrome",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "Melatonin, growth hormone and cortisol levels depending on body mass index in women with metabolic syndrome",
        "Name": "Vania Peneva",
        "Country": "Bulgaria",
        "file_url": "https://drive.google.com/file/d/1u1Vf-DgfjTel8U3fy8ZgSi-ThNLxOgaO/view?usp=drive_link"
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "Evaluation of HOMA-IR and TG-HDL_C ratio in obese pubertal children in Bosnia and Herzegovina",
        "Name": "Jasmina Foco-Solak",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1xJzACpvsSih2mdYkiVOpY03RikJd-kee/view?usp=drive_link"
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "An association between GST polymorphisms and diabetic nephropathy development",
        "Name": "Dragana Pavlovic",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1v-fFGEcBJoZvZ8mbBfdfqPGF_Cx_2Nvw/view?usp=drive_link"
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "Influence of age on hemoglobin levels in children with type 1 diabetes mellitus",
        "Name": "Indira Mujezin",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1uyZugXbdx4rIpxigmCBbezCwhXiU7WFt/view?usp=drive_link"
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "Polycystic ovary syndrome (PCOS) and risk of cardiovascular disease",
        "Name": "Dragana Puhalo Sladoje",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1-9F7K6q63093WNq2lIUs2vQB17IxiREr/view?usp=drive_link"
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "Correlation between modified neutrophil to lymphocyte ratio indexes and glycated hemoglobin in patients with diabetes mellitus type 2",
        "Name": "Aleksandra Klisic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/10OANVisEZJwxg4rzx1M9-rf1Faj4ytW8/view?usp=drive_link"
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "Serum soluble transferrin receptor levels in relation with obesity and insulin resistance in adolescent girls",
        "Name": "Aleksandra Klisic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/11cAMNOJV4Iz9c1nGgycXwFnXQT0XiibS/view?usp=drive_link"
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "Correlation between body mass index and red cell distribution width in adolescents",
        "Name": "Aleksandra Klisic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/11VzYosEuvBANpS4xdQ6qktEiuIz9OvRW/view?usp=drive_link"
    },
    {
        "topic": "Diabetes, adiposity, metabolic syndrome",
        "abstract_title": "Triglyceride to high density lipoprotein cholesterol ratio as surrogate marker for insulin resistance",
        "Name": "Najdana Gligorovic Barhanovic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/13XR06rvpuscr1_hAOWMsMT7sJT2eLrF6/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Dyslipidemia and atherosclerosis",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Dyslipidemia and atherosclerosis",
        "abstract_title": "Inflammatory markers in atherosclerosis-related diseases patients and healthy subjects",
        "Name": "Saska Djekic",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1x8Op0I4SVNn2Fvx7rbb2Yd1TFz0ikFeJ/view?usp=drive_link"
    },
    {
        "topic": "Dyslipidemia and atherosclerosis",
        "abstract_title": "Examination of cholesterol levels in preschool children in Dubrovnik",
        "Name": "Marina Vuceljic",
        "Country": "Croatia",
        "file_url": "https://drive.google.com/file/d/1pyLb3HX1ZOVuhyFsLiLi4xd-42aF2_83/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Endocrinology",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Endocrinology",
        "abstract_title": "Screening test for Hashimoto's thyroiditis and hypothyroidism",
        "Name": "Altin Goxharaj",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1w3Obl02K19laWf-JTtEWO0G26bCUsvXo/view?usp=drive_link"

    },
    {
        "topic": "Endocrinology",
        "abstract_title": "Hypothyroidism and its influence on lipid and glycemic status",
        "Name": "Gjurgjana Danilova",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/1yIS-M1tOcmvsL3PBOn7FnvUl7dynXtiF/view?usp=drive_link"
    },
    {
        "topic": "Endocrinology",
        "abstract_title": "Macroprolactinemia: ten years experience",
        "Name": "Dragana Stanisic",
        "Country": "Serbia",
        "file_url": ""
    },
    {
        "topic": "Endocrinology",
        "abstract_title": "Comparison of polyethilene glycol prolactin precipitation methods for macroprolactine detection",
        "Name": "Jana Djapic Savelic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/1-hKJy-Jy23aB7-80QnaOvRQgk90X5NuZ/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Gastrointestinal diseases",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Gastrointestinal diseases",
        "abstract_title": "Serum lipase a more sensitive biomarker than serum amylase in the diagnosis of acute pancreatitis",
        "Name": "Katerina Dorre",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1zFvyIMcCae9IcQNIpAnMLlW18X1q0-id/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Hemostasis and thrombosis",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Hemostasis and thrombosis",
        "abstract_title": "Sedentary work increases D-dimer levels in women regardless of age",
        "Name": "Predrag Simjanovski",
        "Country": "North Macedonia"
    },
    {
        "topic": "Hemostasis and thrombosis",
        "abstract_title": "PROS 1 mutation and protein S deficiency as a risk factor for poor pregnancy outcomes",
        "Name": "Enisa Zaric",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/1-2hdgltvXEuENb_IwbrR2znooSJHUBke/view?usp=drive_link"
    },
    {
        "topic": "Hemostasis and thrombosis",
        "abstract_title": "Successfully resolved case of acquired hemophilia A patient",
        "Name": "Enisa Zaric",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/1zwlruByY5AeFHYVw7Iwnd-xkt-uURl-0/view?usp=drive_link"
    },
    {
        "topic": "Hemostasis and thrombosis",
        "abstract_title": "Acquired hemophilia A and systemic connective tissue disease: a case report",
        "Name": "Enisa Zaric",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/1zqIIOIq48psXV6bbTsOhdyKuodoEbF9F/view?usp=drive_link"

    },
    {
        "topic": "",
        "abstract_title": "Interferences in laboratory testing",
        "Name": "",
        "Country": "",
        "file_url": "",

    },
    {
        "topic": "Interferences in laboratory testing",
        "abstract_title": "Clinical important Interference of heparin-tubes on 25-OH vitamin D immunoassay on Maglum 4000 Plus",
        "Name": "Adela Kryeziu",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1wrX2TB_Rl-eeupgm2JwOhjC1-fTzbuLo/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Kidney diseases and transplantation",
        "Name": "",
        "Country": "",
        "file_url": "",

    },
    {
        "topic": "Kidney diseases and transplantation",
        "abstract_title": "The role of soluble transferrin receptor in the detection of iron deficiency anemia in dialysis patients",
        "Name": "Ana Cuk",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1v8RlstBfnqyWszkVSZuXD91R7ezMz6GX/view?usp=drive_link"

    },
    {
        "topic": "Kidney diseases and transplantation",
        "abstract_title": "Evaluation of the early markers of overweight and obesity related renal injury in children and adolescents",
        "Name": "Svetlana Cekovska",
        "Country": "North Macedonia",
        "file_url": ""

    },
    {
        "topic": "Kidney diseases and transplantation",
        "abstract_title": "Status of kynurenine in chronic kidney disease",
        "Name": "Ivana Isakov",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/1xI4A6hm9B96maaUq86ZfqcPxdBk4FhfM/view?usp=drive_link"

    },
    {
        "topic": "Kidney diseases and transplantation",
        "abstract_title": "Evaluation of laboratory parameters in hemodialysis patients in Vlora",
        "Name": "Valbona Isufaj",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1yRrWs6mq3AM3QvH45Nk3gCW0mQq7da6_/view?usp=drive_link"


    },
    {
        "topic": "Kidney diseases and transplantation",
        "abstract_title": "The diagnostics value of cystatin C for detecting the severity of chronic kidney disease",
        "Name": "Marijana Dajak",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/1yafcpm7gSAwD5xAHQwN7es4uxsYQALr7/view?usp=drive_link"

    },
    {
        "topic": "",
        "abstract_title": "Laboratory analytical techniques",
        "Name": "",
        "Country": "",
        "file_url": ""

    },
    {
        "topic": "Laboratory analytical techniques",
        "abstract_title": "Types and frequency of errors in the pre-analytical phase in the clinical laboratory - single center study from Bosnia and Herzegovina",
        "Name": "Mirsad Panjeta",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1-PS5HD-4wFHl6m-CX9EKMe8YVNh2A3rw/view?usp=drive_link"
    },
    {
        "topic": "Laboratory analytical techniques",
        "abstract_title": "Validation of direct method for magnesium quantification in serum",
        "Name": "Vasil Vasilev",
        "Country": "Bulgaria",
        "file_url": "https://drive.google.com/file/d/144_AlHj5hbyBit7wDx2-pCqSQxa0mGIF/view?usp=drive_link"

    },
    {
        "topic": "",
        "abstract_title": "Laboratory hematology",
        "Name": "",
        "Country": "",
        "file_url": ""

    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Quantification of serum erythroferrone in patients with anemia with chronic disease",
        "Name": "Victor Manolov",
        "Country": "Bulgaria",
        "file_url": "https://docs.google.com/presentation/d/13rBrDJ3AtzxJcK5WujCWmEU39GkvnI35/edit?usp=drive_link&ouid=100309161525986125429&rtpof=true&sd=true"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Coagulopathy with severe eye bleeding in IgM monoclonal gammopathy as the presentation of lymphoplasmacytic lymphoma",
        "Name": "Enisa Zaric",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/1zbv1lrdYXmN2wpdDGduqzdmxWrSm4tmF/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Predictive markers of mantle cell lymphoma in leukemic phase diagnosed with flow cytometry",
        "Name": "Enisa Zaric",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/1zimisJFtFGafcOYMNVEGH46EBFrO06LP/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Rare presentation of follicular lymphoma with peripheral blood involvement confirmed with flow cytometry, clinical characteristics and outcome",
        "Name": "Enisa Zaric",
        "Country": "Crna Gora",
        "file_url": "https://drive.google.com/file/d/1zKZ_u-EbD3vXcZsKPXc_ADgRuD1JHUFv/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Advanced mantle cell lymphoma staged by flow cytometry coexisting with colon adenocarcinoma",
        "Name": "Enisa Zaric",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/1zXOSFXQsd2gim0thuJUtmHwa5gGMxNm5/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "A Case report of EDTA induced thrombocytopenia",
        "Name": "Azhena Shehu",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1-vt1DaCkI09k2YhUNXt0qkL1fMSVa1r8/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Verification of complete blood count and erythrocyte sedimentation rate on the MEK1305 hematology analyzer",
        "Name": "Tamara Gojkovic",
        "Country": "Serbia",
        "file_url": "https://docs.google.com/presentation/d/1y5JL4RQCvl7btNJOqlDQJUgHO_z_jDLe/edit?usp=drive_link&ouid=100309161525986125429&rtpof=true&sd=true"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "The challenge of diagnosing promyelocytic leukemia in the hematology laboratory: a case report",
        "Name": "Nermina Klapuh-Bukvic",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1tvwU8THXFjREAiUsrfM0xrh9k3nIxBvB/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Is Na-citrate an alternative anticoagulant in conditions of EDTA-dependent pseudothrombocytopenia?",
        "Name": "Nermina Klapuh-Bukvic",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1skLgAITI7aTd-Sii1CjT_fSrhPONyWnb/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Hemoglobine H disease in Albania. A case report",
        "Name": "Arba Coraj",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1z-K5RkEcEqkfTEuWv7QAJHITNC-g5Un5/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Analytical verification and comparability of differential counting values on the hematology analyser Celltac G",
        "Name": "Velimir Belcic",
        "Country": "Croatia",
        "file_url": "https://drive.google.com/file/d/1yr5dTeHopc2pi-WY3G4Fw6dRDPcAVAn5/view?usp=drive_link"
    },
    {
        "topic": "Laboratory hematology",
        "abstract_title": "Hemoglobin Levels: A comparative study of female textile workers and bank office workers in North Macedonia",
        "Name": "Vladimir Andovski",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/1q3QiRCtONboMqc0eaZ250HkAoLDy5IIE/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Molecular diagnostics",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Molecular diagnostics",
        "abstract_title": "Acquired EGFR T790M resistance mutation identified from liquid biopsy in a patient with EGFR-mutated lung cancer: a case report",
        "Name": "Vanesa Sekerus",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/10xYyWg4hjmPSEwb24N82V5vAr1KyAlh7/view?usp=drive_link"
    },
    {
        "topic": "Molecular diagnostics",
        "abstract_title": "The emerging power of miRNAs in management of acute coronary syndrome",
        "Name": "Marija Hiljadnikova-Bajro",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/13mUXKkUYRCZz8uqumvAPHCLtj8QXA0D0/view?usp=drive_link"
    },
    {
        "topic": "Molecular diagnostics",
        "abstract_title": "Prevalence of thrombophilia-associated genetic risk factors in reproductively challenged female population in Montenegro",
        "Name": "Sara Backovic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/13CD-YMVFC78QRUeAWsx5BcaROmhQve9d/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Nutrition and nutrigenomics",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Nutrition and nutrigenomics",
        "abstract_title": "The impact of Macedonian orthodox christian fasting during lent on lipid status",
        "Name": "Marija Valjakova",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/12Y7BkxnbabD6T4cQVyVnDDhl-n3irVjd/view?usp=drive_link"
    },
    {
        "topic": "Nutrition and nutrigenomics",
        "abstract_title": "Non-celiac gluten sensitivity (NCGS) vs. celiac disease (CD): the trend in the Albanian population",
        "Name": "Valbona Isufaj",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1-SYzmCXazfHvmwyMbGY8hR3ln_W8CngU/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Prenatal and neonatal testing",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Prenatal and neonatal testing",
        "abstract_title": "Newborn screening in Albania",
        "Name": "Albana Daka",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1-R693PJAYZ3r8E4MVF8RUAznkDAWEjd8/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Quality control of the clinical laboratory",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Quality control of the clinical laboratory",
        "abstract_title": "Evaluating assay precision of glucose on Cobas C311 Roche analyzer: “the importance of verification in clinical management”",
        "Name": "Melda Emin",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/1wb6w8i3gvwbPtnFokdQp7Q4g1XRK18vI/view?usp=drive_link"
    },
    {
        "topic": "Quality control of the clinical laboratory",
        "abstract_title": "C-Reactive protein assay precision evaluation on Cobas C311 Roche analyzer",
        "Name": "Hristina Ampova",
        "Country": "North Macedonia",
        "file_url": "https://drive.google.com/file/d/1wN6fiYM-GV-zJOzIYlIbpDwIq8tmSVaS/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Sepsis",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Sepsis",
        "abstract_title": "Determination of NEUT -RI and NEUT -GI values in septic patients",
        "Name": "Isidora Radosavkic",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/1-bX6rF4XffKBI9Tex_1CbYv6AgRE9sD5/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Smart technology labs",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Smart technology labs",
        "abstract_title": "Awareness of medical students about green chemistry, green labs and sustainability",
        "Name": "Rabia Semsi",
        "Country": "Turkey",
        "file_url": "https://drive.google.com/file/d/1w0bdIq6Hm3X-QJq6Sb7JACMsMFOmqhJe/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Therapy drug monitoring",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Therapy drug monitoring",
        "abstract_title": "Cyclosporine and tacrolimus use in Montenegrin patients from 2020 to 2023",
        "Name": "Jana Djapic Savelic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/1-kLjrfE2GXPv9D_JyQS5fPXMFvHBGg92/view?usp=drive_link"
    },
    {
        "topic": "Therapy drug monitoring",
        "abstract_title": "Infliximab and adalimumab use in Montenegrin patients",
        "Name": "Marija Femic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/11kKtQJ7ZUZJR5q60DlyNQPISMdk9l5mo/view?usp=drive_link"
    },
    {
        "topic": "Therapy drug monitoring",
        "abstract_title": "The importance of the value of the total аllowable error when calculating the Sigma metric for determining the concentration of lithium and tacrolimus",
        "Name": "Tanja Brasanac",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/15JfQgGpVIwitsXXReRoAVBTKqrof4hsw/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Tumor markers",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Tumor markers",
        "abstract_title": "Investigation of the possible association between galectin and apoptosis in gastric cancer patients",
        "Name": "Funda Kosova",
        "Country": "Turkey",
        "file_url": "https://drive.google.com/file/d/1tKUq0BDzWm_RxY5b7xK7tGtIs854YqZs/view?usp=drive_link"
    },
    {
        "topic": "Tumor markers",
        "abstract_title": "The associations plasma cathepsin S with redox status parameters and HDL subclasses in non-Hodgkin's lymphoma patients",
        "Name": "Bosa Mirjanic-Azaric",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/15DUD6dyK22IpyqbrfbKD6Qh9Dft3ef-n/view?usp=drive_link"
    },
    {
        "topic": "Tumor markers",
        "abstract_title": "Cervical cancer: knowledge, attitudes, practices of women and feasibility of screening for precancerous lesions in a health district of Cameroon",
        "Name": "Lotin Dipita Lobe Felicien Thierry",
        "Country": "Cameroon",
        "file_url": ""
    },
    {
        "topic": "Tumor markers",
        "abstract_title": "Pretreatment neutrophil-to-lymphocyte ratio as a prognostic biomarker in patients with lung, pancreas, and colorectal cancer",
        "Name": "Mariana Yordanova",
        "Country": "Bulgaria",
        "file_url": "https://drive.google.com/file/d/1vOhVrxyYZWcLGVYfW_2ZsK80dvYkjjsn/view?usp=drive_link"
    },
    {
        "topic": "Tumor markers",
        "abstract_title": "Comparision of CA125, HE4 and ROMA index in predicting ovarian cancer",
        "Name": "Marija Hrkac",
        "Country": "Bosnia and Herzegovina",
        "file_url": "https://drive.google.com/file/d/1vqqSIdShJJh44hWofXj-Cbj9qzAU3HR1/view?usp=drive_link"
    },
    {
        "topic": "",
        "abstract_title": "Urin analysis - qualitative and quantitative",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Urin analysis - qualitative and quantitative",
        "abstract_title": "Novel urinary biomarkers with potential for clinical application",
        "Name": "Alen Bajro",
        "Country": "North Macedonia",
        "file_url": ""
    },
    {
        "topic": "",
        "abstract_title": "Other topic",
        "Name": "",
        "Country": "",
        "file_url": ""
    },
    {
        "topic": "Other topic",
        "abstract_title": "Presentation of the results of HCV testing for a 5-year period from 2018 to 2023 in the Bitola Clinical Hospital, North Macedonia",
        "Name": "Aleksandar Dimovski",
        "Country": "North Macedonia",
        "file_url": ""
    },
    {
        "topic": "COVID-19",
        "abstract_title": "Pediatric vitamin D levels from a Romanian pediatric tertiary center during pandemic confinement",
        "Name": "Ioana Sonia Ardeleanu",
        "Country": "Romania",
        "file_url": "https://docs.google.com/presentation/d/1tAU1zPMfnpx9KGPLSDFV9ObWfufaALHt/edit?usp=drive_link&ouid=100309161525986125429&rtpof=true&sd=true"

    },
    {
        "topic": "Other topic",
        "abstract_title": "The Importance of serum protein electrophoresis: a case report",
        "Name": "Hamide Shllaku-Sefa",
        "Country": "Albania",
        "file_url": "https://drive.google.com/file/d/1sQ1n1kNnYRbSufROs0xRN3OKP3SxhNoK/view?usp=drive_link"
    },
    {
        "topic": "Other topic",
        "abstract_title": "Clinical justification of extensive and too frequent laboratory retests of oncological patients",
        "Name": "Marija Milasevic",
        "Country": "Montenegro",
        "file_url": "https://drive.google.com/file/d/10zzAKSy_qhyrLP4CP0ZN8VHqR60YwJfL/view?usp=drive_link"
    },
    {
        "topic": "Other topic",
        "abstract_title": "Circulating progranulin levels in newly diagnosed obstructive sleep apnea syndrome patients",
        "Name": "Meral Yuksel",
        "Country": "Turkey",
        "file_url": "https://drive.google.com/file/d/106Q7cV0_Tr9rp7mG1whbY9DgyAlMBlD7/view?usp=drive_link"
    },
    {
        "topic": "Other topic",
        "abstract_title": "Correlation between automated semen analysis SQA VISION® and manual semen assessment",
        "Name": "Daniela Bartolovic",
        "Country": "Serbia",
        "file_url": "https://drive.google.com/file/d/1xeW30SF1gv2rUDjzgmcDnkvSxYnkfWS8/view?usp=drive_link"
    }
]

export default abstracts_data;