import { useSelector, useDispatch } from 'react-redux'
import { setLanguage } from '../languageSlice'

function useLanguage() {
  const {language} = useSelector(state => state.language);
  const dispatch = useDispatch();

  function handleLanguageChange(newLanguage) {
    dispatch(setLanguage(newLanguage));
  }

  return [ language, handleLanguageChange ];
}

export default useLanguage