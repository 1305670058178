import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import labQ from '../assets/sponsors/gold/s2.svg';
import beckman from '../assets/sponsors/other/beckman.svg';
import qcnet from '../assets/sponsors/other/logo-biorad.jpg';

const mainDaimond = {
	name: 'mainDaimond Sponsor ',
	bannerUrl: require('../assets/sponsors/diamond/mojlab.png'),
	url: 'http://www.mojlab.me/'
};

const mainPlatinum = {
	name: 'mainPlatinum Sponsor 1',
	bannerUrl: require('../assets/sponsors/platinum/s1.png'),
	url: 'https://www.yunycom.rs/en/.'
};

const platinumSponsors = [
	{ url: 'https://www.sysmex-europe.com/', bannerUrl: require('../assets/sponsors/platinum/s2.png') },
	{ url: 'https://www.diasorin.com/home/', bannerUrl: require('../assets/sponsors/platinum/s3.png') },
	{ url: 'https://www.biomerieux.com/', bannerUrl: require('../assets/sponsors/platinum/s4.png') },
	{ url: 'https://www.diagast.com/en/', bannerUrl: require('../assets/sponsors/platinum/s5.jpg') },
	{ url: 'https://www.ekfdiagnostics.com/', bannerUrl: require('../assets/sponsors/platinum/s6.png') },
	{ url: 'https://www.diasys-diagnostics.com/', bannerUrl: require('../assets/sponsors/platinum/s7.png') },
	{ url: 'https://www.diesse.it/en/', bannerUrl: require('../assets/sponsors/platinum/s8.png') },
	{ url: 'https://polycheck.de/', bannerUrl: require('../assets/sponsors/platinum/s9.png') },
	{ url: 'https://www.dialab.at/en/home/', bannerUrl: require('../assets/sponsors/platinum/s10.png') },
	{ url: 'https://www.boditech.co.kr/en', bannerUrl: require('../assets/sponsors/platinum/s11.png') },
	{ url: 'https://www.exias-medical.com/en/', bannerUrl: require('../assets/sponsors/platinum/s12.png') },
	{ url: 'https://www.zybio.com/productxq/40/28.html', bannerUrl: require('../assets/sponsors/platinum/s14.png') },
	{ url: 'https://bromabel.com/', bannerUrl: require('../assets/sponsors/platinum/s13.png') },
];

const mainBronse = {
	bannerUrl: require('../assets/sponsors/bronse/Superlab_logo.png'),
	url: 'http://super-lab.me'
};

const mainOther = { bannerUrl: require('../assets/sponsors/other/medical-sol.png'), url: '' };


const mainOtherSponsors = [
	{ url: 'http://www.beckmancoulter.com', bannerUrl: beckman },
	{ url: 'http://www.werfen.com/', bannerUrl: require('../assets/sponsors/other/werfen.png') },
	{ url: 'http://www.qcnet.com', bannerUrl: qcnet },
];

const bronseSponsors = [
	{ url: 'https://www.diagnostics.eu.tosohbioscience.com', bannerUrl: require('../assets/sponsors/bronse/Tosoh_logo.png') },
	{ url: 'https://eu.nihonkohden.com/en/products/invitrodiagnostics', bannerUrl: require('../assets/sponsors/bronse/NihonKohden_logo.png') },
	{ url: 'https://www.dymind.com/en-US/', bannerUrl: require('../assets/sponsors/bronse/dymind_logo.png') },
	{ url: 'https://www.elitechgroup.com', bannerUrl: require('../assets/sponsors/bronse/ELITechGroup_logo.jpg') },
	{ url: 'https://www.alltests.com.cn', bannerUrl: require('../assets/sponsors/bronse/alltest_logo.jpg') },
	{ url: 'https://www.axonlab.com', bannerUrl: require('../assets/sponsors/bronse/axonlab_logo.png') },
];

const mainBronseBabySponsors = [
	{ url: 'https://biosystems.global/', bannerUrl: require('../assets/sponsors/bronse/biosystem.jpeg') },
	{ url: 'http://en.lifotronic.com/', bannerUrl: require('../assets/sponsors/bronse/lifotronic.jpeg') },
	{ url: 'https://www.mindray.com/en', bannerUrl: require('../assets/sponsors/bronse/midray.jpeg') },
	{ url: 'https://www.alifax.com/', bannerUrl: require('../assets/sponsors/bronse/alifax.jpeg') },
];


const mainSilver = {
	name: 'mainSilver Sponsor 1',
	bannerUrl: require('../assets/sponsors/silver/s1.png'),
	url: 'https://www.farmont.me/'
};

const silverSponsors = [
	{ bannerUrl: require('../assets/sponsors/silver/s2.png'), url: "https://www.bd.com/" },
	{ bannerUrl: require('../assets/sponsors/silver/s3.png'), url: "https://www.shimadzu.hr/" },
	{ bannerUrl: require('../assets/sponsors/silver/s4.png'), url: "https://brahms.de/en-gb/" },
	{ bannerUrl: require('../assets/sponsors/silver/s5.png'), url: "https://www.elitechgroup.com/" },

];

const bronzeSponsors = [
	{ bannerUrl: require('../assets/sponsors/s3.png'), url: 'http://www.labis.si/en' },
	{ bannerUrl: require('../assets/sponsors/s2.png'), url: 'https://www.randox.com/' },
];

const firstOtherSponsor = {
	bannerUrl: require('../assets/sponsors/other/logate.png'), url: 'http://logate.com/'
}
const otherSponsors = [
	{ bannerUrl: require('../assets/sponsors/other/medica.png'), url: '' },
	{ bannerUrl: require('../assets/sponsors/other/herceg-novi.png'), url: 'https://www.hercegnovi.travel/me/' },
	{ bannerUrl: require('../assets/sponsors/other/analitika.png'), url: 'https://www.analitika-lab.com/' },
];

const goldSponsors = [
	{ bannerUrl: require('../assets/sponsors/gold/snipe.png'), url: 'https://www.snibe.com ' },
	{ bannerUrl: require('../assets/sponsors/gold/s1.jpg'), url: 'https://www.rochesrbija.rs/' },
	{ bannerUrl: require('../assets/sponsors/gold/s2.svg'), url: 'http://www.labquality.com/' },
];

const MainBronseSponsors = [
	{ bannerUrl: require('../assets/sponsors/bronse/interpharm.png'), url: 'http://www.interpharm.me/' },
	{ bannerUrl: require('../assets/sponsors/bronse/promedia.png'), url: 'https://www.promedia.rs/' },
];

const SponsorCard = ({ name, bannerUrl, url }) => (
	<div className="w-1/2 md:w-1/6 p-2">
		<div className="bg-white rounded-lg overflow-hidden shadow-sm p-2">
			<a href={url} target="_blank">
				<img className="w-full h-24 object-contain" src={bannerUrl} alt={name} />
			</a>
			<div className="px-4 py-2 hidden">
				<h3 className="text-lg font-medium text-gray-900">{name}</h3>
			</div>
		</div>
	</div>
);


const BronseSponsorCard = ({ name, bannerUrl, url }) => (
	<div className="w-1/2 md:w-1/3  p-2">
		<div className="bg-white rounded-lg overflow-hidden shadow-sm p-2">
			<a href={url} target="_blank">
				<img className="w-full h-24 object-contain" src={bannerUrl} alt={name} />
			</a>
			<div className="px-4 py-2 hidden">
				<h3 className="text-lg font-medium text-gray-900">{name}</h3>
			</div>
		</div>
	</div>
);



const SilverSponsorCard = ({ name, bannerUrl, url }) => (
	<a href={url} target="_blank" className="w-1/2 md:w-1/6 p-2">
		<div className="bg-white rounded-lg overflow-hidden shadow-sm p-2">
			<img className="w-full h-24 object-contain" src={bannerUrl} alt={name} />
			<div className="px-4 py-2 hidden">
				<h3 className="text-lg font-medium text-gray-900">{name}</h3>
			</div>
		</div>
	</a>
);

const MainSponsorCard = ({ name, bannerUrl, url }) => (
	<a href={url} target="_blank" className="w-96  " >
		<div className="bg-white rounded-lg overflow-hidden shadow-md p-5">
			<img className="w-96 h-60 object-contain" src={bannerUrl} alt={name} />
			<div className="px-4 py-2 hidden">
				<h3 className="text-lg font-medium text-gray-900">{name}</h3>
			</div>
		</div>
	</a>
);


const MainSponsorGoldCard = ({ name, bannerUrl, url }) => (
	<a href={url} target="_blank" className="w-96  " >
		<div className="bg-white rounded-lg overflow-hidden shadow-md p-5">
			<img className="w-96 h-60 object-contain" src={bannerUrl} alt={name} />
			<div className="px-4 py-2 hidden">
				<h3 className="text-lg font-medium text-gray-900">{name}</h3>
			</div>
		</div>
	</a>
);





const Sponsors = () => (
	<div className="home-root">
		<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
			<Header />
			<main>
				<div className="mt-32 bg-gray-100 py-16">
					<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="text-center">
							<h1 className="text-4xl font-bold text-gray-900">Sponsors</h1>
						</div>

						<div className="mt-12">
							<div className='w-fit mt-10 text-2xl font-bold text-gray-900 mb-4 p-3 border-b-[#b8d8e7] border-b-4'>
								<span className="text-3xl">
									General (Diamond) Sponsor
								</span>
							</div>
							<div className='sm:flex justify-center  bg-gray-200 py-10 sm:px-10 px-5 rounded-sm'>
								<MainSponsorCard key={mainDaimond.name} {...mainDaimond} />
								{/* <div className="flex flex-wrap  sm:pl-5 sm:w-2/3 items-center pt-3 sm:pt-0 ">
                  {platinumSponsors.map((sponsor) => (
                    <SponsorCard key={sponsor.name} {...sponsor} />
                  ))}
                </div> */}
							</div>
							<div className='w-fit mt-10 text-2xl font-bold text-gray-900 mb-4 p-3 border-b-gray-300 border-b-4'>
								<span className="text-3xl">Platinum Sponsor</span>
							</div>
							<div className='sm:flex  bg-gray-200 py-10 sm:px-10 px-5 rounded-sm'>
								<MainSponsorCard key={mainPlatinum.name} {...mainPlatinum} />
								<div className="flex flex-wrap  sm:pl-5 sm:w-2/3 items-center pt-3 sm:pt-0 ">
									{platinumSponsors.map((sponsor) => (
										<SponsorCard key={sponsor.name} {...sponsor} />
									))}
								</div>
							</div>
							<div className='w-fit mt-10 text-2xl font-bold text-gray-900 mb-4 p-3 border-b-[#FFD700] border-b-4'>
								<span className="text-3xl">Gold Sponsors</span>
							</div>
							<div className=' bg-gray-200 py-10 rounded-sm'>
								<div className='flex bg-gray-200 py-3 sm:pl-10 px-5 sm:pr-0 rounded-sm'>
									<MainSponsorGoldCard key={goldSponsors[0].name} {...goldSponsors[0]} />
								</div>
								<div className='sm:flex bg-gray-200 py-3 sm:pl-10 px-5 sm:px-0  rounded-sm'>
									<a href={goldSponsors[1].url} target="_blank" className="w-96  " >
										<div className="bg-white rounded-lg overflow-hidden shadow-md p-5">
											<img className="w-96 h-60 object-contain" src={goldSponsors[1].bannerUrl} />
										</div>
									</a>
									<a href={goldSponsors[2].url} target="_blank" className="w-96  pl-5" >
										<div className="bg-white rounded-lg overflow-hidden shadow-md p-5">
											<img className="w-96 h-60 object-contain" src={labQ} />
										</div>
									</a>
								</div>
							</div>
							<div className='w-fit mt-10 text-2xl font-bold text-gray-900 mb-4 p-3 border-b-gray-300 border-b-4'>
								<span className="text-3xl">Silver Sponsor</span>
							</div>
							<div className='sm:flex  bg-gray-200 py-10 sm:px-10 px-5 rounded-sm'>
								<MainSponsorCard key={mainSilver.name} {...mainSilver} />
								<div className="flex flex-wrap  sm:pl-5 sm:w-2/3 items-center pt-3 sm:pt-0 ">
									{silverSponsors.map((sponsor) => (
										<SilverSponsorCard key={sponsor.name} {...sponsor} />
									))}
								</div>
							</div>
							<div className='w-fit mt-10 text-2xl font-bold text-gray-900 mb-4 p-3 border-b-[#CD7F32] border-b-4'>
								<span className="text-3xl">Bronze Sponsors</span>
							</div>

							<div className='sm:flex flex-col bg-gray-200 py-10 rounded-sm'>
								<div className='sm:flex bg-gray-200 py-3 sm:pl-10 px-5 sm:px-0  rounded-sm'>
									<a href={MainBronseSponsors[0].url} target="_blank" className="w-96  " >
										<div className="bg-white rounded-lg overflow-hidden shadow-md p-5">
											<img className="w-96 h-60 object-contain" src={MainBronseSponsors[0].bannerUrl} />
										</div>
									</a>
									<a href={MainBronseSponsors[1].url} target="_blank" className="w-96  pl-5" >
										<div className="bg-white rounded-lg overflow-hidden shadow-md p-5">
											<img className="w-96 h-60 object-contain" src={MainBronseSponsors[1].bannerUrl} />
										</div>
									</a>
									<div className="flex flex-wrap  sm:pl-5 sm:w-1/3 items-center pt-3 sm:pt-0 ">
										{mainBronseBabySponsors.map((sponsor) => (
											<BronseSponsorCard key={sponsor.name} {...sponsor} />
										))}
									</div>
								</div>
								{bronzeSponsors.map((sponsor) => (
									<div className='sm:flex  bg-gray-200 py-3 sm:px-10 px-5 rounded-sm'>
										<MainSponsorCard key={sponsor.name} {...sponsor} />
										<div className="flex flex-wrap  sm:pl-5 sm:w-2/3 items-center pt-3 sm:pt-0 ">
										</div>
									</div>
								))}
								<div className='sm:flex  bg-gray-200 py-10 sm:px-10 px-5 rounded-sm'>

									<MainSponsorCard key={mainBronse.name} {...mainBronse} />
									<div className="flex flex-wrap  sm:pl-5 sm:w-2/3 items-center pt-3 sm:pt-0 ">
										{bronseSponsors.map((sponsor) => (
											<SponsorCard key={sponsor.name} {...sponsor} />
										))}
									</div>
								</div>
							</div>
							<div className='w-fit mt-10 text-2xl font-bold text-gray-900 mb-4 p-3 border-b-[#CD7F32] border-b-4'>
								<span className="text-3xl">Other Sponsors</span>
							</div>

							<div className='sm:flex flex-col bg-gray-200 py-10 rounded-sm'>
								<div className='sm:flex  bg-gray-200 py-3 sm:px-10 px-5 rounded-sm'>
									<MainSponsorCard key={firstOtherSponsor.name} {...firstOtherSponsor} />
									<div className="flex flex-wrap  sm:pl-5 sm:w-2/3 items-center pt-3 sm:pt-0 ">
									</div>
								</div>

								<div className='sm:flex  bg-gray-200 py-10 sm:px-10 px-5 rounded-sm'>
									<MainSponsorCard key={mainOther.name} {...mainOther} />
									<div className="flex flex-wrap  sm:pl-5 sm:w-2/3 items-center pt-3 sm:pt-0 ">
										{mainOtherSponsors.map((sponsor) => (
											<SponsorCard key={sponsor.name} {...sponsor} />
										))}
									</div>
								</div>
								{otherSponsors.map((sponsor) => (
									<div className='sm:flex  bg-gray-200 py-3 sm:px-10 px-5 rounded-sm'>
										<MainSponsorCard key={sponsor.name} {...sponsor} />
										<div className="flex flex-wrap  sm:pl-5 sm:w-2/3 items-center pt-3 sm:pt-0 ">
										</div>
									</div>
								))}

							</div>
						</div>
					</div>
				</div>
			</main>

		</div>
		<Footer />

	</div >
)

export default Sponsors;