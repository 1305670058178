import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

export const MedTech = () => {

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header />
				<div className="w-full relativeh-screen mt-28 pb-20">
					<main>
						<div className="w-full sm:w-5/6 m-auto px-5 pt-20">
                            <div className="shadow-md p-10">
                                <div className="w-fit">
									<h1 className="text-2xl sm:text-4xl border-b-4 border-b-[#e78c31] pb-1">
										MedTech Europe
									</h1>
                                </div>
                            </div>
							
						</div>
					</main>
				</div>
			</div>
			<Footer />
		</div>
	);
};
