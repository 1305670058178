import React from 'react'
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'

export const Speaker = (props) => {
    const { state } = useLocation();
    const { name, text1, text2, text3 } = state;

    return (
        <div className="home-root">
            <div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
                <Header />
                <main>
                    <div className='mt-28 m-auto lg:w-4/5 xl:w-4/5 2xl:w-3/5'>
                        <div>
                            <div className='pt-20 pb-20 flex w-4/5 h-full m-auto'>
                                <div className=' '>
                                    <div className=''>
                                        <div className='w-full text-center mt-5'>
                                            <span className='speaker-name text-4xl lg:text-5xl'>{name}</span>
                                            <br></br>
                                        </div>
                                    </div>
                                    <div className='mt-20'>
                                        <p className='text-xl'>
                                            {text1}
                                        </p>
                                        <p className='text-xl mt-5'>
                                            {text2}
                                        </p>
                                        <p className='text-xl mt-5'>
                                            {text3}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    )
}