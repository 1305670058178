import store from "../redux/store";
import { labels_eng } from "../_constants/labels";
import { labels_mne } from "../_constants/labels_mne";

const getLabel = (objKey, key, lang) => {

    return labels_eng[objKey][key];
}
export default {
    getLabel
}