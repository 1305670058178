import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import katic from "../assets/panorama.jpg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DropDownList from "../components/DropDownList";
import { Link, useNavigate } from "react-router-dom";
import herceg_map from '../assets/herceg-map.png';

export const CongressVenue = () => {
	const navigate = useNavigate();

	const onSubmitWorkClick = () => {
		navigate("/SendAbstract");
	};

	return (
		<div className="home-root">
			<div className="home-main bg-blue-50 pb-20 min-h-screen mb-10">
				<Header />

				<div className="w-full relative">
					<main>
						<div className="mt-20">
							<div className="flex justify-center items-center h-screen w-4/5 m-auto rounded-lg overflow-hidden py-32 flex-wrap">
								<div className="w-full sm:w-1/2 sm:h-full">
									<div className="bg-[#001746] p-5 md:p-10  sm:h-full">
										<h1 className=" border-b-4 border-b-[#e78c31] pb-1 w-fit text-white text-3xl sm:text-4xl">
											Park Hall (Dvorana Park)
										</h1>
										<h1 className=" border-b border-b-[#e78c31] pb-1 w-fit text-white text-lg sm:text-xl">
											Njegoseva bb
										</h1>
										<h1 className=" border-b border-b-[#e78c31] pb-1 w-fit text-white text-lg sm:text-xl">
											85340 Herceg Novi
										</h1>
									</div>
								</div>
								<div className="w-full sm:w-1/2 h-96 sm:h-full bg-[url('https://www.hercegfest.me/wp-content/uploads/2020/07/Dvorana-Park2.jpg?id=21252')]  bg-no-repeat bg-cover bg-center relative">
									<div className="bg-gradient-to-b from-[#31436e8e] to-[#42424244] absolute top-0 left-0 h-full z-50 w-full flex justify-center sm:justify-start">
									</div>
								</div>
							</div>
							<div className="-mt-16">
								<div className=" px-10 pb-32 lg:px-40">
									<div>
										<h1 className="text-[#3e3e3e] text-xl sm:text-3xl">Find on google map</h1>
									</div>
									<div className="sm:flex gap-4 ">
										<div className='relative  w-full' style={{ width: '100%', paddingTop: '45%' }}>
											<div className="h-full bg-gray-200 overflow-hidden shadow rounded-xl  absolute top-0 left-0 right-0 bg-center bg-cover bg-no-repeat bg-[url('https://bclf.s3.eu-central-1.amazonaws.com/herceg-gmap.png')]">
												<img className="hidden" src="https://bclf.s3.eu-central-1.amazonaws.com/herceg-gmap.png" />
												<a href="https://www.google.com/maps/place/%D0%94%D0%B2%D0%BE%D1%80%D0%B0%D0%BD%D0%B0+%D0%9F%D0%B0%D1%80%D0%BA/@42.4522889,18.5278307,858m/data=!3m2!1e3!4b1!4m5!3m4!1s0x134c3b7a55a89721:0xeb2ac932162c982e!8m2!3d42.452285!4d18.53231" target="_blank" className="bg-gradient-to-b from-[#31436e91] to-[#42424244]  h-full z-50 w-full flex justify-center items-center  cursor-pointer">
													<h1 className=" pb-1 w-fit text-[#ffffffce]  text-xl sm:text-3xl ">
														Show Location
													</h1>
												</a>
											</div>
										</div>
									</div>

								</div>
								<div className="hidden px-10 lg:px-40 mt-10 sm:mt-16 pb-40">
									<div>
										<h1 className="text-[#3e3e3e]  text-xl sm:text-3xl">
											Explore in Street View
										</h1>
									</div>
									<div className='relative' style={{ width: '100%', paddingTop: '70%' }}>
										<div className="h-full bg-gray-200 overflow-hidden shadow rounded-xl  absolute top-0 left-0 right-0 bg-center bg-cover bg-no-repeat bg-[url('https://bclf.s3.eu-central-1.amazonaws.com/herceg-street.png')]">
											<img className="hidden" src="https://bclf.s3.eu-central-1.amazonaws.com/herceg-gmap.png" />
											<a href="https://www.google.com/maps/@42.4522819,18.5326822,3a,90y,299.55h,89.35t/data=!3m8!1e1!3m6!1sAF1QipOf_CEEt6t9bt97nBv5gPhVGw_9C8uZt5a6IBe5!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOf_CEEt6t9bt97nBv5gPhVGw_9C8uZt5a6IBe5%3Dw203-h100-k-no-pi-0-ya187.23619-ro0-fo100!7i5760!8i2880" target="_blank" className="bg-gradient-to-b from-[#31436e91] to-[#42424244]  h-full z-50 w-full flex justify-center items-center  cursor-pointer">
												<h1 className=" pb-1 w-fit text-[#ffffffce]  text-xl sm:text-3xl ">
													Explore
												</h1>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="pt-10	hidden">
								<div className=" h-screen px-10 lg:px-40">
									<div>
										<h1 className="text-[#3e3e3e]">Find on google map</h1>
									</div>
									<div className='relative' style={{ width: '100%', paddingTop: '50%' }}>
										<div className="h-full bg-gray-200 overflow-hidden shadow sm:rounded-xl  absolute top-0 left-0 right-0 bg-center bg-cover bg-no-repeat bg-[url('https://bclf.s3.eu-central-1.amazonaws.com/herceg-gmap.png')]">
											<img className="hidden" src="https://bclf.s3.eu-central-1.amazonaws.com/herceg-gmap.png" />
											<a href="https://www.google.com/maps/place/%D0%94%D0%B2%D0%BE%D1%80%D0%B0%D0%BD%D0%B0+%D0%9F%D0%B0%D1%80%D0%BA/@42.4522889,18.5278307,858m/data=!3m2!1e3!4b1!4m5!3m4!1s0x134c3b7a55a89721:0xeb2ac932162c982e!8m2!3d42.452285!4d18.53231" target="_blank" className="bg-gradient-to-b from-[#31436e91] to-[#42424244]  h-full z-50 w-full flex justify-center items-center  cursor-pointer">
												<h1 className=" pb-1 w-fit text-[#ffffffce] text-3xl ">
													Show Location
												</h1>
											</a>
										</div>
									</div>
								</div>
								<div className=" h-screen px-10 lg:px-40 -mt-10">
									<div>
										<h1 className="text-[#3e3e3e]">
											Explore in Street View
										</h1>
									</div>
									<div className='relative' style={{ width: '100%', paddingTop: '50%' }}>
										<div className="h-full bg-gray-200 overflow-hidden shadow sm:rounded-xl  absolute top-0 left-0 right-0 bg-center bg-cover bg-no-repeat bg-[url('https://bclf.s3.eu-central-1.amazonaws.com/herceg-street.png')]">
											<img className="hidden" src="https://bclf.s3.eu-central-1.amazonaws.com/herceg-gmap.png" />
											<a href="https://www.google.com/maps/@42.4522819,18.5326822,3a,90y,299.55h,89.35t/data=!3m8!1e1!3m6!1sAF1QipOf_CEEt6t9bt97nBv5gPhVGw_9C8uZt5a6IBe5!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOf_CEEt6t9bt97nBv5gPhVGw_9C8uZt5a6IBe5%3Dw203-h100-k-no-pi-0-ya187.23619-ro0-fo100!7i5760!8i2880" target="_blank" className="bg-gradient-to-b from-[#31436e91] to-[#42424244]  h-full z-50 w-full flex justify-center items-center  cursor-pointer">
												<h1 className=" pb-1 w-fit text-[#ffffffce] text-3xl ">
													Explore
												</h1>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>

					</main>
				</div>
			</div>
			<Footer />
		</div>
	);
};
